import { useEffect, useState } from "react";
import ShowPassword from "../../../../assets/icons/eye-slash.svg";
import HidePassword from "../../../../assets/icons/eye.svg";
import { signup_style } from "../student/style";
import { useLocation } from "react-router-dom";


// REUSABLE STYLING
const labelContainerStyling = "flex flex-col lg:gap-[10px]";
// const labelStyling = "text-[14px] font-[400] leading-[14px] text-tsa-textGrey supreme";
const inputContainerStyling =
  "w-full text-[12px] rounded-[6px] supreme border border-[#DCDEE2]  bg-white py-[14px] pl-3 pr-6";
const fieldStyling =
  "w-full outline-none text-[12px] leading-[14px] text-tsa-inputText supreme bg-transparent";
// const errorStyling = "text-[12px] leading-[14px] text-red-700 -mt-4 mb-4 supreme";

// COMPONENT

function PasswordFieldNoValidation({ field, form }: any) {
  const [showPassword, setShowPassword] = useState(false);

const location = useLocation()
const signinRoute = location.pathname.includes('signin')

  const calculatePasswordStrength = (password: string) => {
    const hasUppercase = /[A-Z]/.test(password) ? 1 : 0;
    const hasLowercase = /[a-z]/.test(password) ? 1 : 0;
    const hasNumber = /\d/.test(password) ? 1 : 0;
    const length = password.length;
    return { hasUppercase, hasLowercase, hasNumber, length };
  };

  const getStrengthLabel = () => {
    const { hasUppercase, hasLowercase, hasNumber, length } =
      calculatePasswordStrength(form.values.password);
    const strength = (hasUppercase + hasLowercase + hasNumber) / 3;

    if (length < 8) {
      return "Very Weak";
    } else if (strength < 0.6) {
      return "Weak";
    } else if (strength < 0.8) {
      return "Strong";
    } else {
      return "Good";
    }
  };

  const calculatedError = form.values.password.length > 0 && getStrengthLabel();

  return (
    <>
      {/* PASSWORD */}
      <div className={labelContainerStyling}>
        <label htmlFor="password" className={signup_style.label}>
          Password
        </label>
        <div className={`${inputContainerStyling} flex`}>
          <input
            id="password"
            name={field.name}
            type={showPassword ? "text" : "password"}
            placeholder="*******"
            className={fieldStyling}
            value={form.values[field.name]}
            onChange={({ target }) =>
              form.setFieldValue(field.name, target.value)
            }
          />
          <img
            src={showPassword ? ShowPassword : HidePassword}
            alt="Show Password"
            className="w-6 h-6 cursor-pointer"
            onClick={() => setShowPassword((prev: boolean) => !prev)}
          />
        </div>
      </div>

      {!signinRoute && (<div className="flex justify-between w-full">
        <p className={signup_style.errorStyle}>{form.errors.password}</p>
        <p
          className={`text-sm ${  
            (calculatedError === "Strong"
              ? "text-amber-500"
              : calculatedError === "Good"
              ? "text-green-400"
              : "text-red-600")
          }`}
        >
          {calculatedError}
        </p>
      </div>)}
    </>
  );
}

export default PasswordFieldNoValidation;
