import { QueryClient, useMutation, useQuery } from "react-query";
import AuthService from "../../services/auth.service";
import {
  ForgotPasswordType,
  OnboardingType,
  ResendEmailType,
  ResetPasswordType,
  SignInType,
  SignUpType,
} from "../types";


type VerifyOtpPayload = {
  email?: string;
  otp?: string;
};

type VerifyOtpResponse = any; // Replace 'any' with your actual response type


const authService = new AuthService();

const queryClient = new QueryClient();

export const useRegisterUser = () => {
  return useMutation((payload: SignUpType) => authService.signup(payload));
};

export const useLogin = () => {
  return useMutation((payload: SignInType) => authService.login(payload));
};

export const useLogout = () => {
  return useMutation(() => authService.logout());
};

export const useResendEmail = () => {
  return useMutation((payload: ResendEmailType) =>
    authService.resendEmail(payload)
  );
};

export const useForgotPassword = () => {
  return useMutation((payload: ForgotPasswordType) =>
    authService.forgotPassword(payload)
  );
};

export const useGetProfile = () => {
  return useQuery(["profile"], () => authService.getProfile());
};

export const useUpdateProfile = () => {
  return useMutation((data: any) => authService.updateProfile(data), {
    onSuccess: () => {
      queryClient.invalidateQueries("profile");
    },
  });
};

export const useUpdatePassword = () => {
  return useMutation((data: any) => authService.updatePassword(data), {
    onSuccess: () => {
      queryClient.invalidateQueries("profile");
    },
  });
};

export const useInitiateEmailVerification = () => {
  return useMutation(
    ({
      url,
      payload,
      id,
    }: {
      url: string;
      payload: { email: string };
      id?: string | undefined;
    }) => authService.verifyEmail(url, payload, id)
  );
};

export const useVerifyEmail = () => {
  return useMutation(
    ({
      url,
      payload,
      id,
    }: {
      url: string;
      payload: { token: string };
      id?: string | undefined;
    }) => authService.verifyEmail(url, payload, id)
  );
};


export const useVerifyOtp = (options: Record<string, any>) => {
  return useMutation({
    mutationKey: ["verifyOTP"],
    mutationFn: (payload: { email?: string; otp?: string }) =>
      authService.verifyOTP(payload),
    ...options,
  });
};

export const useResendOtp = (options: Record<string, any>) => {
  return useMutation({
    mutationKey: ["ResendOTP"],
    mutationFn: (payload: { email: string }) => authService.resendOTP(payload),
    ...options
  })
}


export const useOnboarding = () => {
  return useMutation((payload: OnboardingType) =>
    authService.onBoarding(payload)
  );
};

export const useResetPassword = () => {
  return useMutation((payload: ResetPasswordType) =>
    authService.resetPassword(payload)
  );
};
