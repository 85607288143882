import { useNavigate } from "react-router-dom";
import { producers } from "../../../utils/constants";

const Producers = () => {
  const navigate = useNavigate();
  return (
    <section className="mt-[700px] lg:mt-[900px]">
      <h2 className="text-3xl md:text-4xl font-bold text-center font-[technorSemibold]">
        Meet the producers
      </h2>
      <p className="mx-auto lg:w-[55%] text-[#555C74] text-lg text-center mt-3">
        Collaborate with the industry's top producers who have worked with
        renowned artists to create chart-topping hits.
      </p>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10 w-[90%] lg:w-[80%] mx-auto mt-9">
        {producers.map((d, i) => {
          return (
            <div
              onClick={() => navigate(`/producers/${d.name}`)}
              className="relative"
            >
              <img src={d.img} alt={d.name} />
              <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gradient-to-t from-black/60 to-transparent" />
              <p className="absolute left-1/2 -translate-x-1/2 bottom-7 z-10 text-xl text-white font-bold">
                {d.name}
              </p>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Producers;
