import { useRef } from "react";
import { IoMdClose } from "react-icons/io";
import { useOnClickOutside } from "../custom-hooks";

interface ModalProps {
  modalStyle?: string;
  children: any;
  onClose: () => void;
}

function CustomModal({ modalStyle, children, onClose }: ModalProps) {
  const ref = useRef(null);

  const closeModal = () => {
    console.log("modal closed");
    onClose();
  };

  useOnClickOutside(ref, closeModal);

  return (
    <div className=" fixed z-[1020]  w-full h-full top-0 left-0 bottom-0 bg-[#000]/[0.7] backdrop-brightness-100">
      <div ref={ref} className={`${modalStyle} relative grid`}>
        <div
          onClick={closeModal}
          className="absolute text-gray-400 right-5 top-7 hover:cursor-pointer"
        >
          <IoMdClose size={20} />
        </div>
        <main>{children}</main>
      </div>
    </div>
  );
}

export default CustomModal;
