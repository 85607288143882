import React, { useState } from "react";
import { useParams } from "react-router-dom";
import VideoPlayer from "../../../../components/ui/VideoPlayer";
import { Tab, Tabs } from "../../../../components/ui/Tab";
import CourseContent from "./CourseContent";
import LessonOverview from "./LessonOverview";
import SubmitAssignmnt from "./SubmitAssignmnt";
import CourseReviews from "./CourseReviews";
import { useGetSingleCourse } from "../../../../hooks/react-query/useCourse";
import saveIcon from "../../../../assets/icons/saveIcon.svg";
import shareIcon from "../../../../assets/icons/shareIcon.svg";

function SingleCourse() {
  const { cid } = useParams();
  const { data: courseData } = useGetSingleCourse(cid as string);
  const [lessonVideo, setLessonVideo] = useState<string | null>(null);
  const [currentLessonId, setCurrentLessonId] = useState<string>("");

  function setContent(id: string) {
    console.log({ id });
    const data: [] = courseData.data.lessons;
    const filteredLessonArray = data.filter((d: any) => d._id === id);
    console.log({ filteredLessonArray });
    const filteredLesson = filteredLessonArray[0] as any;
    setLessonVideo(filteredLesson.url);
  }

  const getCurrentLessonId = (id: string) => {
    setCurrentLessonId(id);
  };

  return (
    <div className="my-[70px] mx-[20px]">
      <section className="">
        <div className="md:flex items-start gap-5">
          <div className="w-full md:w-[70%]">
            <VideoPlayer
              videoStyle="rounded-[12px] lg:h-[400px] h-[200px]"
              src={lessonVideo || courseData?.data?.introductory_video}
              lessonId={currentLessonId}
            />

            <div className="flex items-center gap-x-7 py-4">
              <span className="flex items-center gap-x-1.5 supreme">
                <img src={saveIcon} alt="share" /> Save
              </span>
              <span className="flex items-center gap-x-1.5 supreme">
                <img src={shareIcon} alt="save" /> Share
              </span>
            </div>
          </div>
          <div className="w-full md:w-[30%]">
            <p style={{ fontWeight: "bold", marginBottom: 20 }}>
              Course Content
            </p>
            <CourseContent
              data={courseData}
              setContent={setContent}
              getCurrentLessonId={getCurrentLessonId}
            />
          </div>
        </div>
      </section>

      <div className="mt-10 w-full md:w-[70%]">
        <Tabs>
          <Tab title="OVERVIEW">
            <LessonOverview data={courseData} />
          </Tab>
          <Tab title="ASSIGNMENT">
            <SubmitAssignmnt data={courseData} />
          </Tab>
          <Tab title="REVIEWS">
            <CourseReviews data={courseData} />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default SingleCourse;
