import React from "react";
import InProgress from "./inProgress";
import { Tab, Tabs } from "../../../../components/ui/Tab";
import Completed from "./completed";
import Saved from "./saved";

const MyLearning: React.FC = () => {
  return (
    <div className="my-[70px] mx-[20px]  md:w-[60%]">
      <Tabs>
        <Tab title="In Progress">
          <InProgress />
        </Tab>
        {/* <Tab title="Completed">
            <Completed />
          </Tab> */}
        {/* <Tab title="Saved">
            <Saved />
          </Tab> */}
      </Tabs>
    </div>
  );
};

export default MyLearning;
