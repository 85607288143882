import React from "react";
import ContinueLearning from "./continueLearning";
// import Recommended from "./recommended";
// import TrackLearning from "./TrackLearning";
import Popular from "./popular";
import CompleteProfile from "./CompleteProfile";
import Loader from "../../../../components/ui/Loader";
import {
  useGetCoursesHistory,
  useGetPopularCourses,
  // useGetRecommendedCourses,
} from "../../../../hooks/react-query/useCourse";

type Props = {};

export default function Home({}: Props) {
  // const { data: recommendedCourses, isLoading: isLoadingRecommended } = useGetRecommendedCourses();
  const { data: popularCourses, isLoading: isLoadingPopular } =
    useGetPopularCourses();
  const { data: coursesHistory, isLoading: isLoadingHistory } =
    useGetCoursesHistory();

  // if (isLoadingRecommended || isLoadingPopular || isLoadingHistory) {
  //   return <Loader />;
  // }
  if (isLoadingPopular || isLoadingHistory) {
    return <Loader />;
  }

  return (
    <section>
      <div className="my-[70px] mx-[40px] ">
        <div className="md:w-[90%] space-y-5 md:space-y-0 md:space-x-5 md:flex items-center my-5 ">
          {/* <TrackLearning /> */}
          <CompleteProfile />
        </div>
        <ContinueLearning coursesHistory={coursesHistory} />
        {/* <Recommended recommendedCourses={recommendedCourses} /> */}
        <Popular popularCourses={popularCourses} />
      </div>
    </section>
  );
}
