import { useNavigate } from "react-router-dom";
// import bgImage from "../../../assets/images/production/applyNowBg.svg";

const ApplyNow = () => {
  const navigate = useNavigate();

  return (
    <div className={` applyNowBg  bg-no-repeat py-16`}>
      <div className="max-w-4xl mx-auto text-center px-4">
        <h2 className="text-3xl font-bold mb-4">
          Ready to elevate your music production?
        </h2>
        <p className="text-3xl font-medium mb-8">
          Get started with SCRiiPO Collectives
        </p>
        <button
          onClick={() => navigate(`/production/home#apply-section`)}
          className="bg-teal-600 text-white px-6 py-3 rounded-md font-semibold hover:bg-teal-700 transition-colors"
        >
          Apply now
        </button>
      </div>
    </div>
  );
};

export default ApplyNow;
