import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";

const AccordionItem = ({
  title,
  content,
  isOpen,
  onClick,
}: {
  title: string;
  content: string;
  isOpen: boolean;
  onClick: () => void;
}) => {
  return (
    <div className="border-b border-gray-200 py-4">
      <button
        className="flex justify-between items-center w-[90%] md:w-full mx-auto text-left"
        onClick={onClick}
      >
        <span className="text-lg font-medium">{title}</span>
        <div className="text-black bg-[#F8F8F8] rounded-full ml-4 p-2">
          <FaPlus
            className={`transform transition-transform duration-200     ${
              isOpen ? "rotate-45" : "rotate-0"
            }`}
          />
        </div>
      </button>
      {isOpen && (
        <div className="mt-2 w-[90%] md:w-full mx-auto">{content}</div>
      )}
    </div>
  );
};

const FAQProduction = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index: any) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqData = [
    {
      title: "What is SCRiiPO collectives?",
      content: "SCRiiPO collectives is a music production platform...",
    },
    {
      title: "Is the application fee refundable?",
      content: "The application fee is non-refundable...",
    },
    {
      title: "What happens if I'm not selected?",
      content: "If you're not selected, we provide feedback...",
    },
    {
      title: "What is included in the NGN 1,000,000 fee?",
      content: "The NGN 1,000,000 fee includes studio time, production...",
    },
    {
      title: "How long does the production process take?",
      content: "The production process typically takes...",
    },
    {
      title: "Do I retain full ownership of my tracks?",
      content: "Yes, you retain full ownership of your tracks...",
    },
    {
      title: "Are there additional costs?",
      content: "There may be additional costs for...",
    },
  ];

  return (
    <div className="max-w-3xl mx-auto pb-12 px-4 sm:px-6 lg:px-8 pt-[23rem] md:pt-28">
      <h2 className="text-4xl font-bold text-center mb-2">Frequently</h2>
      <p className="text-3xl text-teal-600 text-center mb-12">
        Asked questions.
      </p>

      <div className="space-y-2">
        {faqData.map((faq, index) => (
          <AccordionItem
            key={index}
            title={faq.title}
            content={faq.content}
            isOpen={openIndex === index}
            onClick={() => toggleAccordion(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default FAQProduction;
