import React, { useState } from "react";
import { LandingPageLayout } from "../../components/layouts";
import SectionOne from "../waitList/SectionOne";
import SectionSix from "../waitList/SectionSix";
import SectionFour from "./landing/SectionFour";
import SectionFive from "./landing/SectionFive";
import SectionTwo from "./landing/SectionTwo";
import SectionThree from "./landing/SectionThree";
import ReviewSection from "./landing/ReviewSection";
import {
  useGetAllCategories,
  useGetAllCourses,
} from "../../hooks/react-query/useCourse";


type Props = {};

export default function LandingPage({}: Props) {

  const [hideSection, setHideSection] = useState<boolean>(false);
  const { isLoading: isCoursesLoading, data: courses } = useGetAllCourses();
  const { data: categories } = useGetAllCategories();

  return (
    <LandingPageLayout hideSection={hideSection}>
        <section
          className={`duration-500 ease-in-out h-full  transition-transform transform`}
        >
          <div className="-mt-[11rem] lg:pt-0 pt-[10rem] pb-[2rem] bg">
            <SectionOne
              btnTo="/authentication/signin"
              btnText="Start your journey"
            />
          </div>
          <SectionTwo
            isCoursesLoading={isCoursesLoading}
            courses={courses}
            categories={categories}
          />
          <SectionThree />
          <SectionFour />
          <SectionFive />
          <ReviewSection />
          <SectionSix />
        </section>

    
    </LandingPageLayout>
  );
}
