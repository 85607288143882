import React from "react";
import spiralArrow from "../../../assets/images/production/spiralArrow.svg";

interface AboutProps {
  image: any;
  about: string;
  name: string;
}

const About: React.FC<AboutProps> = ({ image, about, name }) => {
  return (
    <div className="max-w-7xl mx-auto px-4 font-sans pt-[21rem] lg:pt-[35rem]">
      <div className="flex flex-col items-center md:flex-row gap-8">
        <div className="md:w-1/2">
          <img
            src={image}
            alt=""
            className="w-[478px] h-[349px] md:h-[556px] object-cover object-top rounded-lg shadow-lg"
          />
        </div>
        <div className="md:w-1/2">
          <h1 className="text-4xl font-bold mb-6">About</h1>
          <p className="mb-4 whitespace-pre-line">{about}</p>
        </div>
      </div>

      <div className="mt-28 text-center">
        <h2 className="text-3xl font-bold mb-4">Production process</h2>
        <div className="relative w-fit mx-auto">
          <p className="mb-4 ">
            Watch how {name.split(" ").slice(0, 1)} brought his vision to life
            with SCRiiPO collectives.
          </p>
          <img
            src={spiralArrow}
            alt="spiral arrow"
            className="absolute hidden lg:block -right-16 w-24 z-10 -top-5"
          />
        </div>
        <div className=" rounded-lg md:w-[80%] mx-auto overflow-hidden mt-16">
          <iframe
            className="w-full h-[200px] lg:h-[340px] mx-auto rounded-2xl border-2 border-[#D2F5E7]"
            src="https://www.youtube.com/embed/qkl4IIPp7Bo?si=FT519Sl0rmSd60Ii"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default About;
