import { QueryClient, useMutation, useQuery } from "react-query";
import SubscriptionService from "../../services/subscription.service";

const queryClient = new QueryClient();
const subscriptionService = new SubscriptionService();

export const useGetAllSubscriptions = () => {
  return useQuery("allPackages", () => subscriptionService.getAllSubscriptions());
};

export const useGetUserSubscription = () => {
    return useQuery("userSubscription", () => subscriptionService.getUserSubscriptions())
}

export const useCancelSubscription = () => {
  return useMutation(() => subscriptionService.cancelSubscription(), {
    onSuccess: () => {
      queryClient.invalidateQueries("userSubscription");
    },
  });
}