import { AnyAsyncThunk } from "@reduxjs/toolkit/dist/matchers";
import { request } from "../hooks/api";
import { CommentProps, LessonProgressProps } from "./_model";
import { AsyncThunk } from "@reduxjs/toolkit";

class CourseService {
  async watchLesson(id: string | undefined) {
    try {
      const response = await request(
        `/user/lesson/${id}`,
        "GET",
        null,
        true,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async trackProgress(id: string | undefined, payload: LessonProgressProps) {
    try {
      const response = await request(
        `/user/lesson/${id}/progress`,
        "POST",
        payload,
        true,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async commentOnLesson(id: string, payload: CommentProps) {
    try {
      const response = await request(
        `/user/lesson/${id}/comment`,
        "POST",
        payload,
        true,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getPaymentStatus(id: string) {
    try {
      const response = await request(
        `/course/${id}/payment/status`,
        "GET",
        null,
        true,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getAllCourses(queryParameter?: any) {
    try {
      const response = await request(
        `/course?category=${queryParameter?.category}`,
        "GET",
        null,
        true,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getEnrolledCourses(queryParameter?: any) {
    try {
      const response = await request(
        `/course/enrolled`,
        "GET",
        null,
        true,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getAllCategories() {
    try {
      const response = await request(
        `/category`,
        "GET",
        null,
        true,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getSingleCourse(id: string) {
    try {
      const response = await request(
        `/course/${id}`,
        "GET",
        null,
        true,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getPublicSingleCourse(id: string) {
    try {
      const response = await request(
        `/course/${id}/p`,
        "GET",
        null,
        true,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getRecommendedCourses() {
    try {
      const response = await request(
        `/user/dashboard/recommended`,
        "GET",
        null,
        true,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getPopularCourses() {
    try {
      const response = await request(
        `/user/dashboard/popular`,
        "GET",
        null,
        true,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getCoursesHistory() {
    try {
      const response = await request(
        `/user/dashboard/popular`,
        "GET",
        null,
        true,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default CourseService;
