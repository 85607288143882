import React, { useState } from "react";
import { h1Style, pStyle } from "../../../waitList/style";
import { PAYMENT_CATEGORY, packages } from "../../../../utils/constants";
import offRight from "../../../../assets/icons/priceOffRight.svg";
import offLeft from "../../../../assets/icons/priceOffLeft.svg";
import { CustomModal, PackageCard } from "../../../../components/ui";
import UserPackageCard from "../../../../components/ui/UserPackageCard";
import InitializePaymentButton from "./InitializePaymentButton";
import { useGetProfile } from "../../../../hooks/react-query/useAuth";
import { PaymentIntent } from "../../../../utils/enum";

type Props = { packagesData: any };

export function UserPricingPage({ packagesData }: Props) {
  const [activeTab, setActiveTab] = useState(1);
  const [selectedPackage, setSelectedPackage] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedPrice, setSelectedPrice] = useState<number>(0);
  const [selectedDuration, setSelectedDuration] = useState<string>("");
  const [selectedTitle, setSelectedTitle] = useState<string>("");
  const [selectedId, setSelectedId] = useState<string>("");
  const [isSelected, setIsSelected] = useState<boolean>(false);

  const { data: userData } = useGetProfile();

  const handleSelect = (index: number) => {
    setSelectedPackage(index);
    console.log({ index });
  };

  const handleSelectPackage = (
    price: number,
    isSelected: boolean,
    duration: string,
    title: string,
    id: string
  ) => {
    setSelectedPrice(price);
    setSelectedDuration(duration);
    setSelectedTitle(title);
    setSelectedId(id);
    setIsSelected(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const discount = false;
  const discountedPrice = selectedPrice - selectedPrice * 0.01;

  const price = discount ? discountedPrice : selectedPrice;

  //conditionally change background of modal submit button
  const [inputValue, setInputValue] = useState("");
  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };
  //

  // Calculate duration string based on activeTab
  const duration =
    activeTab === 0 ? "monthly" : activeTab === 1 ? "quaterly" : "bi-annually";

  const country = userData?.data.country;

  return (
    <section className="lg:h-[100vh] sm:h-[90vh] w-full">
      <div className="grid gap-y-7 lg:pt-[9rem] pt-[4rem] ">
        {/* currency selection  */}
        <h1
          className={`text-2xl supreme font-semibold text-center lg:w-fit w-[80%] mx-auto`}
        >
          Choose your plan
        </h1>

        <div>
          <ul className="flex bg-[#F1F2F3B2] rounded-full p-1  min-w-[270px] w-fit mx-auto mt-[2rem]">
            {PAYMENT_CATEGORY.map((item, i) => (
              <li
                key={i}
                className={`font-[supreme] px-8 py-2.5 rounded-full hover:cursor-pointer lg:text-lg  ${
                  activeTab === item.value
                    ? "bg-[#EBF8F3] text-tsa-primary"
                    : "bg-[#F1F2F3B2] text-[#010516]"
                }`}
                onClick={() => setActiveTab(item.value)}
              >
                {item.text}
              </li>
            ))}
          </ul>
          <div className="w-full md:w-[55%] lg:w-[40%] max-w-[430px] mx-auto flex items-center justify-between px-6">
            <div></div>
            <div className="flex flex-col">
              <img src={offLeft} alt="" />
              <p className="text-[12px]">5% OFF</p>
            </div>
            <div className="flex flex-col">
              <img src={offRight} alt="" />
              <p className="text-[12px]">8% OFF</p>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid lg:grid-cols-3 gap-5 justify-between w-[95%] mx-auto mt-10">
            {packagesData?.data.map((item: any, i: number) => (
              <UserPackageCard
                packageInfo={item}
                activeTab={activeTab}
                key={i}
                isSelected={selectedPackage === i}
                handleSelect={() => handleSelect(i)}
                userData={userData}
                duration={duration}
                onSelectPackage={handleSelectPackage}
              />
            ))}
          </div>

          <div className="flex justify-center my-12">
            <button
              onClick={() => isSelected && setIsModalOpen(true)}
              className={`supreme ${
                isSelected ? "bg-tsa-primary" : "bg-gray-100"
              } 
              w-[85%] md:w-[40%] mx-auto py-[10px] rounded-lg ${
                isSelected ? "text-white" : "text-gray-400"
              } ${!isSelected && "cursor-not-allowed"}  `}
            >
              Continue
            </button>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <CustomModal
          modalStyle="lg:w-[28%] w-[90%] mt-[180px] bg-white mx-auto mt-[12rem] rounded-[12px] py-[30px] px-5"
          onClose={closeModal}
        >
          <div>
            <p className="text-xl mt-8 font-medium">Summary</p>
            <form className="flex items-center justify-between space-x-2">
              <input
                disabled
                className={`border supreme text-sm text-gray-500 p-1.5 rounded-md border-gray-200 my-5  w-full ${
                  inputValue
                    ? "outline-dashed outline-gray-200"
                    : "outline-none"
                }`}
                value={inputValue}
                onChange={handleInputChange}
                placeholder="Enter discount code"
              />
              <button
                className={`py-1.5 px-3 supreme text-sm rounded-md  ${
                  inputValue
                    ? "bg-tsa-primary text-white"
                    : "bg-gray-100 text-gray-400"
                }`}
                disabled={!inputValue}
                type="submit"
              >
                Apply
              </button>
            </form>
            <div className="supreme space-y-3 py-5 px-2 bg-[#F1F2F380]">
              <div className="flex items-center justify-between">
                <p>
                  {selectedTitle} ({selectedDuration}){" "}
                </p>
                <p>NGN{Math.floor(selectedPrice / 100).toLocaleString()}</p>
              </div>
              <div className="flex items-center justify-between">
                <p>Discount </p>
                <p>{discount ? "-10%" : "-NGN0.00"}</p>
              </div>
              <div className="flex items-center justify-between font-semibold">
                <p>Total</p>
                <p>NGN{Math.floor(price / 100).toLocaleString()}</p>
              </div>
            </div>

          <InitializePaymentButton
  price={price}
  currency={country.includes("igeria") ? "NGN" : "USD"}
  intentMetadata={{
    user_id: userData?.data._id,
    package: selectedId,
    duration: duration,
  }}
  paymentIntent={PaymentIntent.STUDENT_SUBSCRIPTION}
/>

          </div>
        </CustomModal>
      )}
    </section>
  );
}
