import { h3Style, pStyle } from "../../../waitList/style";

function CourseDetails({ data }: any) {
  return (
    <section className="pb-[40px] w-full">
      <div className="grid">
        <div>
          <h3 className={`${h3Style} supreme text-[20px] my-2`}>
            About this course
          </h3>
          <div
            dangerouslySetInnerHTML={{ __html: data?.data?.description }}
            className={`${pStyle} supreme mt-3`}
          />
        </div>
      </div>
    </section>
  );
}

export default CourseDetails;
