import React from "react";
import { UserPricingPage } from "./UserPricingPage";
import { useGetAllSubscriptions } from "../../../../hooks/react-query/useSubscriptions";
import { ClipLoader } from "react-spinners";

const Plans = () => {
  const { data, isLoading } = useGetAllSubscriptions();

  return (
    <section>
      {isLoading ? (
        <ClipLoader
          className="relative left-[50%] -translate-x-[50%] top-[13rem]"
          size={100}
          color="#1C7272"
        />
      ) : (
        <UserPricingPage packagesData={data} />
      )}
    </section>
  );
};

export default Plans;
