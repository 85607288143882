import WhyScriipoCollectives from "./WhyScriipoCollectives";
import HowItWorks from "./HowItWorks";
import IndependentArtistes from "./IndependentArtistes";
import HowWeHelped from "./HowWeHelped";
import FAQProduction from "./FAQProduction";
import ApplyNow from "./ApplyNow";
import ProductionHero from "./ProductionHero";
import { ProductionLayout } from "../../../components/layouts";
import Producers from "./Producers";

const ProductionLanding = () => {
  return (
    <ProductionLayout>
      <ProductionHero />
      <Producers />
      <WhyScriipoCollectives />
      <HowItWorks />
      <IndependentArtistes />
      <HowWeHelped />
      <FAQProduction />
      <ApplyNow />
    </ProductionLayout>
  );
};

export default ProductionLanding;
