import React from "react";
import AliceCarousel from "react-alice-carousel";
import CourseCard from "./CourseCard";
import next from "../../../../assets/icons/slider-right.svg";
import previous from "../../../../assets/icons/slider-left.svg";

function Popular({ popularCourses }: any) {
  const responsive = {
    0: { items: 1 },
    450: { items: 2 },
    800: { items: 2 },
    1024: { items: 3 },
  };
  return (
    <div className="grid gap-3">
      <h1 className="item-center flex justify-start supreme font-[500] text-[20px]">
        Popular
      </h1>
      <div className="relative w-full overflow-hidden">
        <AliceCarousel
          autoPlayControls={false}
          mouseTracking
          responsive={responsive}
          controlsStrategy="responsive"
          autoPlay={true}
          autoPlayInterval={5000}
          infinite={true}
          keyboardNavigation={true}
          renderPrevButton={() => {
            return (
              <img
                src={previous}
                className="absolute md:left-[0.3rem] md:block hidden top-[9rem] "
                alt="previous"
              />
            );
          }}
          renderNextButton={() => {
            return (
              <img
                src={next}
                className="absolute md:right-[0.3rem] md:block hidden top-[9rem] hover:cursor-pointer"
                alt="next"
              />
            );
          }}
        >
          {popularCourses?.data?.map((item: any) => (
            <CourseCard course={item} />
          ))}
        </AliceCarousel>
      </div>
    </div>
  );
}

export default Popular;
