import affordable from "../../../assets/images/production/whyScripo/affordable.svg";
import exposure from "../../../assets/images/production/whyScripo/exposure.svg";
import ownership from "../../../assets/images/production/whyScripo/ownership.svg";
import support from "../../../assets/images/production/whyScripo/support.svg";
import topTier from "../../../assets/images/production/whyScripo/topTier.svg";

const features = [
  {
    icon: topTier,
    title: "Top-tier production",
    description:
      "Work with industry-proven producers across multiple genres to ensure your music reaches its fullest potential",
  },
  {
    icon: ownership,
    title: "Ownership",
    description:
      "No complex contract, just pure ownership of your art. We ensure that the creative freedom and profits from your work stay in your hands.",
  },
  {
    icon: exposure,
    title: "Exposure",
    description:
      "Beyond production, we help you connect with the right audiences, gain visibility and help you build a sustainable career",
  },
  {
    icon: affordable,
    title: "Affordable fees",
    description:
      "We offer affordable high-quality production at a competitive flat fee of NGN 1,000,000 for 4 fully produced tracks",
  },
  {
    icon: support,
    title: "Creative support",
    description:
      "Collaborate with producers who are passionate about artist growth to bring your music to life",
  },
];

const WhyScriipoCollectives = () => {
  return (
    <section className="py-16 px-4 md:px-8 lg:px-16 max-w-7xl mx-auto ">
      <h2 className="text-3xl md:text-4xl font-bold text-center mb-12 font-[technorSemibold]">
        Why SCRiiPO collectives
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8 lg:pt-10">
        {features.map((feature, index) => (
          <div
            key={index}
            className="flex flex-col items-left text-left justify-start"
          >
            <div className="text-4xl mb-4 bg-[#ECFDF5] rounded-full p-3 w-fit">
              <img src={feature.icon} alt={feature.title} />
            </div>
            <h3 className="text-xl font-semibold mb-2 font-[technorSemibold]">
              {feature.title}
            </h3>
            <p className="text-gray-600 ">{feature.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default WhyScriipoCollectives;
