import React, { useEffect, useState } from "react";
import { AuthLayout } from "../../../../../components/layouts";
import { LayoutContainer } from "../../components";
import UserNameForm from "./UserNameForm";
import UserInterestPage from "./UserInterestPage";
import UserLevelPage from "./UserLevelPage";
import OnboardingProgressbar from "./OnboardingProgressbar";
import LoadingPage from "./LoadingPage";
import { OnboardingType } from "../../../../../hooks/types";
import { useOnboarding } from "../../../../../hooks/react-query/useAuthUser";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

type Props = {};

const initialValues = {
  firstname: "",
  lastname: "",
  area: [],
  skill: "",
};

export default function Onboarding({}: Props) {
  const [step, setStep] = useState(1);
  const [progress, setProgress] = useState(30);
  const [formData, setFormData] = useState<OnboardingType>(initialValues);
  const { mutate: onboardMutate } = useOnboarding();
  const navigate = useNavigate();

  const handleNext = () => {
    setStep((prev) => prev + 1);
    setProgress((prev) => (prev === 90 ? prev + 10 : prev + 30));
  };

  const handlePrevious = () => {
    setStep((prev) => (prev > 1 ? prev - 1 : 1));
    setProgress((prev) => prev - 30);
  };

  const handleOnboarding = () => {
    setTimeout(() => {
      onboardMutate(formData, {
        onSuccess: (data) => {
          if (!data.success) {
            toast.error(data.error);
            setStep(1);
            return;
          }
          toast.success("Onboarding successful!");
          navigate("/authentication/signin");
        },
        onError: (error: any) => {
          toast.error(
            error.error ||
              "Unable to complete onboarding process. pls try again!"
          );
          setStep(1);
          return;
        },
      });
    }, 9000);
  };

  useEffect(() => {
    if (formData.skill !== "") {
      handleOnboarding();
    }
  }, [formData.skill]);

  return (
    <AuthLayout>
      <OnboardingProgressbar
        handlePrevious={handlePrevious}
        percentage={progress}
      />
      <LayoutContainer className="pb-[50px] lg:mt-[3.5rem]">
        {step === 1 && (
          <UserNameForm
            setValues={(values) => {
              setFormData((prev) => ({
                ...prev,
                firstname: values.firstname,
                lastname: values.lastname,
              }));
              handleNext();
            }}
            values={{
              firstname: formData.firstname,
              lastname: formData.lastname,
            }}
          />
        )}

        {step === 2 && (
          <UserInterestPage
            setValues={(values) => {
              setFormData((prev) => ({ ...prev, area: values }));
              handleNext();
            }}
            values={formData.area}
          />
        )}

        {step === 3 && (
          <UserLevelPage
            setValues={(value) => {
              setFormData((prev) => ({ ...prev, skill: value }));
              handleNext();
            }}
            value={formData.skill}
          />
        )}

        {step === 4 && <LoadingPage />}
      </LayoutContainer>
    </AuthLayout>
  );
}
