import { useGetEnrolledCourses } from "../../../../hooks/react-query/useCourse";
import ResumeLearning from "./resumeLearning";
import { useNavigate } from "react-router-dom";

function InProgess() {
  const navigate = useNavigate();

  const { data } = useGetEnrolledCourses();

  const handleClick = () => {
    navigate("/app/courses/courseId/lessonId");
    console.log("navigated");
  };

  return (
    <div className="mt-5 w-full grid gap-2 pb-5">
      <ResumeLearning inProgess={true} data={data} handleClick={handleClick} />
    </div>
  );
}

export default InProgess;
