import React from "react";
import { FaSpotify } from "react-icons/fa";
import { SiApplemusic, SiSoundcloud } from "react-icons/si";
import person from "../../../assets/images/production/personListeningToMusic.png";

interface MusicPlatform {
  name: string;
  url: string;
  icon: React.ElementType;
  color: string;
}

interface FearutedTracksProps {
  appleMusicLink: string;
  soundCloudLink: string;
  spotifyLink: string;
  quote: string;
  name: string;
  genre: string;
}

const FeaturedTracks: React.FC<FearutedTracksProps> = ({
  appleMusicLink,
  soundCloudLink,
  spotifyLink,
  quote,
  name,
  genre
}) => {
  const musicPlatforms: MusicPlatform[] = [
    {
      name: "Spotify",
      url: spotifyLink,
      icon: FaSpotify,
      color: "text-green-500",
    },
    {
      name: "Apple music",
      url: appleMusicLink,
      icon: SiApplemusic,
      color: "text-red-500",
    },
    {
      name: "Soundcloud",
      url: soundCloudLink,
      icon: SiSoundcloud,
      color: "text-orange-500",
    },
  ];
  return (
    <section className="max-w-[1440px] mx-auto mt-36">
      <div className="bg-yellow-50 font-sans">
        <div className=" mx-auto items-center justify-between flex flex-col-reverse md:flex-row">
          <div className="md:w-1/2 p-8 lg:p-20 mt-16 lg:mt-0">
            <h2 className="text-4xl font-bold mb-4">Featured tracks</h2>
            <p className="mb-6">
              Listen to the tracks created during his time with us
            </p>

            <ul className="space-y-4 text-sm w-full">
              {musicPlatforms.map((platform) => (
                <li key={platform.name} className="flex items-center">
                  <platform.icon
                    className={`mr-2 text-2xl ${platform.color}`}
                  />
                  <span className="font-semibold mr-2">{platform.name} -</span>
                  <a
                    href={`https://${platform.url}`}
                    className="text-blue-600 hover:underline"
                  >
                    {platform.url}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div className="md:w-1/2 mt-8 md:mt-0">
            <img
              src={person}
              alt="Person listening to music with headphones"
              className="w-full h-auto rounded-lg shadow-lg"
            />
          </div>
        </div>
      </div>
      <div className="max-w-3xl mx-auto text-center mt-16 p-8 supreme">
        <blockquote className="text-2xl text-[#1C7272] font-medium">
         {quote}
        </blockquote>
        <cite className="block mt-4 text-gray-600">
         {name} <span className="font-normal">(Genre: {genre})</span>
        </cite>
      </div>
    </section>
  );
};

export default FeaturedTracks;
