import React from "react";
import { signup_style } from "./style";
import { CustomButton, CustomTextbox } from "../../../../components/ui";
import { LayoutContainer } from "../components";
import { AuthLayout } from "../../../../components/layouts";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { SIGN_IN } from "./constant";
import { useForgotPassword } from "../../../../hooks/react-query/useAuthUser";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";

type Props = {};
interface ForgotPasswordProps {
  email: string;
}

const initialValues = {
  email: "",
};

const grid = "grid lg:gap-y-1";

const SignupSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});

export default function ForgotPassword({}: Props) {
  const { isLoading, mutate: forgotPasswordMutate } = useForgotPassword();
  const navigate = useNavigate();

  const onSubmit = (value: ForgotPasswordProps) => {
    const payload = value;
    console.log(payload);
    forgotPasswordMutate(payload, {
      onSuccess: (data) => {
        if (!data.success) {
          toast.error(data.error);
          return;
        }
        toast.success("Reset password link has been sent!");
        navigate("/authentication/reset-password");
      },
      onError: (error: any) => {
        toast.error(error.error || error);
      },
    });
  };

  return (
    <AuthLayout>
      <LayoutContainer className="pb-[50px] mt-[10rem]">
        <h1 className={signup_style.h1Style}>Lets get you back in!</h1>
        <p className={`${signup_style.pStyle} mx-auto py-1.5`}>
          Provide the email associated with this account
        </p>

        <Formik
          onSubmit={onSubmit}
          validationSchema={SignupSchema}
          initialValues={initialValues}
        >
          {({ errors, touched }) => (
            <Form className="w-[75%] mx-auto grid lg:gap-y-4 gap-y-2 mt-7">
              <div className={grid}>
                <label htmlFor="email" className={signup_style.label}>
                  {SIGN_IN.EMAIL_LABEL}
                </label>
                <CustomTextbox
                  id="email"
                  name="email"
                  useFormikForm={true}
                  placeholder={SIGN_IN.EMAIL_PLACEHOLDER}
                />
                {errors.email && touched.email ? (
                  <small className={signup_style.errorStyle}>
                    {errors.email}
                  </small>
                ) : null}
              </div>

              <CustomButton
                isLink={false}
                value={
                  isLoading ? (
                    <ClipLoader className="mx-auto" size={20} color="#fff" />
                  ) : (
                    "Continue"
                  )
                }
                className="text-white mt-2"
              />
            </Form>
          )}
        </Formik>
        <div className="w-fit mx-auto mt-2">
          <Link
            to="/authentication/signin"
            className="supreme mt-1 font-[500] text-center lg:text-[16px] text-[13px] leading-[24px] text-tsa-primary"
          >
            Back to login
          </Link>
        </div>
      </LayoutContainer>
    </AuthLayout>
  );
}
