import React, { useState } from "react";
import { h1Style, pStyle } from "../../waitList/style";
import { PAYMENT_CATEGORY, packages } from "../../../utils/constants";
import offRight from "../../../assets/icons/priceOffRight.svg";
import offLeft from "../../../assets/icons/priceOffLeft.svg";
import { useGetAllSubscriptions } from "../../../hooks/react-query/useSubscriptions";
import PackageCard from "../../../components/ui/PacakgeCard";

type Props = {};

export default function SectionOne({}: Props) {
  const { data: packagesData } = useGetAllSubscriptions();

  const [activeTab, setActiveTab] = useState(1);
  return (
    <section className="pb-20 w-full">
      <div className="grid gap-y-7 lg:pt-[15rem] pt-[4rem] ">
        {/* currency selection  */}
        <h1 className={`${h1Style} text-center lg:w-fit w-[80%] mx-auto`}>
          Flexible pricing made for you.
        </h1>
        <p className={`${pStyle} text-center lg:w-[33%] w-[90%] mx-auto`}>
          Choose from our affordable plans designed for all skilled levels and
          get access to expert-led courses.
        </p>
        <div>
          <ul className="flex bg-white rounded-full p-1  min-w-[270px] w-fit mx-auto mt-[2rem]">
            {PAYMENT_CATEGORY.map((item, i) => (
              <li
                key={i}
                className={`font-[supreme] px-8 py-2.5 rounded-full hover:cursor-pointer lg:text-[18px] text-[12px] ${
                  activeTab === item.value
                    ? "bg-[#EBF8F3] text-tsa-primary"
                    : "bg-white text-[#010516]"
                }`}
                onClick={() => setActiveTab(item.value)}
              >
                {item.text}
              </li>
            ))}
          </ul>
          <div className="w-full md:w-[50%] lg:w-[40%] max-w-[430px] mx-auto flex items-center justify-between px-6">
            <div></div>
            <div className="flex flex-col">
              <img src={offLeft} alt="" />
              <p className="text-[12px]">5% OFF</p>
            </div>
            <div className="flex flex-col">
              <img src={offRight} alt="" />
              <p className="text-[12px]">8% OFF</p>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:flex lg:flex-wrap gap-y-7 justify-between w-[80%] mx-auto mt-10">
            {packagesData?.data.map((item: any, i: number) => (
              <PackageCard packageInfo={item} activeTab={activeTab} key={i} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

// packages
