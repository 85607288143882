import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer";

const initialState = {
  courses: [],
};

const userSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {
    setCourses: (state: RootState, action) => {
      state.courses = action.payload;
    },
  },
});

export const { setCourses } = userSlice.actions;
export default userSlice.reducer;
