import React, { FC } from "react";

interface SocialProofProps {
  hitProduced: number;
  streamsAndDownloads: string;
  collaborations: number;
}

const SocialProof: FC<SocialProofProps> = ({
  hitProduced,
  streamsAndDownloads,
  collaborations,
}) => {
  return (
    <div className="bg-[#FFFEE0] w-full mt-20 py-10 space-y-8 md:space-y-0 md:py-16 flex-col md:flex-row flex justify-evenly">
      <div>
        <h1 className="font-black text-5xl text-center">{hitProduced}</h1>
        <p className="text-center mt-3">Hit produced</p>
      </div>
      <div>
        <h1 className="font-black text-5xl text-center">
          {">"}
          {streamsAndDownloads}
        </h1>
        <p className="text-center mt-3">Streams & Downloads</p>
      </div>
      <div>
        <h1 className="font-black text-5xl text-center">{collaborations}</h1>
        <p className="text-center mt-3">Notable collaborations</p>
      </div>
    </div>
  );
};

export default SocialProof;
