import checkCircle from "../../assets/icons/check-circle.svg";
import verifiedIcon from "../../assets/icons/verified.svg";
import check from "../../assets/icons/packageListCheck.svg";
import { useGetUserSubscription } from "../../hooks/react-query/useSubscriptions";
import { useEffect, useRef, useState } from "react";

interface Props {
  packageInfo?: any;
  recommended?: string | undefined;
  activeTab?: number;
  isSelected: boolean;
  handleSelect: () => void;
  userData: any;
  duration: string;
  onSelectPackage: (
    price: number,
    isSelected: boolean,
    duration: string,
    title: string,
    id: string
  ) => void;
}

export default function UserPackageCard({
  packageInfo,
  recommended,
  activeTab,
  isSelected,
  handleSelect,
  userData,
  duration,
  onSelectPackage,
}: Props) {
  const [isCurrentPackage, setIsCurrentPackage] = useState<boolean>(false);
  const durationRef = useRef<HTMLParagraphElement>(null);

  const tempDuration = durationRef.current?.textContent;

  const { data: subscriptionData } = useGetUserSubscription();

  const country = userData?.data.country;

  const title = subscriptionData?.data?.transaction?.package?.title || "";

  const userDuration = subscriptionData?.data?.duration;

  useEffect(() => {
    const subscriptionDuration =
      subscriptionData?.data?.duration?.toLowerCase();

    if (packageInfo?.title === title && duration === userDuration) {
      setIsCurrentPackage(true);
    } else {
      console.log({ userDuration });
      console.log({ duration });
      setIsCurrentPackage(false);
    }
  }, [
    duration,
    title,
    packageInfo,
    subscriptionData,
    tempDuration,
    userDuration,
  ]);

  const priceInNaira =
    activeTab === 0
      ? packageInfo?.price.monthly.ngn
      : activeTab === 1
      ? packageInfo?.price.quarterly.ngn
      : packageInfo?.price.biAnnually.ngn;

  const priceInDollar =
    activeTab === 0
      ? packageInfo?.price.monthly.usd
      : activeTab === 1
      ? packageInfo?.price.quarterly.usd
      : packageInfo?.price.biAnnually.usd;

  const handleSelectPackage = () => {
    handleSelect();
    const price = country.includes("igeria") ? priceInNaira : priceInDollar;
    onSelectPackage(
      price,
      isSelected,
      duration,
      packageInfo?.title,
      packageInfo?._id
    );
  };

  return (
    <div
      onClick={handleSelectPackage}
      className={`mx-auto rounded-[20px] border lg:py-[40px] py-[20px] px-[40px] hover:border-tsa-primary shadow ${
        isCurrentPackage
          ? "bg-[#F7F8F8] cursor-not-allowed"
          : "bg-white cursor-pointer"
      }  `}
    >
      <div className="flex justify-between items-center">
        <h1 className="supreme font-semibold text-[18px] leading-[28px]">
          {packageInfo?.title.charAt(0).toUpperCase() +
            packageInfo?.title.slice(1)}
        </h1>
        <div
          className="flex bg-[#39B5881A] py-1 px-2 rounded-full items-center gap-x-1 text-[#39B588] text-[12px] font-[400]"
          style={{ display: packageInfo?.recommended ? "" : "none" }}
        >
          <img src={verifiedIcon} alt="verified" />
          <span className="font-[supreme]">Recommended</span>
        </div>
      </div>
      <div className="mt-5">
        {country.includes("igeria") ? (
          <h1 className="font-[supreme] font-[700] lg:text-[35px] text-[30px] leading-[40px] text-[#010516]">
            NGN{Math.floor(priceInNaira / 100).toLocaleString()}
          </h1>
        ) : (
          <h1 className="font-[supreme] font-[700] lg:text-[35px] text-[30px] leading-[40px] text-[#010516]">
            USD{Math.floor(priceInDollar / 100).toLocaleString()}
          </h1>
        )}
        <p
          ref={durationRef}
          className="font-[supreme] font-[400] text-[16px]  leading-[24px] text-[#747478]"
        >
          {duration.charAt(0).toUpperCase() + duration.slice(1)}
        </p>
      </div>
      {isCurrentPackage ? (
        <div className="w-full mt-5">
          <button className="w-full font-[supreme] text-center py-[10px] rounded-[8px] border-[2px] border-gray-200 text-gray-500 bg-[#F7F8F8]">
            Current Plan
          </button>
        </div>
      ) : (
        <div className="w-full mt-5">
          <button
            // onClick={handleSelect}
            className={`w-full font-[supreme] text-center py-[10px] rounded-[8px] border-[1.4px] border-tsa-primary ${
              isSelected
                ? "text-white bg-tsa-primary"
                : "text-tsa-primary bg-white"
            } cursor-pointer`}
          >
            {isSelected ? "Selected" : "Select"}
          </button>
        </div>
      )}
      <ul className="mt-8 grid lg:gap-y-2 gap-y-1">
        {packageInfo.features.map((item: string, i: number) => (
          <li key={i} className="flex gap-x-4 items-start">
            <img src={check} alt="check" />
            <p className="font-[supreme] lg:text-[16px] text-[14px] font-[400] leading-[22px]">
              {item}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
}
