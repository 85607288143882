import React, { useState } from "react";
import { h2Style, pStyle } from "../../waitList/style";
import { CXM } from "../../../utils/constants";
import AliceCarousel from "react-alice-carousel";
import { InstructorCard } from "../../../components/ui";
import previous from "../../../assets/icons/slider-left.svg";
import next from "../../../assets/icons/slider-right.svg";
import { Link } from "react-router-dom";
import arrRight from "../../../assets/icons/arrRight.svg";
import ReviewCard from "../../../components/ui/ReviewCard";

type Props = {};

const responsive = {
  1024: { items: 1 },
};

export default function ReviewSection({}: Props) {
  const [activeTab, setActiveTab] = useState("Artist development");

  return (
    <section className="bg-white lg:py-[100px] py-[50px]">
      <div className="lg:px-[100px] px-[10px]">
        <h2 className={`${h2Style} text-center mb-2`}>Students stories.</h2>
      </div>

      <div className="md:px-[100px] px-3 mt-[4rem] relative w-full overflow-hidden">
        <AliceCarousel
          mouseTracking
          responsive={responsive}
          controlsStrategy="responsive"
          autoPlay={true}
          autoPlayInterval={2000}
          infinite={true}
          keyboardNavigation={true}
          renderPrevButton={() => {
            return (
              <img
                src={previous}
                className="absolute md:-left-[2.6rem] md:block hidden top-[10rem] "
                alt="previous"
              />
            );
          }}
          renderNextButton={() => {
            return (
              <img
                src={next}
                className="absolute md:-right-[2.6rem] md:block hidden top-[10rem] hover:cursor-pointer"
                alt="next"
              />
            );
          }}
        >
          {CXM.map((item) => (
            <ReviewCard />
          ))}
        </AliceCarousel>
      </div>
    </section>
  );
}
