import React, { useEffect } from "react";
import { RiVisaLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import {
  useCancelSubscription,
  useGetAllSubscriptions,
  useGetUserSubscription,
} from "../../../../hooks/react-query/useSubscriptions";
import { useGetAllCourses } from "../../../../hooks/react-query/useCourse";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";

function SubscriptionPlan({ changePlans }: any) {
  const navigate = useNavigate();

  const {
    data: subscriptionData,
    isLoading: isUserSubLoading,
    refetch,
  } = useGetUserSubscription();

  const { mutate, isLoading: isCancelSubLoading } = useCancelSubscription();

  useEffect(() => {
    refetch();
  }, [refetch, subscriptionData]);

  const userSubscription = subscriptionData?.data;

  const title = userSubscription
    ? userSubscription?.transaction?.package?.title
    : "";

  const subscriptionTitle = title
    ? title.charAt(0).toUpperCase() + title.slice(1).toLowerCase()
    : "";

  const amount = (
    userSubscription?.transaction?._amount / 100
  ).toLocaleString();

  const currency = userSubscription?.transaction?.currency.toUpperCase();

  const duration = userSubscription ? userSubscription?.duration : "";

  const subscriptionDuration = duration
    ? duration.charAt(0).toUpperCase() + duration.slice(1).toLowerCase()
    : "";

  //date logic
  const dateStr = userSubscription?.expiresAt;
  const date = new Date(dateStr);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const formattedSubExpDate = date.toLocaleDateString("en-US", options);

  const userSubscriptionArray = userSubscription
    ? Object.values(userSubscription)
    : [];

  const handleClick = () => {
    mutate(undefined, {
      onSuccess: (data) => {
        if (!data.success) {
          toast.error("Subscription does not exist");
          return;
        }
        refetch();
        toast.success("Subscription cancelled succesfully");
      },
      onError: (error: any) => {
        toast.error(error || error.error || "Error cancelling subscription");
        return;
      },
    });
  };

  return (
    <div className="w-full flex flex-col gap-10 pt-4 supreme">
      <div className="flex flex-col">
        <div className=" flex flex-col gap-1">
          <h1 className="supreme text-[16px] font-semibold text-gray-700">
            Plan
          </h1>
          {isUserSubLoading ? (
            <ClipLoader className="mx-auto" size={30} color="#1C7272" />
          ) : (
            <div>
              {!userSubscriptionArray.length ? (
                ""
              ) : (
                <p className="supreme text-[12px] font-normal text-gray-600">
                  {`You are currently subscribed to a ${duration} premium plan`}
                </p>
              )}

              {!userSubscriptionArray.length ? (
                <div>
                  <h2 className="supreme my-1">
                    You currently do not have an active subscription
                  </h2>
                  <button
                    onClick={() => navigate("/app/plans")}
                    className="bg-[#1C7272] mt-3 text-[13px] flex py-[8px] text-white items-center gap-1 px-2 rounded-[5px] w-max supreme cursor-pointer"
                  >
                    Choose a subscription plan
                  </button>
                </div>
              ) : (
                <div className="supreme bg-[#F1F2F3]  md:w-[70%] p-4 px-5 flex  gap-5 pt-6 rounded-lg justify-between ">
                  <div className="flex flex-col gap-3">
                    <div className="flex flex-col">
                      <p className="supreme text-[10px] font-normal text-gray-600">
                        Current plan
                      </p>
                      <p className="supreme text-[16px] font-semibold text-gray-800 tracking-wide">
                        {subscriptionTitle}
                      </p>
                    </div>
                    <div className="flex flex-col gap-2">
                      <p className="supreme text-[11px] font-semibold text-gray-800">
                        {`Frequency : ${subscriptionDuration}`}
                      </p>
                      <p className="supreme text-[11px] font-semibold text-gray-800">
                        {`Next billing date : ${formattedSubExpDate}`}
                      </p>
                      <p className="supreme text-[11px] font-semibold text-gray-800">
                        {`Amount : ${currency}${amount}`}
                      </p>
                    </div>
                  </div>
                  <div>
                    <button
                      onClick={() => navigate("/app/plans")}
                      className="bg-[#1C7272] text-[13px] flex py-[8px] text-white items-center gap-1 px-2 rounded-[5px] w-max supreme"
                    >
                      Change plan
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-col">
        <div className=" flex flex-col gap-1">
          <h1 className="supreme text-[16px] font-semibold text-gray-700">
            Payment method
          </h1>
          <p className="supreme text-[12px] font-normal text-gray-600">
            This card is currently being charged for your subscription
          </p>
        </div>
        <div className="bg-[#F1F2F3] h-[10vh] md:w-[70%] p-4 px-5 flex  gap-5 rounded-lg justify-between items-center ">
          <div className="flex flex-col gap-3">
            {/* <div className="flex  items-center gap-3">
              <RiVisaLine size={24} color="blue" />
              <p className="supreme text-[14px] font-semibold text-gray-800 tracking-wide">
                **** **** **** 5632
              </p>
            </div> */}
            <p className="text-gray-600 text-sm">
              You do not have any saved card{" "}
            </p>
          </div>
          <div>
            <button
              className="bg-[#F1F2F3] text-[#AAACB1] border-[1.5px] border-[#DCDEE2] text-[12px] flex py-[8px]  items-center gap-1 px-2 rounded-[5px] w-max supreme cursor-not-allowed"
              // onClick={changePlans}
            >
              Add card
            </button>
          </div>
        </div>
      </div>

      <div className="flex flex-col">
        <div className=" flex flex-col gap-1">
          <h1 className="supreme text-[16px] font-semibold text-gray-700">
            Cancel subscription
          </h1>
        </div>
        <div className="bg-[#F1F2F3] h-[10vh] md:w-[70%] p-4 px-5 flex  gap-5 rounded-lg justify-between items-center ">
          <div className="flex flex-col gap-3">
            <p className="supreme text-sm font-normal text-gray-600 tracking-wide">
              You can cancel your subscription at any time
            </p>
          </div>
          <div>
            <button
              onClick={handleClick}
              className="bg-[#F93232] text-[12px] flex py-[8px] text-white items-center gap-1 px-2 rounded-[5px] w-max supreme"
            >
              {isCancelSubLoading ? (
                <ClipLoader className="mx-auto" size={20} color="#fff" />
              ) : (
                "Cancel"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionPlan;
