import React from "react";
// import spiralArrow from "../../../assets/images/production/spiralArrow.svg";

interface AboutProps {
  image: any;
  about: string;
  name: string;
}

const About: React.FC<AboutProps> = ({ image, about, name }) => {
  return (
    <div className="max-w-7xl mx-auto px-4 font-sans pt-[21rem] lg:pt-[35rem]">
      <div className="flex flex-col items-center md:flex-row gap-8">
        <div className="md:w-[40%]">
          <img
            src={image}
            alt="Jeph in a red outfit and striped hat"
            className="w-[478px] h-[349px] md:h-[556px] object-cover object-top rounded-lg shadow-lg"
          />
        </div>
        <div className="md:w-[60%]">
          <h1 className="text-4xl font-bold mb-6">About</h1>
          <p className="mb-4 whitespace-pre-line">{about}</p>
        </div>
      </div>

     
    </div>
  );
};

export default About;
