import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print';
import { IoIosShareAlt } from 'react-icons/io';
import { RiDownload2Line } from 'react-icons/ri';
import Certificate from './Certificate';

function CourseCertificate() {
    const componentRef = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    return (
        <div className="my-[70px] mx-[20px] w-[80%]">
          <Certificate ref={componentRef} certificateId={123} />
          <div className="flex gap-5 mt-10">
            <div
              onClick={handlePrint}
              className="  p-2 w-max text-white bg-tsa-primary text-[12px] flex justify-center items-end gap-2 cursor-pointer"
            >
              Download Certificate PDF
              <RiDownload2Line size={20} />
            </div>
            <div className="border  cursor-pointer border-black py-2 px-4 md:px-2  md:w-max text-black text-[12px] flex justify-center items-center gap-2">
              Share
              <IoIosShareAlt size={20} />
            </div>
          </div>
          <div className="text-[13px] text-gray-600 mt-5 mb-[100px] supreme">
            {`This certificate above verifies that John Doe successfully completed the
                    course Mastering Afrobeat Music Production on 24th May, 2024 as taught by
                    Gospel Obi. The certificate indicates the entire course was completed as
                    validated by the student.`}
          </div>
        </div>
    );
}

export default CourseCertificate