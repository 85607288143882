import React, { useRef, useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { useTrackProgress } from "../../hooks/react-query/useCourse";

interface VideoPlayerProps {
  videoStyle?: string;
  src: string | undefined;
  type?: string;
  lessonId?: string;
}

export default function VideoPlayer({
  src,
  type,
  videoStyle,
  lessonId,
}: VideoPlayerProps) {
  const [watchedSeconds, setWatchedSeconds] = useState(0);
  const playerRef = useRef<ReactPlayer>(null);

  const { mutate } = useTrackProgress();

  const handleProgress = (state: { playedSeconds: number }) => {
    setWatchedSeconds(state.playedSeconds);
  };

  const handleEnd = () => {
    // Send the watched duration when the video ends
    mutate({ id: lessonId, payload: { watchedDuration: watchedSeconds } });
  };

  useEffect(() => {
    // Optionally, you could also send the data at regular intervals
    const interval = setInterval(() => {
      mutate({ id: lessonId, payload: { duration: watchedSeconds } });
    }, 60000); // Send every minute

    return () => clearInterval(interval);
  }, [watchedSeconds]);

  return (
    <div className="h-[50vh]">
      <ReactPlayer
        ref={playerRef}
        url={src}
        className="react-player"
        controls={true}
        style={{ pointerEvents: "auto" }}
        onProgress={handleProgress}
        onEnded={handleEnd}
      />
    </div>
  );
}
