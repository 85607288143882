import React, { useState } from "react";
import { IoCamera } from "react-icons/io5";
import { CiEdit, CiImageOn } from "react-icons/ci";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useUpdateProfile } from "../../../../hooks/react-query/useAuthUser";
import Loader from "../../../../components/ui/Loader";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { handleUpload } from "../../../../services/uploadFiles";
import { capitalizeFirstLetter } from "../../../../utils/helperFunctions";
import { CustomModal } from "../../../../components/ui";

interface PersonalDetailsProps {
  data: any;
  refetch: any;
}

function PersonalDetails({ data: userData, refetch }: PersonalDetailsProps) {
  const [shouldEdit, setShouldEdit] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [image, setImage] = useState(userData?.data?.image || null);
  const { mutate: updateProfileMutate, isLoading } = useUpdateProfile();

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setImageLoading(true);
    try {
      const file = e.target.files && e.target.files[0];
      if (file) {
        const res = await handleUpload(file);
        setImage(res.url);
        setIsModalOpen(false);
      }
    } catch (error) {
      console.error("file upload error", error);
    } finally {
      setImageLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstname: userData?.data?.firstname || "",
      lastname: userData?.data?.lastname || "",
      email: userData?.data?.email || "",
      headline: userData?.data?.headline || "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string(),
      lastname: Yup.string(),
      email: Yup.string(),
      headline: Yup.string(),
    }),
    onSubmit: (values) => {
      const payload = { ...values, image };
      updateProfileMutate(payload, {
        onSuccess: () => {
          setShouldEdit(true);
          refetch();
          toast("Update successful");
        },
        onError: (err: any) => {
          console.error("put error message", err);
        },
      });
    },
  });

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const imageDisplayCondition = image
    ? image
    : userData?.data?.firstname
    ? `https://api.dicebear.com/8.x/initials/svg?seed=${userData?.data?.firstname}-${userData?.data?.lastname}&backgroundColor=43a047`
    : `https://api.dicebear.com/8.x/initials/svg?seed=${userData?.data?.email}&backgroundColor=7cb342`;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="w-full flex flex-col gap-3 pt-4 supreme">
      <h1 className="supreme text-[16px] font-semibold text-gray-700">
        Personal details
      </h1>
      <div className="bg-[#F1F2F3] mb-20 md:w-[70%] p-4 flex flex-col gap-5 pt-6 rounded-md">
        <div className="flex gap-5 items-center">
          <div
            className={`relative w-max ${
              shouldEdit ? "cursor-not-allowed" : "cursor-pointer"
            }`}
          >
            <img
              className="h-[65px] w-[65px] rounded-[1000px] bg-[#F1F2F3] text-black flex items-center justify-center"
              src={imageDisplayCondition}
              alt="avatar"
              onClick={() => !shouldEdit && setIsModalOpen(true)}
            />
            <div className="bg-[#667185] w-max h-max p-[3px] rounded-full absolute -right-[4px] bottom-[3px] border border-white">
              <IoCamera
                color="white"
                size={12}
                onClick={() => !shouldEdit && setIsModalOpen(true)}
              />
            </div>
          </div>
          <div className="supreme flex flex-col gap-2">
            <p className="text-[18px] font-bold">
              {userData?.data?.firstname &&
                capitalizeFirstLetter(userData.data.firstname)}{" "}
              {userData?.data?.lastname &&
                capitalizeFirstLetter(userData.data.lastname)}
            </p>
            {shouldEdit && (
              <button
                className="bg-[#1C7272] text-[10px] flex py-[5px] text-white items-center gap-1 px-2 rounded-[5px] w-max"
                onClick={() => setShouldEdit(false)}
              >
                <CiEdit size={14} />
                Edit profile
              </button>
            )}
          </div>
        </div>
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-5">
          <div className="flex flex-col gap-1 supreme">
            <p className="text-[12px] font-medium">First name</p>
            <input
              type="text"
              disabled={shouldEdit}
              className={`text-[14px] rounded-md md:w-[70%] p-2 supreme placeholder-gray-400 placeholder:font-medium border border-gray-200 text-black ${
                shouldEdit ? "bg-white" : "bg-white"
              }`}
              placeholder="Enter first name"
              {...formik.getFieldProps("firstname")}
            />
          </div>
          <div className="flex flex-col gap-1 supreme">
            <p className="text-[12px] font-medium">Last name</p>
            <input
              type="text"
              disabled={shouldEdit}
              className={`text-[14px] md:w-[70%] rounded-md p-2 supreme placeholder-gray-400 placeholder:font-medium border border-gray-200 text-black ${
                shouldEdit ? "bg-white" : "bg-white"
              }`}
              placeholder="Enter last name"
              {...formik.getFieldProps("lastname")}
            />
          </div>
          <div className="flex flex-col gap-1 supreme">
            <p className="text-[12px] font-medium">Email</p>
            <input
              type="email"
              disabled
              className={`text-[14px] md:w-[70%] p-2 rounded-md supreme placeholder-gray-400 placeholder:font-medium border border-gray-200 text-tsa-greyDark hover:cursor-not-allowed ${
                shouldEdit ? "bg-white" : "bg-white"
              } `}
              placeholder="Enter email"
              {...formik.getFieldProps("email")}
            />
          </div>
          <div className="flex flex-col gap-1 supreme">
            <p className="text-[12px] font-medium">Headline</p>
            <input
              type="text"
              disabled={shouldEdit}
              className={`text-[14px] md:w-[70%] p-2 pb-20 rounded-md supreme placeholder-gray-400 placeholder:font-medium border border-gray-200 text-black ${
                shouldEdit ? "bg-white" : "bg-white"
              }`}
              placeholder="Tell us about yourself"
              {...formik.getFieldProps("headline")}
            />
          </div>
          {!shouldEdit && (
            <div className="flex items-center space-x-3">
              <button
                type="submit"
                className="bg-[#1C7272] text-[12px] flex py-[8px] text-white items-center gap-1 px-2 rounded-[5px] w-max supreme"
              >
                Save changes
              </button>
              <button
                onClick={() => setShouldEdit(true)}
                className="bg-red-600 text-[12px] flex py-[8px] text-white items-center gap-1 px-2 rounded-[5px] w-max supreme"
              >
                Cancel changes
              </button>
            </div>
          )}
        </form>
      </div>

      {isModalOpen && (
        <CustomModal
          modalStyle="lg:w-[20%] w-[90%] mt-[250px] bg-white mx-auto mt-[12rem] rounded-[12px] py-[30px] "
          onClose={closeModal}
        >
          <div className=" text-center space-y-5">
            <h2 className="supreme text-center font-[700] text-xl lg:text-2xl mb-2 leading-[38px]">
              Upload Image
            </h2>
            <input
              className="hidden"
              id="file-input"
              type="file"
              name="image"
              accept="image/*"
              onChange={handleImageChange}
            />
            <label htmlFor="file-input" className="cursor-pointer text-center">
              <div className="items-center space-y-2">
                <div className="w-fit mx-auto">
                  <CiImageOn size={32} />
                </div>
                <p>Choose an image to upload</p>
              </div>
            </label>
            {imageLoading && <CircularProgress color="success" />}
          </div>
        </CustomModal>
      )}
    </div>
  );
}

export default PersonalDetails;
