import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { MdKeyboardArrowDown } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

interface CourseContentProps {
  data: any;
  setContent?: (id: string) => void;
  getCurrentLessonId?: any;
}

function CourseContent({
  data,
  setContent,
  getCurrentLessonId,
}: CourseContentProps) {
  const isEnrollment = window.location.href.includes("enrollment");

  return (
    <div>
      {data?.data.group?.map((el: any) => {
        !isEnrollment && getCurrentLessonId(el.id);
        return (
          <Accordion key={el._id}>
            <AccordionSummary
              expandIcon={<MdKeyboardArrowDown />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <div>
                <h4 className="text-[14px] font-bold supreme">
                  Module {el.position}: {el.title}
                </h4>
              </div>
            </AccordionSummary>
            <AccordionDetail
              group={el}
              data={data}
              setContent={setContent}
              isEnrollment={isEnrollment}
            />
          </Accordion>
        );
      })}
    </div>
  );
}

const AccordionDetail = ({ group, data, setContent, isEnrollment }: any) => {
  const filteredLesson = data.data.lessons.filter(
    (e: any) => e.group === group._id
  );
  const sortedLessons = [...filteredLesson].sort(
    (a, b) => a.position - b.position
  );

  let percentageProgress = 0;

  return (
    <AccordionDetails>
      {sortedLessons.map((lesson: any, index: number) => (
        <div
          key={lesson._id}
          onClick={() => {
            if (!isEnrollment && setContent) {
              setContent(lesson._id);
            }
          }}
          className={`h-max w-full p-3 py-3 cursor-pointer  flex flex-col gap-1 justify-between text-[#1F2029] hover:bg-green-100  font-medium ${
            isEnrollment ? "cursor-default" : "cursor-pointer"
          }`}
        >
          <div className="flex items-center space-x-2 w-full">
            {!isEnrollment ? (
              <div className="flex gap-2 items-center w-5">
                {percentageProgress === 100 ? (
                  <FaCheckCircle size={18} className="text-tsa-primary" />
                ) : (
                  <div className="border-[2px] border-tsa-primary rounded-full">
                    <CircularProgressbar
                      value={percentageProgress}
                      strokeWidth={50}
                      styles={buildStyles({
                        strokeLinecap: "butt",
                        pathColor: " #1c7272",
                        trailColor: " #fff",
                      })}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className="supreme flex gap-2 items-center  font-semibold">
                {(index + 1).toString().padStart(2, "0")} -
              </div>
            )}
            <p className="text-gray-700 text-[13px]">{lesson.title}</p>
          </div>
        </div>
      ))}
    </AccordionDetails>
  );
};

export default CourseContent;
