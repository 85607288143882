import React from "react";
import { IoCloseSharp } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import logoImg from "../../../assets/images/logo-mini.svg";

type Props = {
  categoriesData: any;
  isCategoriesOpen: boolean;
  toggleCategories: () => void;
};

const Categories = ({
  categoriesData,
  isCategoriesOpen,

  toggleCategories,
}: Props) => {
  const navigate = useNavigate();
  const getAllCategoriesData = categoriesData?.data || [];

  return (
    <aside
      className={` px-3 w-[220px] z-[2000] bg-white shadow-md h-full fixed top-0 left-0  transform transition-transform duration-300 ${
        isCategoriesOpen ? "translate-x-0" : "-translate-x-full"
      } lg:hidden`}
    >
      <div className="flex justify-between mb-20 mt-10">
        <Link to="/">
          <img src={logoImg} alt="logo" className="" />
        </Link>
        <button onClick={toggleCategories} className="lg:hidden">
          <IoCloseSharp size={30} />
        </button>
      </div>

      <div className="flex items-center mb-7">
        <div className="h-4 w-1 mr-1 bg-tsa-primary"></div>
        <h1 className="">Filter By Categories</h1>
      </div>
      <ul className="space-y-5">
        <li
          onClick={() => {
            navigate("/all-courses");
            toggleCategories();
          }}
          className="supreme"
        >
          All Courses
        </li>
        {getAllCategoriesData.map((category: any, i: number) => {
          return (
            <li
              onClick={() => {
                navigate(`/all-courses?category=${category?._id}`);
                toggleCategories();
              }}
              className="supreme"
              key={i}
            >
              {category?.title}
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default Categories;
