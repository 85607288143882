// OtpForm.tsx
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

interface OtpFormProps {
  source: string | number | null;
  isLoading: boolean;
  handleSubmit: (otp: string) => void;
  handleResendOtp?: () => void;
}

const OtpForm = ({
  source,
  isLoading,
  handleSubmit,
  handleResendOtp,
}: OtpFormProps) => {
  const [codeBox, setCodeBox] = useState<string[]>(new Array(6).fill(""));
  const [timeLeft, setTimeLeft] = useState<number>(60);
  const [isResendDisabled, setIsResendDisabled] = useState<boolean>(true);

  const handleNext = () => {
    const isCodeValid = codeBox.every((value) => value.trim() !== "");
    if (!isCodeValid) {
      toast.error("Please enter a valid OTP");
      return;
    }

    const otp = codeBox.join("");
    handleSubmit(otp);
  };

  const handleChange = (element: HTMLInputElement, index: number): void => {
    if (isNaN(Number(element.value))) return;

    setCodeBox((prevCodeBox) =>
      prevCodeBox.map((d, idx) => (idx === index ? element.value : d))
    );

    // Focus next input
    if (element.nextSibling instanceof HTMLInputElement) {
      element.nextSibling.focus();
    }
  };

  const handleDelete = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ): void => {
    if (
      e.key === "Backspace" &&
      e.currentTarget.previousSibling instanceof HTMLInputElement
    ) {
      e.preventDefault();
      e.currentTarget.previousSibling.focus();

      setCodeBox((prevCodeBox) =>
        prevCodeBox.map((data, i) => (i !== index ? data : ""))
      );
    } else if (
      e.key === "Backspace" &&
      !e.currentTarget.previousSibling &&
      e.currentTarget.nextSibling instanceof HTMLInputElement
    ) {
      e.preventDefault();
      setCodeBox((prevCodeBox) =>
        prevCodeBox.map((data, i) => (i !== index ? data : ""))
      );
    }
  };

  const handlePaste = (e: React.ClipboardEvent) => {
    const value = e.clipboardData.getData("text");
    if (isNaN(Number(value))) return false;
    const updatedValue = value.toString().split("").slice(0, codeBox.length);
    setCodeBox(updatedValue);

    const focusedInput = e.currentTarget.parentNode?.querySelector(
      "input:focus"
    ) as HTMLInputElement;
    if (focusedInput) {
      focusedInput.blur();
    }
  };
  //timing
  useEffect(() => {
    if (timeLeft <= 0) {
      setIsResendDisabled(false);
      return;
    }

    const timer = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  const handleResend = () => {
    if (handleResendOtp) {
      handleResendOtp();
      setTimeLeft(60);
      setIsResendDisabled(true);
    }
  };

  const formatTime = (seconds: number): string => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, "0")}`;
  };
  
  return (
    <section className=" space-y-12 p-5 supreme">
      <div className="flex md:gap-[10px] gap-[7px] my-5 supreme w-fit mx-auto">
        {codeBox.map((value, i) => (
          <input
            type="text"
            className="max-w-[62px] w-full h-[62px] border text-center border-gray-200 bg-white text-black rounded-[6px]"
            key={i}
            value={value}
            maxLength={1}
            onChange={(e) => handleChange(e.target, i)}
            onFocus={(e) => e.target.select()}
            onKeyDown={(e) => {
              const target = e.target as HTMLInputElement;
              if (e.keyCode === 8 || e.key === "Backspace") {
                handleDelete(e, i);
              } else if (e.key === "ArrowLeft" && target.previousSibling) {
                e.preventDefault();
                (target.previousSibling as HTMLInputElement).focus();
              } else if (e.key === "ArrowRight" && target.nextSibling) {
                e.preventDefault();
                (target.nextSibling as HTMLInputElement).focus();
              }
            }}
            onPaste={handlePaste}
          />
        ))}
      </div>

      {/* Timer and resend button */}

      <div className="text-left space-y-2">
        {isResendDisabled ? (
          <p className="text-gray-600">Resend OTP in {formatTime(timeLeft)}</p>
        ) : (
          <button
            onClick={handleResend}
            className="text-tsa-primary  underline"
            type="button"
          >
            Resend OTP
          </button>
        )}
      </div>

      <button
        className="w-full py-[16px] rounded-[8px] text-white bg-tsa-primary"
        onClick={handleNext}
        disabled={isLoading || !source}
      >
        {isLoading ? "Verifying..." : "Submit"}
      </button>
    </section>
  );
};

export default OtpForm;