import React, { useEffect, useState } from "react";
import { LandingPageLayout } from "../../../components/layouts";
import toggleSidebar from "./Main";
import Aside from "./Aside";
import {
  useGetAllCategories,
  useGetAllCourses,
} from "../../../hooks/react-query/useCourse";
import Categories from "./Categories";
import { useLocation } from "react-router-dom";
import Main from "./Main";
import ScrollToTop from "../../../utils/ScrollToTop";

const Index = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const categoryId = searchParams.get("category");
  const [param, setParam] = useState(categoryId);
  const { isLoading, data } = useGetAllCourses({
    category: param,
  });

  const [studyCourses, setStudyCourses] = useState([]);

  useEffect(() => {
    setParam(categoryId);
  }, [categoryId]);

  useEffect(() => {
    if (data) {
      setStudyCourses(data.data);
    }
  }, [data]);

  const { data: categoriesData } = useGetAllCategories();
  const [isCategoriesOpen, setIsCategoriesOpen] = useState<boolean>(false);

  //search logic
  const handleSearch = (value: string) => {
    const coursesData = data?.data || [];
    const filteredCourses = coursesData.filter(
      (course: any) =>
        course.title.toLowerCase().includes(value.toLowerCase()) ||
        course?.main_contributor?.name
          .toLowerCase()
          .includes(value.toLowerCase())
    );
    setStudyCourses(filteredCourses);
  };

  //toggle filter by category
  const toggleCategories = () => {
    console.log("toggled");
    setIsCategoriesOpen((prev) => !prev);
  };

  return (
    <LandingPageLayout>
      <ScrollToTop />
      <div className="w-full flex -mt-[6rem] py-[50px]">
        <Aside categoriesData={categoriesData} />
        <Main
          studyCourses={studyCourses}
          handleSearch={handleSearch}
          isLoading={isLoading}
          toggleCategories={toggleCategories}
          categoryId={categoryId}
        />
        <Categories
          categoriesData={categoriesData}
          isCategoriesOpen={isCategoriesOpen}
          toggleCategories={toggleCategories}
        />
      </div>
    </LandingPageLayout>
  );
};

export default Index;
