import React, { useEffect, useState } from "react";
import { appLinks } from "../../../utils/constants";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logoImg from "../../../assets/images/logo-mini.svg";
import { useLogout } from "../../../hooks/react-query/useAuth";
import { toast } from "react-toastify";
import logout from "../../../assets/icons/exit.svg";

const gridStyle = "";
const linkListStyle =
  "flex items-center gap-x-3 w-[80%] mx-auto hover:bg-tsa-primary hover:text-white rounded-[10px] py-3.5 px-4 tracking-wide delay-100 ease-in";
const linkContainerStyle = "grid gap-y-3 w-full mt-[4rem]";
const linkNameStyle =
  "lg:block text-[14px] font-[300] leading-[24.55px] capitalize";

type SidebarProps = {
  setSidebarOpen: (isOpen: boolean) => void;
  isSidebarOpen: boolean;
  data: any;
};

export default function Sidebar({
  setSidebarOpen,
  isSidebarOpen,
  data: userData,
}: SidebarProps) {
  const { mutate: logoutMutation } = useLogout();
  const location = useLocation();
  const [currentHover, setCurrentHover] = useState<number | null>(null);

  //set profile picture logic
  const [image, setImage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (userData?.data?.image) {
      setImage(userData.data.image);
    } else if (userData?.data?.firstname) {
      setImage(
        `https://api.dicebear.com/8.x/initials/svg?seed=${userData.data.firstname}-${userData.data.lastname}&backgroundColor=43a047`
      );
    } else {
      setImage(
        `https://api.dicebear.com/8.x/initials/svg?seed=${userData?.data?.email}&backgroundColor=7cb342`
      );
    }
  }, [userData]);

  // logout logic
  const handleLogout = () => {
    logoutMutation(undefined, {
      onSuccess: (data) => {
        if (!data.success) {
          toast.error("Error logging out");
          return;
        }
        toast.success("Logged out successfully");
        localStorage.clear();
        navigate("/authentication/signin");
      },
      onError: (error: any) => {
        toast.error(error || error.error || "Login failed!, try again");
      },
    });
  };

  return (
    <aside
      className={` w-[220px] md:w-[300px] lg:w-[220px] z-[1010] bg-white shadow-md h-full fixed top-0 left-0  transform transition-transform duration-300 ${
        isSidebarOpen ? "translate-x-0" : "-translate-x-full"
      } lg:translate-x-0`}
    >
      <div className=" px-4 py-6">
        <Link to="/">
          <img src={logoImg} alt="logo" className="" />
        </Link>
      </div>

      <div className={gridStyle}>
        <div className={linkContainerStyle}>
          {appLinks.map((item) => {
            const targetRoute = location.pathname.includes(item.route);
            return (
              <Link
                key={item.id}
                to={item.route}
                onMouseOver={() => setCurrentHover(item.id)}
                onMouseOut={() => setCurrentHover(null)}
                onClick={() => setSidebarOpen(false)}
                className={`${linkListStyle} ${
                  targetRoute
                    ? "text-[#F0F1F5] bg-tsa-primary"
                    : "text-tsa-textGrey"
                }`}
              >
                <img
                  src={
                    targetRoute || currentHover === item.id
                      ? require(`../../../assets/icons/on.${item.img}`)
                      : require(`../../../assets/icons/${item.img}`)
                  }
                  alt={item.name}
                />
                <p className={linkNameStyle}>{item.name}</p>
              </Link>
            );
          })}

          <div className="absolute bottom-8  flex items-center space-x-3 w-[80%] left-1/2 -translate-x-1/2">
            {userData?.data?.firstname ? (
              <div className="flex items-center space-x-2">
                <img
                  className="h-9 w-9 rounded-full bg-tsa-primary text-white flex items-center justify-center"
                  src={image}
                  alt="profile"
                />

                <div className="text-xs">
                  <p>
                    {userData?.data?.firstname}{" "}
                    <span>{userData?.data?.lastname}</span>
                  </p>
                  <p className="text-xs">@{userData?.data?.firstname}</p>
                </div>
              </div>
            ) : (
              <p>Signout</p>
            )}
            <img onClick={handleLogout} src={logout} alt="" />
          </div>
        </div>
      </div>
      <div className="flex items-center">
        <div className="flex mt-[1rem] w-fit py-1.5 border-b-[2px] border-[#F0F1F5]"></div>
      </div>
    </aside>
  );
}
