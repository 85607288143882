import React from "react";
import { ProductionLayout } from "../../../components/layouts";
import { useParams } from "react-router-dom";
import { producers } from "../../../utils/constants";
import Intro from "./Intro";
import About from "./About";
import SocialProof from "./SocialProof";
import Discography from "./Discography";
import ApplyNow from "./ApplyNow";

const Producer = () => {
  const { id } = useParams();

  const singleProducer = producers.find((d) => d.name === id);
  console.log({ singleProducer });

  if (!singleProducer) {
    return null;
  }

  const {
    name,
    img,
    about,
    spotifyLink,
    boomplayLink,
    audiomackLink,
    socialProof,
  } = singleProducer;

  return (
    <ProductionLayout>
      <Intro name={name} />
      <About image={img} about={about} name={name} />
      <SocialProof
        hitProduced={socialProof.hitProduced}
        streamsAndDownloads={socialProof.streamsAndDownloads}
        collaborations={socialProof.collaborations}
      />
      <Discography
        spotifyLink={spotifyLink}
        boomplayLink={boomplayLink}
        audiomackLink={audiomackLink}
      />
      <ApplyNow />
    </ProductionLayout>
  );
};

export default Producer;
