import { useEffect, useState } from "react";
import apply from "../../../assets/images/production/apply.png";
import getSelected from "../../../assets/images/production/getSelected.png";
import getSelectedSmall from "../../../assets/images/production/getSelectedSmall.svg";
import production from "../../../assets/images/production/production.png";
import rightClearance from "../../../assets/images/production/rightClearance.png";



const HowItWorks = () => {
  const [isLargeScreen, setIsLargeScreen] = useState(false)

useEffect(() => {
  const checkScreenSize = () => setIsLargeScreen(window.innerWidth >= 1024)

  checkScreenSize()

  window.addEventListener('resize', checkScreenSize)

  return () => window.removeEventListener('resize', checkScreenSize)
}, [])

const processData = [
  {
    id: 1,
    title: "APPLY",
    description:
      "Submit your bio, link to released music (EP/ALBUM/Track), and Pay NGN 100,000 for review and selection",
    imageSrc: apply,
    imageAlt: "Application process",
    gridClass: "lg:row-start-1 lg:row-end-5",
    imageContainerClass: "bg-white p-4 rounded flex justify-center ",
    imageClass: "",
  },
  {
    id: 2,
    title: "GET SELECTED",
    description:
      "Our producers will invite you on selection to pay NGN 1,000,000 to experience a 3-4 days camp",
    imageSrc: isLargeScreen ? getSelected : getSelectedSmall,
    imageAlt: "Selection notification",
    gridClass: "lg:row-start-1 lg:row-end-4",
    imageContainerClass: "bg-white p-4 rounded -bottom-3",
    imageClass: "",
  },
  {
    id: 3,
    title: "PRODUCTION",
    description:
      "Collaborate with top producers to craft your unique sound and create songs",
    imageSrc: production,
    imageAlt: "Collaboration",
    gridClass: "lg:row-start-5 lg:row-end-8",
    imageContainerClass: "bg-white p-4 rounded flex justify-center",
    imageClass: "",
  },
  {
    id: 4,
    title: "RIGHT CLEARANCE",
    description:
      "After the camp you receive full Masters ownership of a maximum of 4 tracks",
    imageSrc: rightClearance,
    imageAlt: "Rights clearance",
    gridClass: "lg:row-start-4 lg:row-end-8",
    imageContainerClass: "bg-white p-4 rounded flex justify-center",
    imageClass: "",
  },
];


  return (
    <div className="relative">
      <h1 className="text-3xl md:text-4xl font-bold text-black text-center mb-6 font-[technorSemibold]">
        How it works
      </h1>
      <div className="absolute inset-0 bg-[#0E3939] top-20"></div>
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-rows-8 gap-6">
          {processData.map((item) => (
            <div
              key={item.id}
              className={`relative bg-yellow-50 rounded-lg px-6 pt-6 pb-32 overflow-y-clip ${item.gridClass}`}
            >
              <div className="flex items-center mb-3">
                <span className="bg-teal-700 text-white rounded-md font-[technorSemibold] w-10 h-10 flex items-center justify-center mr-3 flex-shrink-0">
                  {item.id}
                </span>
                <h2 className="text-sm font-semibold">{item.title}</h2>
              </div>
              <p className="mb-4 font-[technorSemibold]">{item.description}</p>
              <div
                className={`${item.imageContainerClass} absolute left-1/2 -translate-x-1/2 w-[80%] border border-teal-700  h-[50%] -bottom-3`}
              >
                <img
                  src={item.imageSrc}
                  alt={item.imageAlt}
                  className={`${item.imageClass}`}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
