import React from "react";
import studio from "../../../assets/images/production/studio.png";
import spotify from "../../../assets/images/production/musicIcons/spotify.svg";
import audiomack from "../../../assets/images/production/musicIcons/audiomack.svg";
import boomplay from "../../../assets/images/production/musicIcons/boomplay.svg";

interface MusicPlatform {
  name: string;
  url: string;
  icon: string;
}

interface FearutedTracksProps {
  spotifyLink: string;
  boomplayLink: string;
  audiomackLink: string;
}

const Discography: React.FC<FearutedTracksProps> = ({
  spotifyLink,
  boomplayLink,
  audiomackLink,
}) => {
  const musicPlatforms: MusicPlatform[] = [
    {
      name: "Spotify",
      url: spotifyLink,
      icon: spotify,
    },
    {
      name: "Booomplay",
      url: boomplayLink,
      icon: boomplay,
    },
    {
      name: "Audiomack",
      url: audiomackLink,
      icon: audiomack,
    },
  ];
  return (
    <section className="max-w-[1440px] mx-auto">
      <div className=" font-sans">
        <div className=" mx-auto items-center justify-between flex flex-col-reverse md:flex-row px-5 md:px-16 py-16">
          <div className="md:w-1/2 p-8 lg:p-20  lg:mt-0">
            <h2 className="text-4xl font-black mb-4">Explore discography</h2>
            <p className="mb-6">
              From breakout singles to iconic album tracks, explore LordSky
              discography
            </p>

            <ul className="space-y-4 text-sm w-full">
              {musicPlatforms.map((platform) => (
                <li key={platform.name} className="flex items-center">
                  <img
                    src={platform.icon}
                    alt={platform.name}
                    className="mr-2"
                  />
                  <span className="font-black mr-2 technor">
                    {platform.name} -
                  </span>
                  <a
                    href={platform.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:underline"
                  >
                    Listen on {platform.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div className="md:w-1/2 mt-8 md:mt-0">
            <img
              src={studio}
              alt="studio listening to music with headphones"
              className="w-full lg:w-[80%] h-auto rounded-lg shadow-lg"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Discography;
