import { useParams } from "react-router-dom";
import { ProductionLayout } from "../../../components/layouts";
import { artisteCaseStudy } from "../../../utils/constants";
import About from "./About";
import FeaturedTracks from "./FeaturedTracks";
import MoreCaseStudies from "./MoreCaseStudies";
import Intro from "./Intro";

const CaseStudy = () => {
  const { id } = useParams();

  const currentCaseStudy = artisteCaseStudy.filter((d) => d.name === id)[0];

  const {
    about,
    appleMusicLink,
    genre,
    image,
    name,
    quote,
    soundCloudLink,
    spotifyLink,
  } = currentCaseStudy;

  return (
    <ProductionLayout>
      <Intro name={name} />
      <About image={image} about={about} name={name} />
      <FeaturedTracks
        appleMusicLink={appleMusicLink}
        soundCloudLink={soundCloudLink}
        spotifyLink={spotifyLink}
        quote={quote}
        name={name}
        genre={genre}
      />
      <MoreCaseStudies />
    </ProductionLayout>
  );
};

export default CaseStudy;
