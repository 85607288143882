import React from "react";
import { FaArrowRight } from "react-icons/fa";
import {  useNavigate, useParams } from "react-router-dom";
import { artisteCaseStudy } from "../../../utils/constants";

const MoreCaseStudies: React.FC = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const filteredArtisteCaseStudy = artisteCaseStudy.filter(
    (d) => d.name !== id
  );

  return (
    <div className="bg-white font-sans">
      <div className="max-w-6xl mx-auto px-4 py-12">
        <h2 className="text-4xl font-bold mb-8">More case studies</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {filteredArtisteCaseStudy.slice(0, 3).map((study, index) => (
            <div
              key={index}
              onClick={() => navigate(`/production/case-study/${study.name}`)}
              className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer"
            >
              <img
                src={study.image}
                alt={study.name}
                className="w-full h-48 object-cover object-top"
              />
              <div className="p-4">
                <h3 className="text-xl font-bold mb-2 text-center">
                  {study.name}
                </h3>
                <div className="flex items-center justify-center ">
                  Read case study
                  <FaArrowRight className="ml-2" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="bg-yellow-50 py-12">
        <div className="max-w-4xl mx-auto text-center px-4">
          <h2 className="text-3xl font-bold mb-4">
            Ready to elevate your music production?
          </h2>
          <p className="text-2xl mb-8">Get started with SCRiiPO Collectives</p>
          <button
            onClick={() => navigate(`/production/home#apply-section`)}
            className="bg-teal-600 text-white px-6 py-3 rounded-md font-semibold hover:bg-teal-700 transition-colors"
          >
            Apply now
          </button>
        </div>
      </div>
    </div>
  );
};

export default MoreCaseStudies;
