import { CourseCard, SearchBox } from "../../../components/ui";
import { h3Style, pStyle } from "../../waitList/style";

import filterImg from "../../../assets/icons/filterBtn.svg";
import { Pagination } from "../../../components/ui";
import Loader from "../../../components/ui/Loader";
import { ClipLoader } from "react-spinners";
import { useEffect, useState } from "react";
import { useGetAllCategories } from "../../../hooks/react-query/useCourse";

type Props = {
  studyCourses: any;
  handleSearch: (value: string) => void;
  isLoading: boolean;
  toggleCategories: () => void;
  categoryId: any;
};

export default function Main({
  studyCourses,
  handleSearch,
  isLoading,
  toggleCategories,
  categoryId,
}: Props) {
  const { data } = useGetAllCategories();

  const [category, setCategory] = useState("All Courses");

  const [currentPage, setCurrentPage] = useState<number>(1);

  const categoryData = data?.data || [];

  const filteredCategory = categoryData.filter(
    (category: any) => category?._id === categoryId
  );

  const displayedCategory = filteredCategory[0]?.title;

  useEffect(() => {
    if (displayedCategory) {
      setCategory(displayedCategory);
    } else {
      setCategory("All Courses");
    }
  }, [displayedCategory, filteredCategory]);

  //pagination logic

  const itemsPerPage = 10;

  const totalItems = studyCourses?.length;

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = studyCourses?.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className="lg:w-[75%] w-full lg:pr-[70px] lg:px-0 px-5">
      <div className="w-full flex items-center gap-x-4">
        <div className="lg:w-[65%] w-4/5">
          <SearchBox
            placeholder="Search instructor, courses"
            handleSearch={handleSearch}
          />
        </div>
        <img
          src={filterImg}
          alt="filter"
          className="lg:hidden block lg:w-full w-1/5 hover:cursor-pointer"
          onClick={toggleCategories}
        />
      </div>

      <div className="mt-8">
        <h3 className={`${h3Style}`}>{category}</h3>
        {/* <p className={`${pStyle}`}>1024 courses</p> */}
      </div>

      {isLoading ? (
        <div className="w-fit mx-auto mt-20">
          <ClipLoader size={70} color="#1C7272" />
        </div>
      ) : currentItems?.length < 1 ? (
        <div className="w-fit mx-auto mt-20">
          <h1 className="text-2xl">No Course Found</h1>
        </div>
      ) : (
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5 mt-10">
          {currentItems.map((item: any, i: number) => (
            <CourseCard key={i} course={item} />
          ))}
        </div>
      )}
      <div className="mt-[4rem] lg:float-right">
        <Pagination
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          totalItems={totalItems}
          paginate={paginate}
        />
      </div>
    </div>
  );
}
