import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

interface PaginationProps {
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
  paginate: (pageNumber: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  itemsPerPage,
  totalItems,
  paginate,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const pageNumbers: (number | string)[] = [];

  if (totalPages <= 1) {
    pageNumbers.push(1);
  } else {
    if (currentPage > 4) {
      pageNumbers.push(1, "…");
    } else {
      pageNumbers.push(1);
    }

    const start = Math.max(2, currentPage - 2);
    const end = Math.min(totalPages - 1, currentPage + 2);

    for (let i = start; i <= end; i++) {
      if (i !== 1 && i !== totalPages) {
        pageNumbers.push(i);
      }
    }

    if (currentPage < totalPages - 4) {
      pageNumbers.push("…");
    }
    if (totalPages > 1) {
      pageNumbers.push(totalPages);
    }
  }

  const nextPage = () => {
    if (currentPage < totalPages) {
      paginate(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
    }
  };

  return (
    <nav className="w-full mx-auto md:w-[300px] lg:w-[500px] bg-white py-2 px-3 h-[56px] flex items-center rounded-xl">
      <ul className="flex items-center justify-between w-full gap-3">
        <li
          className={`flex items-center justify-between gap-2 text-[#313131] cursor-pointer font-medium w-[10%] ${
            currentPage === 1 ? "text-[#A4A4A4]" : ""
          }`}
          onClick={prevPage}
        >
          <MdKeyboardArrowLeft size={30} />
        </li>

        <div className="flex items-center justify-center flex-1 gap-2 py-1 overflow-x-scroll">
          {pageNumbers.map((number, index) => (
            <li
              key={index}
              className={
                number === currentPage
                  ? "bg-tsa-primary text-white py-1 px-4 rounded-lg transition-all duration-500 cursor-pointer border border-gray-300"
                  : "py-1 px-4 rounded-lg transition-all duration-500 cursor-pointer border border-gray-300"
              }
              onClick={() => typeof number === "number" && paginate(number)}
            >
              {number}
            </li>
          ))}
        </div>

        <li
          className={`flex items-center justify-between text-[#313131] cursor-pointer font-medium w-[10%] ${
            currentPage === totalPages ? "text-[#A4A4A4]" : ""
          }`}
          onClick={nextPage}
        >
          <MdKeyboardArrowRight size={30} />
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
