import React from "react";
import { GoArrowRight } from "react-icons/go";

interface resumeProps {
  isComplete?: boolean;
  inProgess?: boolean;
  isSaved?: boolean;
  handleClick: any;
  data?: any;
}

function ResumeLearning({
  isComplete,
  inProgess,
  isSaved,
  handleClick,
  data,
}: resumeProps) {
  return (
    <div></div>
    // <div className=" bg-gray-100 p-5 flex gap-5">
    //   <div className="w-[30%] h-full">
    //     <img
    //       src="/images/singer.svg"
    //       className="cursor-pointer w-full h-full"
    //       alt="singer"
    //     />
    //   </div>
    //   <div className="grid gap-1 w-[70%]">
    //     <h3 className="text-[15px] font-[500] leading-[21px] font-[supreme] leading-21px] text-[#010516] mb-1.5">
    //       {"Music Industry Networking and Relationship Building"}
    //     </h3>
    //     <p className="text-[14px] font-[400] leading-[19px] font-[supreme] text-[#010516] mb-1.5">
    //       By {"Kelvin Peter"}
    //     </p>
    //     {inProgess && (
    //       <>
    //         <div className="my-2">
    //           <div className="h-[5px] bg-[#DCDEE2] rounded-full">
    //             <div className="w-[40%] h-full bg-tsa-primary rounded-full"></div>
    //           </div>
    //           <div className="flex items-center justify-between">
    //             <span className="text-[10px] flex justify-end text-gray-700 supreme ">
    //               2 of 12 lessons
    //             </span>
    //             <span className="text-[10px] flex justify-end text-gray-700 supreme">
    //               70%
    //             </span>
    //           </div>
    //         </div>
    //         <div className="flex md:justify-start mt-3 md:mt-0">
    //           <div
    //             className=" py-2  text-tsa-primary supreme text-[14px] hover:underline cursor-pointer flex justify-center items-center gap-2"
    //             onClick={() => handleClick()}
    //           >
    //             Continue learning
    //             <GoArrowRight size={20} />
    //           </div>
    //         </div>
    //       </>
    //     )}
    //     {isComplete && (
    //       <>
    //         <div className="flex gap-2 items-center">
    //           <img
    //             src="/images/check.svg"
    //             className="w-[12px] h-[12px]"
    //             alt="check"
    //           />
    //           <span className="supreme text-[12px]">Completed</span>
    //         </div>
    //         <div className="flex md:justify-start mt-3 md:mt-0">
    //           <div
    //             className=" py-2  text-tsa-primary supreme text-[14px] hover:underline cursor-pointer flex justify-center items-center gap-2"
    //             onClick={() => handleClick()}
    //           >
    //             View certificate
    //             <GoArrowRight size={20} />
    //           </div>
    //         </div>
    //       </>
    //     )}

    //     {isSaved && (
    //       <>
    //         <div>
    //           <p className="font-[supreme] text-[12px] font-[400] leading-[19px] text-[#555C74] mb-5">
    //             {
    //               "Discover how to create a unique brand identity that resonates with your audi..."
    //             }
    //           </p>
    //           <div className="flex items-center gap-x-4  absolute bottom-3">
    //             <p className="text-[14px] font-[400] leading-[22px] font-[supreme] text-[#010516]">
    //               30 lessons
    //             </p>
    //             {/* <p>{course\.duration}</p> */}
    //             <p className="text-[14px] font-[400] leading-[22px] font-[supreme] text-[#010516]">
    //               2hrs 5mins
    //             </p>
    //           </div>
    //         </div>
    //         <div className="flex items-center justify-between">
    //           <span className="supreme text-[12px]">12 lessons 2hrs 5mins</span>
    //           <div
    //             className=" py-2  text-tsa-primary supreme text-[14px] hover:underline cursor-pointer flex justify-center items-center gap-2"
    //             onClick={() => handleClick()}
    //           >
    //             Go to course
    //             <GoArrowRight size={20} />
    //           </div>
    //         </div>
    //       </>
    //     )}
    //   </div>
    // </div>
  );
}

export default ResumeLearning;
