import React from "react";
import { Link } from "react-router-dom";
import { btnStyle, h1Style, pStyle } from "./style";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import { responsive, settings } from "../../utils/constants";
import img1 from "../../assets/images/Image-1.png";
import img2 from "../../assets/images/Image-2.png";
import img3 from "../../assets/images/Image-3.png";
import img4 from "../../assets/images/Image-4.png";
import img5 from "../../assets/images/Image-5.png";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

type Props = {
  btnTo: string;
  btnText: string;
};

export default function SectionOne({ btnText, btnTo }: Props) {
  return (
    <section className="lg:h-[135vh] sm:h-[107vh] md:h-[68vh] w-full">
      <div className="grid gap-y-7 lg:pt-[11rem] pt-[4rem] ">
        <h1 className={`${h1Style} text-center lg:px-0 px-7`}>
          Learn from{" "}
          <span className="font-[900] text-tsa-primaryLight font-[technorBold]">
            industry experts,
          </span>{" "}
          <br />
          Master your music.
        </h1>
        <p
          className={`${pStyle} text-center lg:px-0 px-5 lg:w-[35%] w-[95%] mx-auto`}
        >
          Want to elevate your musical skills? From beginners to advanced
          learners, we are dedicated to helping you grow
        </p>
        <div className="w-fit mx-auto mt-8">
          <Link className={`${btnStyle}`} to={btnTo}>
            {btnText}
          </Link>
        </div>
        {/* carousel  */}
        <div className=" mt-[4rem] relative w-full overflow-hidden">
          <AliceCarousel
            mouseTracking
            responsive={responsive}
            controlsStrategy="responsive"
            autoPlay={true}
            autoPlayInterval={2000}
            infinite={true}
            keyboardNavigation={true}
            disableButtonsControls={true}
          >
            <img
              src={img1}
              alt="boy playing guitar"
              className="mx-auto lg:w-[90%] w-fit object-cover"
            />
            <img
              src={img2}
              alt="boy playing key"
              className="mx-auto lg:w-[90%] w-fit object-cover"
            />
            <img
              src={img3}
              alt="boy with music note"
              className="mx-auto lg:w-[90%] w-fit object-cover"
            />
            <img
              src={img4}
              alt="boy holding guitar"
              className="mx-auto lg:w-[90%] w-fit object-cover"
            />
            <img
              src={img5}
              alt="girl singing"
              className="mx-auto lg:w-[90%] w-fit object-cover"
            />
          </AliceCarousel>
        </div>
      </div>
    </section>
  );
}
