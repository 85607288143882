import React, { useState } from "react";
import { h2Style, pStyle } from "../../waitList/style";
import { COURSES, CXM } from "../../../utils/constants";
import AliceCarousel from "react-alice-carousel";
import { CourseCard } from "../../../components/ui";
import previous from "../../../assets/icons/slider-left.svg";
import next from "../../../assets/icons/slider-right.svg";
import { Link, useNavigate } from "react-router-dom";
import arrRight from "../../../assets/icons/arrRight.svg";
import Loader from "../../../components/ui/Loader";
import { ClipLoader } from "react-spinners";

type Props = {
  courses: any;
  categories: any;
  isCoursesLoading: boolean;
};

const responsive = {
  0: { items: 1 },
  450: { items: 2 },
  800: { items: 2 },
  1024: { items: 3 },
};

export default function SectionTwo({
  courses,
  categories,
  isCoursesLoading,
}: Props) {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("Artist Development");

  const categoriesData = categories?.data || [];
  const coursesData = courses?.data || [];

  console.log({ coursesData });

  return (
    <section className="bg-white lg:py-[100px] py-[50px]">
      <div className="lg:px-[100px]  px-[10px]">
        <h2 className={`${h2Style} text-center`}>Explore our courses.</h2>
        <p className={`${pStyle} text-center`}>
          Find the ideal course for your skill level and interests, and learn
          from the best.
        </p>
      </div>
      <ul className="deactivateScrollbar max-w-[750px] min-w-[300px]  mx-auto flex mt-5 space-x-3 overflow-scroll">
        {categoriesData.map((item: any, i: number) => (
          <li
            onClick={() => {
              setActiveTab(item?.title);
              navigate(`/all-courses?category=${item?._id}`);
            }}
            key={i}
            className={`text-center text-xs md:text-base font-[supreme] px-3.5 py-1.5 
                        ${
                          item?.title === activeTab
                            ? "bg-tsa-primary text-white"
                            : "bg-[#F1F2F3] text-[#AAACB1]"
                        } hover:cursor-pointer rounded-[10px] whitespace-nowrap`}
          >
            {item?.title}
          </li>
        ))}
      </ul>
      {isCoursesLoading ? (
        <ClipLoader className="mx-auto" size={30} color="#1C7272" />
      ) : (
        <div className="lg:px-[100px]  px-3 mt-[4rem] relative w-full overflow-hidden">
          <AliceCarousel
            mouseTracking
            responsive={responsive}
            controlsStrategy="responsive"
            autoPlay={true}
            autoPlayInterval={4000}
            infinite={true}
            keyboardNavigation={true}
            renderPrevButton={() => {
              return (
                <img
                  src={previous}
                  className="absolute md:-left-[2.6rem] md:block hidden top-[10rem] "
                  alt="previous"
                />
              );
            }}
            renderNextButton={() => {
              return (
                <img
                  src={next}
                  className="absolute md:-right-[2.3rem] md:block hidden top-[10rem] hover:cursor-pointer"
                  alt="next"
                />
              );
            }}
          >
            {coursesData.map((item: any, i: number) => (
              <div key={i} className="px-2 md:px-4">
                <CourseCard course={item} />
              </div>
            ))}
          </AliceCarousel>
        </div>
      )}
      <Link
        to="/all-courses"
        className={`mt-10 font-[500] text-[16px] leading-[24px] text-[#010516] font-[supreme] text-center flex gap-x-2 items-center justify-center`}
      >
        See all courses <img src={arrRight} alt="arr-right" />
      </Link>
    </section>
  );
}
