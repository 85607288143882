import React from "react";
import ratingIcon from "../../assets/icons/starIcon.svg";
import { Link, useNavigate } from "react-router-dom";
import { CourseProps } from "./_model";
import user from "../../assets/images/Image-2.png";
import {
  convertSecondsToHoursMins,
  truncateText,
} from "../../utils/helperFunctions";

interface CourseCardProps {
  course?: any; //CourseProps,
  showModal?: () => void;
  showProgress?: boolean;
}

const CourseCard: React.FC<CourseCardProps> = ({
  course,
  showModal,
  showProgress,
}) => {
  return (
    <div className="relative  h-[380px] w-fit lg:w-fit md:h-[420px] lg:h-[400px] rounded-[12px] font-[supreme] bg-[#F1F2F380] mx-auto overflow-hidden">
      <Link to={`/all-courses/${course?._id || 1}`}>
        <div className="w-full h-[50%] overflow-hidden">
          <img
            src={course?.image || course.imageUrl || user}
            alt={course?.main_contributor?.name}
            className="object-cover w-full"
          />
        </div>
        <div className="p-[15px]">
          <h3 className="text-[15px] font-[500] leading-[21px] font-[supreme] leading-21px] text-[#010516] mb-1.5">
            {course?.title}
          </h3>
          <p className="text-[14px] font-[400] leading-[19px] font-[supreme] text-[#010516] mb-1.5">
            By {course?.main_contributor?.name || course?.author}
          </p>
          {showProgress && (
            <div className="my-2">
              <div className="h-[5px] bg-[#DCDEE2] rounded-full">
                <div className="w-[40%] h-full bg-tsa-primary rounded-full"></div>
              </div>
              <div className="flex items-center justify-between">
                <span className="text-[10px] flex justify-end text-gray-700 supreme ">
                  2 of 12 lessons
                </span>
                <span className="text-[10px] flex justify-end text-gray-700 supreme">
                  70%
                </span>
              </div>
            </div>
          )}
          <div>
            <p className="font-[supreme] text-[12px] font-[400] leading-[19px] text-[#555C74] mb-5">
              {course?.tagline}
              {truncateText(course?.description, 25)}...
            </p>
            <div className="flex items-center gap-x-4  absolute bottom-3">
              <p className="text-[14px] font-[400] leading-[22px] font-[supreme] text-[#010516]">
                {course?.lessons?.length || course?.lessons} lessons
              </p>
              <p className="text-[14px] font-[400] leading-[22px] font-[supreme] text-[#010516]">
                {convertSecondsToHoursMins(course?.duration)}
              </p>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default CourseCard;
