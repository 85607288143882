import React, { ReactNode, useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import { useGetProfile } from "../../../hooks/react-query/useAuthUser";
import Loader from "../../ui/Loader";
import { useNavigate } from "react-router-dom";

type Props = {
  children: ReactNode;
  backBtn?: boolean;
};

export default function AppLayout({ children, backBtn }: Props) {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const { isLoading, data } = useGetProfile();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !data?.data?.email) {
      navigate("/authentication/signin");
    }
  }, [isLoading, data, navigate]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="w-[100vw] h-[100vh] flex relative">
      <Sidebar
        data={data}
        setSidebarOpen={setSidebarOpen}
        isSidebarOpen={isSidebarOpen}
      />
      <Navbar
        data={data}
        setSidebarOpen={setSidebarOpen}
        isSidebarOpen={isSidebarOpen}
      />
      <div className="lg:w-full mx-auto lg:ml-[220px]">{children}</div>
    </div>
  );
}
