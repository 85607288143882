import React, { useState } from "react";

function UpdatePayment({ changePlans }: any) {
  return (
    <div className="w-full flex flex-col gap-10 pt-4 supreme ">
      <div className="flex flex-col">
        <div className=" flex flex-col gap-1">
          <h1 className="supreme text-[16px] font-semibold text-gray-700">
            Update payment method
          </h1>
          <p className="supreme text-[12px] font-normal text-gray-600">
            Your new payment method will be charged for your next billing cycle
          </p>
        </div>
      </div>
      <div className="bg-[#F1F2F3] h-[50vh] md:w-[70%] p-4 flex flex-col gap-5 pt-6 rounded-md pr-20">
        <div className="flex gap-2 items-center">
          <input type="radio" id="card" name="card" value="card" />
          <label htmlFor="vehicle1" className="supreme ">
            Credit/ Debit card
          </label>
          <br />
          <input type="radio" id="paypal" name="paypal" value="paypal" />
          <label htmlFor="vehicle1" className="supreme ">
            <img src="/images/PayPal.svg" alt="paypal" />
          </label>
        </div>
        <div className="flex flex-col gap-1 supreme">
          <p className="text-[14px] font-medium text-gray-800">
            Cardholder name
          </p>

          <input
            type={"text"}
            className="text-[14px] w-full rounded-md p-2 pr-10 supreme placeholder-gray-400 placeholder:font-medium text-black bg-white border border-gray-200"
            placeholder="card name"
          />
        </div>
        <div className="flex flex-col gap-1 supreme">
          <p className="text-[14px] font-medium  text-gray-800">Card number</p>

          <input
            type={"number"}
            className="text-[14px] w-full rounded-md p-2 pr-10 supreme placeholder-gray-400 placeholder:font-medium text-black bg-white border border-gray-200"
            placeholder="card number"
          />
        </div>
        <div className=" flex w-full gap-3">
          <div className="flex flex-col gap-1 supreme w-[50%]">
            <p className="text-[14px] font-medium  text-gray-800">
              Expiry date
            </p>

            <input
              type={"number"}
              className="text-[14px] w-full rounded-md p-2 pr-10 supreme placeholder-gray-400 placeholder:font-medium text-black bg-white border border-gray-200"
              placeholder="12/24"
            />
          </div>
          <div className="flex flex-col gap-1 supreme w-[50%]">
            <p className="text-[14px] font-medium  text-gray-800">CVV</p>

            <input
              type={"number"}
              className="text-[14px] w-full rounded-md p-2 pr-10 supreme placeholder-gray-400 placeholder:font-medium text-black bg-white border border-gray-200"
              placeholder="123"
            />
          </div>
        </div>

        <button
          className="bg-[#1C7272] text-[13px] flex py-[8px] text-white items-center gap-1 px-2 rounded-[5px] w-max supreme "
          onClick={changePlans}
        >
          Save changes
        </button>
      </div>
    </div>
  );
}

export default UpdatePayment;
