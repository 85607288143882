import { Navigate, Outlet } from "react-router-dom";
import { AppLayout } from "../components/layouts";

const ProtectedRoute = () => {
  const token = localStorage.getItem("scriipo-token");

  if (!token) {
    return <Navigate to="/authentication/signin" replace />;
  }
  return (
    <AppLayout>
      <Outlet />
    </AppLayout>
  );
};

export default ProtectedRoute;
