import { PaystackButton } from "react-paystack";
import { useNavigate } from "react-router-dom";
import { PaymentIntent } from "../../../../utils/enum";

interface CustomFieldInterface {
  display_name: string;
  variable_name: string;
  value: any;
}

function transfromMetadatIntent(
  metadata: IntentMetadataInterface
): Array<CustomFieldInterface> {
  const keys: Array<string> = Object.keys(metadata);
  const values: Array<any> = Object.values(metadata);

  const output = [];

  for (let i = 0; i < keys.length; i++) {
    const obj = {
      display_name: keys[i],
      variable_name: keys[i],
      value: values[i],
    };

    output.push(obj);
  }
  console.log({ output });
  return output;
}

interface IntentMetadataInterface {
  first_name?: string;
  last_name?: string;
  email_address?: string;
  phone_number?: string;
  music_link?: string;
  bio?: string;
  user_id?: string;
  package?: string;
  duration?: string;
}

type Props = {
  price: number;
  currency: string;
  paymentIntent: PaymentIntent;
  intentMetadata: IntentMetadataInterface;
};

function InitializePaymentButton({
  price,
  currency,
  paymentIntent,
  intentMetadata,
}: Props) {
  const navigate = useNavigate();

  console.log({ intentMetadata });

  const config = {
    reference: new Date().getTime().toString(),
    email: intentMetadata!.email_address as string,
    currency: currency.toLowerCase(),
    amount: price,
    metadata: {
      custom_fields: [
        ...transfromMetadatIntent(intentMetadata),
        {
          display_name: "payment_intent",
          variable_name: "payment_intent",
          value: paymentIntent,
        },
      ],
    },
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY as string,
  };

  // I can call this function anything
  const handlePaystackSuccessAction = (reference: any) => {
    if (paymentIntent === "student_subscription") {
      navigate(`/app/courses`);
    } else {
      navigate(`/production/home?application`);
    }
  };

  // I can call this function anything
  const handlePaystackCloseAction = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
  };

  const componentProps = {
    ...config,
    text: `Proceed to pay ${currency}${Math.floor(
      price / 100
    ).toLocaleString()}`,
    onSuccess: (reference: any) => handlePaystackSuccessAction(reference),
    onClose: handlePaystackCloseAction,
    className:
      "supreme bg-tsa-primary mt-5 py-[10px] w-full rounded-lg text-white ",
  };

  return (
    <div className="">
      <PaystackButton {...componentProps} />
    </div>
  );
}

export default InitializePaymentButton;
