import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ClipLoader } from "react-spinners";
import { CustomModal } from "../../../components/ui";
import InitializePaymentButton from "../../webapp/app/packages/InitializePaymentButton";
import { PaymentIntent } from "../../../utils/enum";

// Define the validation schema using Yup
const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phoneNumber: Yup.string().required("Phone number is required"),
  bio: Yup.string().required("Bio is required"),
  musicLink: Yup.string()
    .url("Must be a valid URL")
    .required("Music link is required"),
  financialObligation: Yup.boolean().oneOf(
    [true],
    "You must agree to the financial obligations"
  ),
  nonRefundable: Yup.boolean().oneOf(
    [true],
    "You must agree that the fee is non-refundable"
  ),
});

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  bio: string;
  musicLink: string;
  financialObligation: boolean;
  nonRefundable: boolean;
}

const ApplyNow: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<FormValues | null>(null);

  const applicationFee = 100000;
  const serviceCharge = 0;

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const section = document.querySelector(hash);
      if (section) {
        const yOffset = -100;
        const y =
          section.getBoundingClientRect().top + window.scrollY + yOffset;

        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  }, []);

  const initialValues: FormValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    bio: "",
    musicLink: "",
    financialObligation: false,
    nonRefundable: false,
  };

  const handleSubmit = (values: FormValues) => {
    console.log(values);
    setFormValues(values);
    setIsSubmitting(false);
    setIsModalOpen(true);
  };

  return (
    <section
      id="apply-section"
      className="max-w-2xl w-[90%] my-16 lg:w-full mx-auto p-6 bg-white rounded-lg shadow-lg border-2  border-teal-600"
    >
      <h2 className="text-3xl font-bold text-center mb-2">Apply now</h2>
      <p className="text-center text-gray-600 mb-6">
        Please fill out the form below to get started
      </p>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label
                htmlFor="firstName"
                className="block text-sm font-medium text-gray-700"
              >
                First name
              </label>
              <Field
                name="firstName"
                type="text"
                className="mt-1 border block w-full rounded-md supreme border-[#DCDEE2] shadow-sm focus:border-[#1C7272] focus:ring-[#1C7272] focus:ring-1 outline-none p-2"
              />
              <ErrorMessage
                name="firstName"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>
            <div>
              <label
                htmlFor="lastName"
                className="block text-sm font-medium text-gray-700"
              >
                Last name
              </label>
              <Field
                name="lastName"
                type="text"
                className="p-2 mt-1 block w-full rounded-md supreme  border border-[#DCDEE2] shadow-sm focus:border-[#1C7272] focus:ring-[#1C7272] focus:ring-1 outline-none"
              />
              <ErrorMessage
                name="lastName"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email address
              </label>
              <Field
                name="email"
                type="email"
                className="p-2 mt-1 block w-full rounded-md supreme  border border-[#DCDEE2] shadow-sm focus:border-[#1C7272] focus:ring-[#1C7272] focus:ring-1 outline-none"
              />
              <ErrorMessage
                name="email"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>
            <div>
              <label
                htmlFor="phoneNumber"
                className="block text-sm font-medium text-gray-700"
              >
                Phone number
              </label>
              <Field
                name="phoneNumber"
                type="tel"
                className="p-2 mt-1 block w-full rounded-md supreme  border border-[#DCDEE2] shadow-sm focus:border-[#1C7272] focus:ring-[#1C7272] focus:ring-1 outline-none"
              />
              <ErrorMessage
                name="phoneNumber"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="bio"
              className="block text-sm font-medium text-gray-700"
            >
              Bio
            </label>
            <Field
              name="bio"
              as="textarea"
              rows={4}
              className="p-2 mt-1 block w-full rounded-md supreme  border border-[#DCDEE2] shadow-sm focus:border-[#1C7272] focus:ring-[#1C7272] focus:ring-1 outline-none"
            />
            <ErrorMessage
              name="bio"
              component="div"
              className="text-red-500 text-sm mt-1"
            />
          </div>
          <div>
            <label
              htmlFor="musicLink"
              className="block text-sm font-medium text-gray-700"
            >
              Music link
            </label>
            <Field
              name="musicLink"
              type="url"
              placeholder="Provide links to some of your music samples"
              className="p-2 mt-1 block w-full rounded-md supreme  border border-[#DCDEE2] shadow-sm focus:border-[#1C7272] focus:ring-[#1C7272] focus:ring-1 outline-none"
            />
            <ErrorMessage
              name="musicLink"
              component="div"
              className="text-red-500 text-sm mt-1"
            />
          </div>
          <div>
            <label className="flex items-start">
              <Field
                type="checkbox"
                name="financialObligation"
                className="mt-1 rounded border-gray-300 text-teal-600 shadow-sm focus:border-teal-300 focus:ring focus:ring-teal-200 focus:ring-opacity-50"
              />
              <span className="ml-2 text-sm text-gray-600">
                By checking this box, I understand the financial obligations
                associated with this process, and I am financially prepared to
                proceed
              </span>
            </label>
            <ErrorMessage
              name="financialObligation"
              component="div"
              className="text-red-500 text-sm mt-1"
            />
          </div>
          <div>
            <label className="flex items-start">
              <Field
                type="checkbox"
                name="nonRefundable"
                className="mt-1 rounded border-gray-300 text-teal-600 shadow-sm focus:border-teal-300 focus:ring focus:ring-teal-200 focus:ring-opacity-50"
              />
              <span className="ml-2 text-sm text-gray-600">
                By checking this box, I agree that the application fee is
                non-refundable
              </span>
            </label>
            <ErrorMessage
              name="nonRefundable"
              component="div"
              className="text-red-500 text-sm mt-1"
            />
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              disabled={isSubmitting}
              className="w-[190px] flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#1C7272] hover:bg-teal-700 "
            >
              {isSubmitting ? (
                <ClipLoader color="#fff" size={30} />
              ) : (
                "Submit application"
              )}
            </button>
          </div>
        </Form>
      </Formik>

      {isModalOpen && (
        <CustomModal
          onClose={() => setIsModalOpen(false)}
          modalStyle="lg:w-[30%] w-[90%] mt-[180px] bg-white mx-auto rounded-[12px] py-[30px] px-5 pt-20 pb-10"
        >
          <div className="text-center space-y-5 supreme">
            <h4 className="text-xl font-semibold supreme text-left">
              Payment details
            </h4>

            <div className="bg-[#F1F2F380] bg-opacity-[#F1F2F380] py-4 px-3 space-y-3">
              <div className="flex items-center justify-between">
                <p>Application fee</p>
                <p>₦{applicationFee.toLocaleString()}.00</p>
              </div>
              <div className="flex items-center justify-between">
                <p>Service charge</p>
                <p>₦{serviceCharge.toLocaleString()}.00</p>
              </div>
              <div className="flex items-center justify-between font-semibold">
                <p>Total</p>
                <p>₦{(applicationFee + serviceCharge).toLocaleString()}.00</p>
              </div>
            </div>

            <div onClick={() => setIsModalOpen(false)}>
              <InitializePaymentButton
                currency="NGN"
                // price={100000000}
                price={100000}
                intentMetadata={{
                  first_name: formValues?.firstName,
                  last_name: formValues?.lastName,
                  email_address: formValues?.email,
                  phone_number: formValues?.phoneNumber,
                  music_link: formValues?.musicLink,
                  bio: formValues?.bio,
                }}
                paymentIntent={PaymentIntent.PRODUCER_APPLICATION}
              />
            </div>
          </div>
        </CustomModal>
      )}
    </section>
  );
};

export default ApplyNow;
