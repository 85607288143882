import arrowUp from "../../../assets/icons/arrUpMini.svg";
import arrowDown from "../../../assets/icons/arrDownMini.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

type AsideProps = {
  categoriesData: any;
};

export default function Aside({ categoriesData }: AsideProps) {
  const navigate = useNavigate();

  const getAllCategoriesData = categoriesData?.data || [];

  const [isOpen, setIsOpen] = useState(true);
  const [activeTab, setActiveTab] = useState("All courses");

  return (
    <aside className="w-[25%] lg:block hidden pt-[5rem]">
      <div className="w-[60%] mx-auto">
        <div>
          <button
            className="flex gap-x-2.5 items-center hover:cursor-pointer"
            onClick={() => setIsOpen(!isOpen)}
          >
            <span className="font-[700] text-[20px] leading-[30px] text-[#010516] font-[technorSemibold]">
              Courses
            </span>
            <img src={isOpen ? arrowUp : arrowDown} alt="arrow_icon" />
          </button>
          {isOpen && (
            <ul className="grid gap-y-5 text-left mt-5">
              <li
                className={`${
                  activeTab === "All courses"
                    ? "font-[600] pl-[8px] border-l-[4px] border-tsa-primary"
                    : "font-[400]"
                } font-[supreme] text-[16px]  hover:pl-[8px] hover:border-l-[4px] hover:border-tsa-primary`}
              >
                <button
                  onClick={() => {
                    setActiveTab("All courses");
                    navigate(`/all-courses`);
                  }}
                >
                  All courses
                </button>
              </li>
              {getAllCategoriesData.map((item: any, i: number) => (
                <li
                  key={i}
                  className={`${
                    activeTab === item?.title
                      ? "font-[600] pl-[8px] border-l-[4px] border-tsa-primary"
                      : "font-[400]"
                  } font-[supreme] text-[16px]  hover:pl-[8px] hover:border-l-[4px] hover:border-tsa-primary`}
                >
                  <button
                    onClick={() => {
                      setActiveTab(item?.title);
                      navigate(`/all-courses?category=${item?._id}`);
                    }}
                  >
                    {item?.title}
                  </button>
                </li>
              ))}
            </ul>
          )}
          <button
            className="flex gap-x-2.5 items-center hover:cursor-pointer mt-7"
            onClick={() => setIsOpen(!isOpen)}
          >
            <span className="font-[700] text-[20px] leading-[30px] text-[#010516] font-[technorSemibold]">
              Skills Level
            </span>
            <img src={!isOpen ? arrowUp : arrowDown} alt="arrow_icon" />
          </button>
        </div>
      </div>
    </aside>
  );
}
