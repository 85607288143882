import { request } from "../hooks/api";
import {} from "../hooks/types";

class SubscriptionService {
  async getAllSubscriptions() {
    try {
      const response = await request(
        `/package`,
        "GET",
        null,
        true,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getUserSubscriptions() {
    try {
      const response = await request(
        `/subs/user`,
        "GET",
        null,
        true,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async cancelSubscription() {
    try {
      const response = await request(
        `/subs/cancel`,
        "POST",
        null,
        true,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default SubscriptionService;
