import React from "react";
import { btnStyle, h2Style } from "./style";
import { Link } from "react-router-dom";
import bg1 from "../../assets/icons/bg01.svg";
import bg2 from "../../assets/icons/bg02.svg";

type Props = {
  text?: string;
  to?: string;
};

export default function SectionSeven({ text, to }: Props) {
  return (
    <section className="relative bg-[#FFFEE0] lg:h-[70vh] h-[50vh] md:h-[23vh] bg-pattern overflow-hidden">
      <img
        src={bg2}
        alt="bg_pattern"
        className="absolute lg:left-0 md:-left-[70px] -left-[100px] lg:w-fit md:w-[30%] w-[50%] h-full object-contain"
      />
      <img
        src={bg1}
        alt="bg_pattern"
        className="absolute lg:right-0 md:-right-[70px] -right-[100px] lg:w-fit md:w-[27%] w-[50%] h-full object-contain"
      />
      <div className="lg:w-[55%] md:w-[65%] w-[80%] mx-auto h-fit lg:pt-[9.5rem] pt-[4rem] items-center">
        <h2 className={`${h2Style} text-center `}>
          Ready to elevate your music skills? Grab your spot now
        </h2>
        <div className="mx-auto w-fit mt-10">
          <Link to={to || "/waitlist/join"} className={`${btnStyle}`}>
            {text || "Join the waitlist"}
          </Link>
        </div>
      </div>
    </section>
  );
}
