import React, { ChangeEvent, useEffect, useState } from "react";
import { LandingPageLayout } from "../../../components/layouts";
import { Link, useNavigate, useParams } from "react-router-dom";
import backIcon from "../../../assets/icons/backArrow.svg";
import { CommentList, CourseCard, VideoPlayer } from "../../../components/ui";
import { btnStyle, h2Style, h3Style, pStyle } from "../../waitList/style";
import shareIcon from "../../../assets/icons/shareIcon.svg";
import saveIcon from "../../../assets/icons/saveIcon.svg";
import levelIcon from "../../../assets/icons/levelIcon.svg";
import reviewIcon from "../../../assets/icons/reviewIcon.svg";
import audioIcon from "../../../assets/icons/speaker.svg";
import durationIcon from "../../../assets/icons/durationIcon.svg";
import girlImg from "../../../assets/images/girlImg.png";
import commentIcon from "../../../assets/icons/commentIcon.svg";
import previous from "../../../assets/icons/slider-left.svg";
import next from "../../../assets/icons/slider-right.svg";
import {
  testComments as comments,
  CXM,
  study_courses,
} from "../../../utils/constants";
import AliceCarousel from "react-alice-carousel";
import {
  useGetPublicSingleCourse,
  useGetSingleCourse,
} from "../../../hooks/react-query/useCourse";
import Loader from "../../../components/ui/Loader";
import { Pagination } from "../../../components/ui";

type Props = {};

const responsive = {
  0: { items: 1 },
  450: { items: 2 },
  800: { items: 3 },
  1024: { items: 3 },
};

export default function CourseDetail({}: Props) {
  const { id } = useParams();

  const navigate = useNavigate();

  const { data: courseData, isLoading } = useGetPublicSingleCourse(
    id as string
  );

  const handleClick = () => {
    navigate(`/all-instructors/instructor/${0}`);
  };

  //pagination logic
  const [currentPage, setCurrentPage] = useState<number>(1);

  const itemsPerPage = 5;

  const totalItems = comments?.length;

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentComments = comments?.slice(indexOfFirstItem, indexOfLastItem);

  if (isLoading) {
    console.log(isLoading);
    return <Loader />;
  }

  return (
    <LandingPageLayout>
      <section className="pb-[40px]">
        <div className="grid lg:grid-cols-2 grid-cols-1 lg:px-[100px] md:px-[50px] px-[30px] items-center font gap-x-10 gap-y-3">
          <div>
            <button
              onClick={() => navigate(-1)}
              className="items-center supreme flex gap-x-2 mb-7"
            >
              <img src={backIcon} alt="back" />
              &nbsp; Back to courses
            </button>
            <VideoPlayer
              videoStyle="rounded-[12px] lg:h-[400px] h-[200px]"
              src={courseData?.data?.introductory_video}
            />
            <div className="flex items-center gap-x-7 py-4">
              <span className="flex items-center gap-x-1.5 supreme">
                <img src={saveIcon} alt="share" /> Save
              </span>
              <span className="flex items-center gap-x-1.5 supreme">
                <img src={shareIcon} alt="save" /> Share
              </span>
            </div>{" "}
          </div>
          <div className="w-full">
            <h2 className={`${h2Style}`}>
              {courseData?.data?.title ||
                "Understanding Basic Musical Notation"}
            </h2>
            <p className={`${pStyle} py-1 capitalize`}>
              By {courseData?.data?.main_contributor.name}{" "}
              {courseData?.data?.main_contributor.nickname ||
                study_courses[1].author}
            </p>
            <ul className="grid gap-y-3 py-5">
              <li className="flex items-center gap-x-2">
                <span className="flex items-center gap-x-1 supreme">
                  <img src={levelIcon} alt="level" />
                  &nbsp; Level
                </span>
                <span className="supreme capitalize">
                  {courseData?.data?.level || "Beginner"}
                </span>
              </li>
              <li className="flex items-center gap-x-2">
                <span className="flex items-center gap-x-1 supreme">
                  <img src={reviewIcon} alt="review" />
                  &nbsp; Review
                </span>
                <span className="supreme">90% positive review</span>
              </li>
              <li className="flex items-center gap-x-2">
                <span className="flex items-center gap-x-1 supreme">
                  <img src={audioIcon} alt="audio" />
                  &nbsp; Audio
                </span>
                <span className="supreme capitalize">
                  {courseData?.data?.language || "English"}
                </span>
              </li>
              <li className="flex items-center gap-x-2">
                <span className="flex items-center gap-x-1 supreme">
                  <img src={durationIcon} alt="duration" />
                  &nbsp; Duration
                </span>
                <span className="supreme">
                  {courseData?.data?.lessons.length || "5"} lessons (
                  {courseData?.data?.duration || "54"}) Seconds
                </span>
              </li>
            </ul>
            <Link
              to={"/authentication/signin"}
              className={`w-full supreme flex justify-center mt-3 ${btnStyle}`}
            >
              Start your journey
            </Link>
          </div>
        </div>
      </section>

      <section className="pb-[40px]">
        <div className="grid lg:grid-cols-2 grid-cols-1 lg:px-[100px] md:px-[50px] px-[30px] gap-x-10 gap-y-10">
          <div>
            <h3 className={`${h3Style}`}>About this course</h3>
            <div className={`${pStyle} supreme mt-3`}>
              {courseData?.data?.description ||
                'Welcome to "Music Theory Fundamentals," an engaging and comprehensive course designed for beginners who are eager to dive into the world of music theory. Whether you\'re an aspiring musician, a budding composer, or simply someone with a passion for music, this course will provide you with the essential building blocks to understand and create music.'}
            </div>

            <div className="mt-7">
              <h3 className={`${h3Style}`}>Course content</h3>
              <ul className="grid gap-y-3 mt-2 list-disc font-[400] lg:text-[18px] text-[14px] leading-[24px] text-[#010516]">
                {courseData?.data?.group.map((d: any) => (
                  <li className=" ml-3 supreme">
                    {d?.title}
                    {/* <span className="supreme">{el?.title}</span> */}
                  </li>
                ))}
              </ul>
              {/* <button className="supreme mt-7 font-[500] text-[16px] leading-[24px] text-[#010516]">
                View all {courseData?.data?.group.length} lessons &nbsp;&darr;
              </button> */}
            </div>
          </div>
          <div>
            <h3 className={`${h3Style}`}>Instructor</h3>
            <div className="flex items-center gap-x-4 mt-4">
              <img
                src={courseData?.data?.main_contributor.image || girlImg}
                alt="author"
                className="lg:h-[80px] lg:w-[80px] h-[60px] w-[60px] rounded-full object-cover"
              />
              <div>
                <p className="supreme font-[500] text-[20px] leading-[24px] text-[#010516]">
                  {courseData?.data?.main_contributor.name || "Kanye West"}
                </p>
                <p className="supreme font-[400] text-[16px] leading-[22px] text-[#555C74] mt-1">
                  {courseData?.data?.main_contributor.title || "Instructor"}
                </p>
              </div>
            </div>
            <p className={`${pStyle} supreme mt-3 mb-5 lg:w-[600px] `}>
              {courseData?.data?.main_contributor.detail ||
                "Discover how to create a unique brand identity that resonates with your audi Discover how to create a unique brand..."}
            </p>
            <div
              onClick={handleClick}
              className="supreme  font-[500] text-[16px] leading-[24px] text-[#010516] cursor-pointer"
            >
              View profile &nbsp;&rarr;
            </div>
          </div>
        </div>
      </section>

      {/* <section className="pb-[40px] lg:px-[100px] md:px-[50px] px-[30px] mt-[3rem]">
        <h3 className={`${h3Style}`}>Reviews</h3>
        <div className="flex items-center font-[500] gap-x-7 leading-[28px] lg:text-[20px] text-[18px] rounded-[12px] border-[.8px] p-[20px] w-fit mt-7 border-[#DCDEE2]">
          <div className="supreme flex items-center">
            <img src={commentIcon} alt="" />
            &nbsp; {10} reviews
          </div>
          <div className="supreme flex items-center">
            <img src={reviewIcon} alt="comment" className="h-[25px]" />
            &nbsp; {90}% positive
          </div>
        </div>
        <div className="mt-7 border-b border-[#DCDEE2] lg:pb-[100px] pb-[50px]">
          {currentComments.map((comment: any, i: number) => {
            return (
              <CommentList
                key={i}
                isLast={comments?.length - 1 === i}
                item={comment}
              />
            );
          })}

          <Pagination
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={totalItems}
            paginate={paginate}
          />
        </div>
      </section> */}

      {/* <section className="lg:pb-[70px] pb-[40px] lg:px-[100px] md:px-[50px] px-[30px] mt-[3rem]">
        <div className="flex items-center justify-between">
          <h3 className={`${h3Style}`}>
            Students who liked this course also liked
          </h3>
        </div>
        <div className="px-3 mt-[2rem] relative w-full overflow-hidden">
          <AliceCarousel
            mouseTracking
            responsive={responsive}
            controlsStrategy="responsive"
            autoPlay={true}
            autoPlayInterval={2000}
            infinite={true}
            keyboardNavigation={true}
            renderPrevButton={() => {
              return (
                <img
                  src={previous}
                  className="absolute md:-left-[4.5rem] md:block hidden top-[10rem] "
                  alt="previous"
                />
              );
            }}
            renderNextButton={() => {
              return (
                <img
                  src={next}
                  className="absolute md:-right-[2.3rem] md:block hidden top-[10rem] hover:cursor-pointer"
                  alt="next"
                />
              );
            }}
          >
            {CXM.map((item) => (
              <CourseCard course={item} />
            ))}
          </AliceCarousel>
        </div>
      </section> */}
    </LandingPageLayout>
  );
}
