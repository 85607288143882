import heroBg from "../../../assets/images/production/caseStudyHero.svg";

const Intro = ({ name }: { name: string }) => {
  return (
    <div
      className="bg-cover  bg-no-repeat bg-right px-4 md:px-8 lg:px-16 w-full text-center lg:text-left h-[300px] lg:h-[540px] absolute top-0 pt-24 lg:pt-52"
      style={{
        backgroundImage: `url(${heroBg})`,
      }}
    >
      <div className="max-w-2xl w-[80%] mx-auto lg:mx-0 lg:w-full">
        <h1 className="text-[#C3C212] font-[technorSemibold] text-4xl md:text-5xl lg:text-6xl font-bold mb-4 md:mb-6">
          {name.toUpperCase()}
        </h1>
        <p className="text-white text-lg font-light supreme lg:w-[80%]">
          See how {name.split(" ").slice(0, 1)} transformed his music with our
          top-tier production support.
        </p>
      </div>
    </div>
  );
};

export default Intro;
