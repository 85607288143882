import { ReactNode, useState } from "react";
import Navbar from "../LandingPage/Navbar";
import Footer from "../../../pages/landingPage/landing/Footer";
import ScrollToTop from "../../../utils/ScrollToTop";

const ProductionLayout = ({ children }: { children: ReactNode }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen((prev) => !prev);
  };
  return (
    <>
      <ScrollToTop />
      <Navbar
        setSidebarOpen={setSidebarOpen}
        toggleSidebar={toggleSidebar}
        isSidebarOpen={isSidebarOpen}
      />
      {children}
      <Footer />
    </>
  );
};

export default ProductionLayout;
