import React from "react";
import { LandingPageLayout } from "../../../components/layouts";
import { h1Style, h2Style, pStyle } from "../../waitList/style";
import { ABOUT, study_courses } from "../../../utils/constants";
import { CourseCard } from "../../../components/ui";
import img from "../../../assets/images/img_.png";

type Props = {};

export default function InstructorDetail({}: Props) {
  return (
    <LandingPageLayout>
      <div className="-mt-[10.7rem] lg:h-[120vh] h-[60vh] bg">
        <div className="relative w-full h-full">
          <div className="w-full absolute bottom-0 left-1/2  transform -translate-x-1/2">
            <h1 className={`${h1Style} text-center lg:py-10 py-3`}>
              Kazeem Taiwo
            </h1>
            <div className=" mx-auto overflow-hidden">
              <img
                src={
                  "https://eu2.contabostorage.com/dc1f0473623e454784dc331d572188cb:courses/beginners/kaycekeys/kacekeys.webp"
                }
                alt=""
                className="object-cover lg:w-[65%] w-[75%] lg:h-[75vh]  h-[35vh] rounded-tr-[20px] rounded-tl-[20px] mx-auto"
              />
            </div>
          </div>
        </div>
      </div>
      <section className="bg-white lg:py-[50px] py-[30px] px-[10px]">
        <p className={`${pStyle} lg:w-[60%] md:w-[80%] w-[95%] mx-auto`}>
          {ABOUT}
        </p>
      </section>
      <section className="lg:py-[80px] pb-[40px] md:px-[100px] px-[20px]">
        <h2 className={`${h2Style} text-center py-[3rem]`}>
          Courses by Tazeem taiwo
        </h2>
        <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-y-8 justify-items-center">
          {study_courses.slice(0, 6).map((item, i) => (
            <div className="px-3 lg:px-5">
              <CourseCard key={i} course={item} />
            </div>
          ))}
        </div>
      </section>
    </LandingPageLayout>
  );
}
