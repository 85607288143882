// EmailVerificationOtp.tsx
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthLayout } from "../../../../components/layouts";
import { LayoutContainer, OtpForm } from "../components";
import {
  useResendOtp,
  useVerifyOtp,
} from "../../../../hooks/react-query/useAuthUser";
import { toast } from "react-toastify";

const EmailVerificationOtp = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");

  const { mutate: otpMutate, isLoading: verifyOtpLoading } = useVerifyOtp({
    onSuccess: (data: any) => {
      if (data.success === false) {
        toast.error(data.error);
        return;
      } else {
        toast.success("Your email has been verified!");
        navigate("/authentication/signin");
      }
    },
  });

  const { mutate: resendOtpMutate } = useResendOtp({
    onSuccess: () => {
      toast.success("OTP resent successfully");
    },
    onError: () => {
      toast.error("Failed to resend OTP");
    },
  });

  const handleSubmit = (otp: string) => {
    email && otpMutate({ email, otp });
  };

  const handleResendOtp = () => {
    if (email) {
      resendOtpMutate({ email });
    }
  };

  return (
    <AuthLayout>
      <LayoutContainer className="pb-[50px] mt-[14rem] ">
        <div className="w-[95%] md:w-[80%] mx-auto">
          <div className="w-fit mx-auto text-center">
            <h2 className="text-2xl lg:text-3xl font-bold supreme">
              Email Verification
            </h2>
            <p className="mt-2 mb-5 text-gray-600">
              Please input the OTP sent to {email}
            </p>
          </div>
          <OtpForm
            source={email}
            isLoading={verifyOtpLoading}
            handleSubmit={handleSubmit}
            handleResendOtp={handleResendOtp}
          />
        </div>
      </LayoutContainer>
    </AuthLayout>
  );
};

export default EmailVerificationOtp;
