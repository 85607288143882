import React, { useState } from "react";
import { FaRegEyeSlash } from "react-icons/fa";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useUpdatePassword } from "../../../../hooks/react-query/useAuthUser";
import Loader from "../../../../components/ui/Loader";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";

function PasswordSettings() {
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };
  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };
  const togglePasswordVisibility3 = () => {
    setShowPassword3(!showPassword3);
  };

  const { mutate, isLoading } = useUpdatePassword();

  const {
    handleSubmit,
    handleBlur,
    handleChange,
    resetForm,
    errors,
    touched,
    values,
  } = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().required("Current password is required"),
      newPassword: Yup.string().required("New password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword")], "Passwords must match")
        .required("Confirm password is required"),
    }),
    onSubmit: (values) => {
      const payload = {
        old_password: values.currentPassword,
        new_password: values.newPassword,
      };
      mutate(payload, {
        onSuccess: (data) => {
          if (!data.success) {
            toast.error(data.error);
            console.log("you have failed in changing your password");
            return;
          }
          console.log("you have succesfully changed your password");
          resetForm();
          toast.success("Password update successful");
        },
        onError: (err: any) => {
          toast.error(err);
        },
      });
    },
  });

  return (
    <div className="w-full flex flex-col gap-3 pt-4 supreme">
      <h1 className="supreme text-[16px] font-semibold text-gray-700">
        Password Settings
      </h1>
      <div className="bg-[#F1F2F3] mb-20 md:w-[70%] p-4 flex flex-col gap-5 pt-6 rounded-md">
        <form onSubmit={handleSubmit} className="space-y-5">
          <div className="flex flex-col gap-1 supreme">
            <p className="text-[14px] font-medium text-gray-800">
              Current password
            </p>
            <div className="relative w-[90%]">
              <input
                type={showPassword1 ? "text" : "password"}
                name="currentPassword"
                className="text-[14px] w-full rounded-md p-2 pr-10 supreme placeholder-gray-400 placeholder:font-medium text-black bg-white border border-gray-200"
                placeholder="Current password"
                value={values.currentPassword}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FaRegEyeSlash
                onClick={togglePasswordVisibility1}
                className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-400"
              />
              {touched.currentPassword && errors.currentPassword ? (
                <div className="text-red-500 text-sm">
                  {errors.currentPassword}
                </div>
              ) : null}
            </div>
          </div>
          <div className="flex flex-col gap-1 supreme">
            <p className="text-[14px] font-medium text-gray-800">
              New password
            </p>
            <div className="relative w-[90%]">
              <input
                type={showPassword2 ? "text" : "password"}
                name="newPassword"
                className="text-[14px] w-full rounded-md p-2 pr-10 supreme placeholder-gray-400 placeholder:font-medium text-black bg-white border border-gray-200"
                placeholder="New password"
                value={values.newPassword}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FaRegEyeSlash
                onClick={togglePasswordVisibility2}
                className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-400"
              />
              {touched.newPassword && errors.newPassword ? (
                <div className="text-red-500 text-sm">{errors.newPassword}</div>
              ) : null}
            </div>
          </div>
          <div className="flex flex-col gap-1 supreme">
            <p className="text-[14px] font-medium text-gray-800">
              Re-enter new password
            </p>
            <div className="relative w-[90%]">
              <input
                type={showPassword3 ? "text" : "password"}
                name="confirmPassword"
                className="text-[14px] w-full rounded-md p-2 pr-10 supreme placeholder-gray-400 placeholder:font-medium text-black bg-white border border-gray-200"
                placeholder="Re-enter new password"
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FaRegEyeSlash
                onClick={togglePasswordVisibility3}
                className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-400"
              />
              {touched.confirmPassword && errors.confirmPassword ? (
                <div className="text-red-500 text-sm">
                  {errors.confirmPassword}
                </div>
              ) : null}
            </div>
          </div>
          <button
            type="submit"
            className="bg-[#1C7272] w-fit text-[13px] flex py-[8px] text-white items-center gap-1 px-2 rounded-[5px] supreme mt-2"
          >
            {isLoading ? (
              <ClipLoader className="mx-auto" size={20} color="#fff" />
            ) : (
              "Save changes"
            )}
          </button>
        </form>
      </div>
    </div>
  );
}

export default PasswordSettings;
