import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";

export const handleUpload = async (file: any) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("upload_preset", "scriipo_academy");
  const cloudName = process.env.REACT_APP_CLOUDINARY;
  if (!cloudName ) {
    console.error(
      "Cloudinary cloud name is not defined in environment variables"
    );
    return;
  }
  try {
    const response: AxiosResponse<any> = await axios.post(
      `https://api.cloudinary.com/v1_1/${cloudName}/upload`,
      formData
    );
    if (response.status === 200) {
      toast.success("Upload successful");
      return response.data;
    } 
  } catch (error) {
    toast.error("Upload not successful, try again!");
    console.error("Upload error:", error);
  }
};
