import React from "react";
import { h3Style, pStyle } from "../../../waitList/style";
import { GoDotFill } from "react-icons/go";

function SubmitAssignmnt({ data }: any) {
  return (
    <div className="w-full"></div>
    // <section className="pb-[40px] w-full">
    //   <div className="grid gap-3">
    //     <div className="grid gap-3">
    //       <h3 className={`font-bold supreme text-[20px] my-2`}>
    //         1. Research and Analysis
    //       </h3>
    //       <p className={`${pStyle} supreme  text-[12px] mt-3`}>
    //         Designed specifically for newcomers, this course covers the
    //         foundational skills and knowledge you need to begin creating your
    //         own music. You’ll learn the basics of audio principles, how to
    //         navigate digital audio workstations (DAWs), and essential techniques
    //         in recording, mixing, and mastering. This course provides a
    //         structured and comprehensive introduction to music production,
    //         perfect for those with little to no prior experience.
    //         <br />
    //         <br /> By the end of this course, you will have a solid
    //         understanding of the music production process and the skills to
    //         start creating your own music.
    //       </p>
    //     </div>
    //     <div className="flex items-center justify-between">
    //       <button className="border-2 border-tsa-primary  text-tsa-primary p-3 rounded-lg">
    //         Submit Assignment
    //       </button>
    //       <div className="flex items-center justify-start gap-2">
    //         <h5 className={` supreme text-[14px] my-2`}>
    //           32 Students Submitted
    //         </h5>
    //         <GoDotFill className="text-[12px] text-gray-400" />
    //         <p className={`supreme text-[14px] my-2 text-red-400`}>
    //           1 Day Left
    //         </p>
    //         <GoDotFill className="text-[12px] text-gray-400" />
    //       </div>
    //     </div>
    //   </div>
    // </section>
  );
}

export default SubmitAssignmnt;
