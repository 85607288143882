/**
 *
 * @param {string} url
 * @param {string, [GET, POST, PATCH, PUT...]} method
 * @param {payload} payload
 * @param {boolean} token
 * @param {boolean} text
 * @param {boolean} form
 * @returns Response Data;
 */

import { request } from "../hooks/api";
import {
  ForgotPasswordType,
  OnboardingType,
  ResendEmailType,
  SignInType,
  SignUpType,
} from "../hooks/types";
import { preparedParams } from "./_helpers";

class AuthService {
  async signup(payload: SignUpType) {
    try {
      const response = await request(
        `/authentication/register`,
        "POST",
        payload,
        false,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async resendEmail(payload: ResendEmailType) {
    try {
      const response = await request(
        `/authentication/forgot/request`,
        "POST",
        payload,
        false,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async verifyEmail(
    url: string,
    payload: { email?: string; token?: string },
    id: string | undefined
  ) {
    // const requestParams = preparedParams(url, id);
    try {
      const response = await request(
        `/authentication/verify/${id}`,
        "POST",
        payload,
        false,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async verifyOTP(payload: { email?: string; otp?: string }) {
    try {
      const response = await request(
        `/authentication/verify/otp`,
        "POST",
        payload,
        false,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async resendOTP(payload: { email?: string }) {
    try {
      const response = await request(
        `/authentication/register/resend-email `,
        "POST",
        payload,
        false,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async login(payload: SignInType) {
    try {
      const response = await request(
        `/authentication/login`,
        "POST",
        payload,
        false,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async logout() {
    try {
      const response = await request(
        `/authentication/logout`,
        "POST",
        null,
        false,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async forgotPassword(payload: ForgotPasswordType) {
    try {
      const response = await request(
        `/authentication/forgot/request`,
        "POST",
        payload,
        false,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getProfile() {
    try {
      const response = await request(`/user`, "GET", null, true, false, false);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async updateProfile(payload: any) {
    try {
      const response = await request(
        `/user`,
        "PUT",
        payload,
        false,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async updatePassword(payload: any) {
    try {
      const response = await request(
        `/authentication/forgot/new/password`,
        "PUT",
        payload,
        false,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async resetPassword(payload: any) {
    try {
      const response = await request(
        `/authentication/forgot/reset`,
        "POST",
        payload,
        false,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async onBoarding(payload: OnboardingType) {
    try {
      const response = await request(
        `/authentication/onboard`,
        "POST",
        payload,
        true,
        false,
        false
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default AuthService;
