import { FaCheck } from "react-icons/fa";
import spiralArrow from "../../../assets/images/production/spiralArrow.svg";
import producer from "../../../assets/images/production/producerWorking.png";
import { Link as ScrollLink } from "react-scroll";

const premiumFeatures = [
  {
    id: 1,
    text: "Access to premium studio space, fully equipped with the latest gear.",
  },
  {
    id: 2,
    text: "Collaboration with renowned producers to craft your unique sound",
  },
  {
    id: 3,
    text: "Professional mixing and mastering (optional add-ons for extra fee)",
  },
];

const IndependentArtistes = () => {
  return (
    <div className="bg-gradient-to-r from-yellow-50 to-green-100 px-8 pt-8 md:px-16 md:pt-16">
      <h1 className="text-4xl md:text-4xl font-bold text-center mb-8 mt-24 font-[technorSemibold]">
        For the truly independent artistes.
      </h1>

      <div className="relative w-fit mx-auto">
        <p className="text-center mb-6 text-lg text-[#555C74] supreme">
          With <span className=" text-black  supreme">NGN 1,000,000</span> get
          access to top notch Production with top producers
        </p>
        <img
          src={spiralArrow}
          alt="arrow"
          className="absolute -top-5 -right-20 hidden lg:block "
        />
      </div>

      <ul className="space-y-4 mb-8 mx-auto w-fit">
        {premiumFeatures.map((feature) => (
          <li key={feature.id} className="flex items-start">
            <FaCheck className="w-4 h-4 text-[#1C7272] mr-2 mt-1 flex-shrink-0" />
            <span>{feature.text}</span>
          </li>
        ))}
      </ul>

      <div className="text-center mb-8">
        <ScrollLink
          to="apply-section"
          smooth={true}
          offset={-100}
          duration={500}
          className="bg-teal-600 text-white cursor-pointer px-6 py-3 rounded-md hover:bg-teal-700 transition duration-300"
        >
          Apply now
        </ScrollLink>
      </div>

      <div className="rounded-lg overflow-hidden shadow-lg pt-5">
        <img
          src={producer}
          alt="Recording studio setup"
          className="w-full h-auto object-cover"
        />
      </div>
    </div>
  );
};

export default IndependentArtistes;
