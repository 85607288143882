import React, { ReactNode, useState } from "react";
import Navbar from "./Navbar";
import Footer from "../../../pages/landingPage/landing/Footer";
import SectionSeven from "../../../pages/waitList/SectionSeven";
import Sidebar from "./Sidebar";

interface Props {
  children: ReactNode;
  hideSection?: boolean;
}

export default function LandingPageLayout({ children, hideSection }: Props) {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen((prev) => !prev);
  };

  return (
    <>
      <Sidebar isSidebarOpen={isSidebarOpen} setSidebarOpen={setSidebarOpen} />
      <Navbar
        isSidebarOpen={isSidebarOpen}
        setSidebarOpen={setSidebarOpen}
        toggleSidebar={toggleSidebar}
      />
      <div className="lg:mt-[100px] mt-[80px]">{children}</div>
      {!hideSection && (
        <SectionSeven text="Sign up now" to="/authentication/signup" />
      )}
      <Footer />
    </>
  );
}
