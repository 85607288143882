import React, { useState } from "react";
import { AuthLayout } from "../../../../components/layouts";
import {
  CustomButton,
  CustomModal,
  CustomTextbox,
} from "../../../../components/ui";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import PasswordFieldNoValidation from "../components/PasswordFieldNoValidation";
import { SIGN_UP } from "./constant";
import { signup_style } from "./style";
import { Link, useNavigate } from "react-router-dom";
import { LayoutContainer } from "../components";
import {
  useRegisterUser,
  useResendEmail,
} from "../../../../hooks/react-query/useAuthUser";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import { setUser } from "../../../../redux/slice/userSlice";

const countriesArr = require("../../../../utils/countries.json");

type Props = {};
interface FormProps {
  email: string;
  password: string;
  country: string;
}

const initialValues = {
  email: "",
  password: "",
  country: "",
};

const grid = "grid lg:gap-y-1";

const SignupSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .required("Please enter a password")
    .min(8, "Password must have at least 8 characters")
    .matches(/[A-Z]/, "Password must have at least one uppercase letter")
    .matches(/\d/, "Password must have at least one number"),
  country: Yup.string().required("Required"),
});

export default function Signup({}: Props) {
  const navigate = useNavigate();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userData, setUserData] = useState<FormProps>({
    email: "",
    password: "",
    country: "",
  });
  const dispatch = useDispatch();
  const {
    mutate: registerUserMutate,
    isLoading,
  } = useRegisterUser();

  const { mutate: resendEmailMutate } = useResendEmail();

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onSubmit = (values: FormProps) => {
    const payload = values;
    console.log("signUp data", payload);

    registerUserMutate(payload, {
      onSuccess: (data) => {
        if (!data.success) {
          toast.error(data.error);
          return;
        }
        toast.success(data.success);
        dispatch(setUser(data.user));
        setUserData(values);
        navigate(`/authentication/verify-email?email=${values.email}`);
        // setIsModalOpen(true);
      },
      onError: (error: any) => {
        console.log("sign up error", error);
        toast.error(error.error || error || "Sign up failed!, try again");
        return;
      },
    });
  };

  const resendEmail = (email: string) => {
    const payload = { email };
    resendEmailMutate(payload, {
      onSuccess: (data) => {
        if (!data.success) {
          toast.error(data.error);
          return;
        }
        toast.success(data.success);
        closeModal();
      },
    });
  };

  return (
    <>
      <AuthLayout>
        <LayoutContainer className="pb-[70px]">
          <h1 className={signup_style.h1Style}>{SIGN_UP.HEADING_TEXT}</h1>
          <p className={signup_style.pStyle}>{SIGN_UP.PARAGRAPH_TEXT}</p>

          <Formik
            onSubmit={onSubmit}
            validationSchema={SignupSchema}
            initialValues={initialValues}
          >
            {({ errors, touched, setFieldValue }: any) => (
              <Form className="w-[75%] mx-auto grid lg:gap-y-4 gap-y-2 mt-7">
                {/* email  */}
                <div className={grid}>
                  <label htmlFor="email" className={signup_style.label}>
                    {SIGN_UP.EMAIL_LABEL}
                  </label>
                  <CustomTextbox
                    id="email"
                    name="email"
                    useFormikForm={true}
                    placeholder={SIGN_UP.EMAIL_PLACEHOLDER}
                  />
                  {errors.email && touched.email ? (
                    <small className={signup_style.errorStyle}>
                      {errors.email}
                    </small>
                  ) : null}
                </div>
                {/* password  */}
                <div className={grid}>
                  <CustomTextbox
                    id="password"
                    name="password"
                    type="password"
                    useFormikForm={true}
                    placeholder={SIGN_UP.PASSWORD_PLACEHOLDER}
                    component={PasswordFieldNoValidation}
                  />
                  {errors.email && touched.email ? (
                    <small className={signup_style.errorStyle}>
                      {errors.email}
                    </small>
                  ) : null}
                </div>

                {/* country  */}
                <div className={grid}>
                  <label htmlFor="lastName" className={signup_style.label}>
                    {SIGN_UP.COUNTRY_LABEL}
                  </label>
                  <Field
                    as="select"
                    id="country"
                    name="country"
                    className={`${signup_style.inputStyle} hover:cursor-pointer outline-tsa-primary`}
                    value={selectedCountry}
                    onChange={(e: any) => {
                      setFieldValue("country", e.target.value);
                      setSelectedCountry(e.target.value);
                    }}
                  >
                    <option value="" disabled selected hidden>
                      Select country
                    </option>
                    {countriesArr.map((country: any) => (
                      <option
                        className="capitalize"
                        key={country.iso3}
                        value={country.name}
                      >
                        {country.name}
                      </option>
                    ))}
                  </Field>
                  {errors.country && touched.country ? (
                    <small className={signup_style.errorStyle}>
                      {errors.country}
                    </small>
                  ) : null}
                </div>

                <CustomButton
                  isLink={false}
                  type="submit"
                  value={
                    isLoading ? (
                      <ClipLoader className="mx-auto" size={20} color="#fff" />
                    ) : (
                      SIGN_UP.BUTTON_TEXT
                    )
                  }
                  className="text-white mt-3 lg:mt-0"
                />
              </Form>
            )}
          </Formik>
          <p className="mt-2 supreme text-center font-[500] lg:text-[16px] text-[13px] leading-[24px] text-[#555C74]">
            Already have an account?{" "}
            <Link
              to="/authentication/signin"
              className="supreme text-tsa-primary"
            >
              sign in
            </Link>
          </p>

          <div className="absolute bottom-5 left-[10%] text-[#010516] right-[10%] font-[700] text-[12px] leading-[22px] supreme text-center">
            By creating an account, you agree to SCRiiPO&nbsp;
            <Link to="/terms-of-use" className="supreme text-tsa-primary">
              Terms of Use
            </Link>{" "}
            and &nbsp;
            <Link to="/privacy-policy" className="supreme text-tsa-primary">
              Privacy policy
            </Link>
          </div>
        </LayoutContainer>
      </AuthLayout>

      {isModalOpen && (
        <CustomModal
          modalStyle="lg:w-[35%] w-[90%] mt-[70px] bg-white mx-auto mt-[12rem] rounded-[12px] py-[30px]"
          onClose={closeModal}
        >
          <div>
            <h1 className={signup_style.h1Style}>Email Verification</h1>
            <p
              className={`${signup_style.pStyle} lg:w-[75%] w-[80%] mx-auto py-1`}
            >
              An OTP has been sent to{" "}
              <span className="supreme">{userData.email}</span>
            </p>
            <p className={`supreme text-[14px] text-center font-[400] mt-5`}>
              Didn't recieve an OTP?{" "}
              <span
                onClick={() => resendEmail(userData.email)}
                className="supreme font-[500] text-tsa-primary hover:cursor-pointer"
              >
                Retry
              </span>
            </p>
          </div>
        </CustomModal>
      )}
    </>
  );
}
