// redux/store.ts
import { combineReducers } from "@reduxjs/toolkit";
import userSlice from "./slice/userSlice";
import coursesSlice from "./slice/coursesSlice";
import modeSlice from "./slice/modeSlice";


const rootReducer: any = combineReducers({
  user: userSlice,
  courses: coursesSlice,
  mode: modeSlice,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
