import React from "react";
import { Link } from "react-router-dom";
import logoImg from "../../../assets/images/logo-mini.svg";
import { IoCloseSharp } from "react-icons/io5";
import { MOBILE_NAV_LINKS } from "../../../utils/constants";
import { CiLogin } from "react-icons/ci";

type Props = {
  isSidebarOpen: boolean;
  setSidebarOpen: (value: boolean) => void;
};

const Sidebar = ({ isSidebarOpen, setSidebarOpen }: Props) => {
  return (
    <aside
      className={`  w-[220px] md:w-[400px] z-[2100] bg-white shadow-md h-full fixed top-0 left-0  transform transition-transform duration-300 ${
        isSidebarOpen ? "translate-x-0" : "-translate-x-full"
      } lg:hidden`}
    >
      <div className="flex justify-between py-8 px-4">
        <Link to="/">
          <img src={logoImg} alt="logo" className="" />
        </Link>
        <button onClick={() => setSidebarOpen(false)} className="lg:hidden">
          <IoCloseSharp size={30} />
        </button>
      </div>

      <ul className=" flex flex-col items-center mt-20">
        {MOBILE_NAV_LINKS.map((item, i) => {
          return (
            <>
              <Link
                key={i}
                to={item.to}
                className="text-black py-6 border-b w-full"
                onClick={() => setSidebarOpen(false)}
              >
                <p className="pl-4">{item.link_name}</p>
              </Link>
            </>
          );
        })}
        <Link
          to="/authentication/signin"
          className="flex items-center pt-6 text-black pl-6  w-full"
        >
          <p className="mr-2">Signin</p>
          <div className="text-tsa-primary">
            <CiLogin />
          </div>
        </Link>
      </ul>
    </aside>
  );
};

export default Sidebar;
