export const signup_style = {
  h1Style:
    "supreme text-center font-[700] lg:text-[30px] text-[22px] leading-[38px]",
  pStyle:
    "font-[400] lg:text-[18px] text-[14px] lg:leading-[28px] leading-[20px] text-[#555C74] supreme text-center",
  label:
    "supreme font-[500] lg:text-[16px] text-[13px] leading-[24px] text-[#101928]",
  errorStyle: `text-red-600 text-[13px] supreme `,
  inputStyle:
    "supreme border border-[#DCDEE2] rounded-[6px] py-[12px] px-[16px] w-full ",
};