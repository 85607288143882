const requestHeader = {
  Accept: "application/json",
  "Cache-Control": "no-cache",
  "Content-Type": "application/json",
};

const getLocation = () => {
  return window.location;
};

const logout = () => {
  localStorage.clear();
  window.location.href = "/authentication/signin";
};

/**
 *
 * @param {string} url
 * @param {string, [GET, POST, PATCH, PUT...]} method
 * @param {payload} payload
 * @param {boolean} token
 * @param {boolean} text
 * @param {boolean} form
 * @param {string | null} xHash
 * @returns Response Data;
 */


const API_USER_URL = process.env.REACT_APP_BASE_URL;



async function request(url, method, payload, token = true, text, form) {
  const location = getLocation();
  const isAuthPage = location.pathname.includes("/authentication/");
  const bearerToken = localStorage.getItem("scriipo-token");

  if (bearerToken) {
    requestHeader["Authorization"] = `Bearer ${bearerToken}`;
  }

  requestHeader["Content-Type"] =
    form === true ? "multipart/form-data" : "application/json";

  const options = {
    method,
    headers: Object.assign(requestHeader),
  };

  if (method !== "GET" && payload != null) {
    options.body = form === true ? payload : JSON.stringify(payload);
  }

  return fetch(API_USER_URL + url, options)
    .then((res) => {
      if (!isAuthPage) {
        if (res.status === 403 || res.status === 401) {
          logout();
          throw new Error("Access forbidden. Redirecting to login page.");
        } else if (text === true) {
          return res.text();
        } else {
          return res.json();
        }
      } else {
        if (text === true) {
          return res.text();
        } else {
          return res.json();
        }
      }
    })
    .catch((err) => {
      console.error(`Request Error ${url}: `, err);
      throw new Error(err);
    });
}


export { request };
