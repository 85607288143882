import { Navigate, RouteObject } from "react-router-dom";
import {
  ContributorSignUp,
  CountrySelectPage,
  Signup,
  Signin,
  ForgotPassword,
  ResetPassword,
  Onboarding,
} from "../pages/webapp/authentication";

import NotificationPage from "../components/ui/NotificationPage";
import WaitList from "../pages/waitList";
import WaitListForm from "../pages/waitList/WaitlistForm";
import LandingPage from "../pages/landingPage";
import Pricing from "../pages/landingPage/pricing";
import Courses from "../pages/landingPage/courses/Index";
import Instructors from "../pages/landingPage/instructors";
import InstructorDetail from "../pages/landingPage/instructor-detail";
import Help from "../pages/landingPage/help";
import Search from "../pages/landingPage/help/search";
import PrivacyPolicy from "../pages/landingPage/privacy-policy";
import TermsOfUse from "../pages/landingPage/terms";
import About from "../pages/landingPage/about";
import CourseDetail from "../pages/landingPage/course-detail";
import Home from "../pages/webapp/app/home";
import MyLearning from "../pages/webapp/app/my-learning";
import Account from "../pages/webapp/app/account";
import MyCourses from "../pages/webapp/app/courses";
import SingleCourse from "../pages/webapp/app/courses/SingleCourse";
import CourseCertificate from "../pages/webapp/app/courses/CourseCertificate";
import CourseEnrollment from "../pages/webapp/app/courses/CourseEnrollment";
import ProtectedRoute from "./ProtectedRoute";
import { SignupSuccess } from "../pages/webapp/authentication/components";
import Plans from "../pages/webapp/app/packages";
import ProductionLanding from "../pages/production/landing";
import CaseStudy from "../pages/production/caseStudy";
import Producer from "../pages/production/Producer";
import EmailVerificationOtp from "../pages/webapp/authentication/student/EmailVerificationOtp";

const routes: RouteObject[] = [
  { path: "/", element: <Navigate to="/home" replace={true} /> },
  { path: "/home", element: <LandingPage /> },
  { path: "/pricing", element: <Pricing /> },

  /////////////////    Utility pages   //////////////////
  { path: "/success", element: <NotificationPage /> },
  { path: "/all-courses", element: <Courses /> },
  { path: "/all-courses/:id", element: <CourseDetail /> },
  { path: "/all-instructors", element: <Instructors /> },
  { path: "/all-instructors/instructor/:id", element: <InstructorDetail /> },
  { path: "/waitlist", element: <WaitList /> },
  { path: "/waitlist/join", element: <WaitListForm /> },
  { path: "/help", element: <Help /> },
  { path: "/help/search", element: <Search /> },
  { path: "/privacy-policy", element: <PrivacyPolicy /> },
  { path: "/terms-of-use", element: <TermsOfUse /> },
  { path: "/about-us", element: <About /> },
  // { path: "/email-verification/:id/:token", element: <EmailVerfication /> },
  { path: "/authentication/verify-email", element: <EmailVerificationOtp /> },
  { path: "/authentication/signup", element: <Signup /> },
  { path: "/authentication/signin", element: <Signin /> },
  { path: "/authentication/success", element: <SignupSuccess /> },
  { path: "/authentication/forgt-password", element: <ForgotPassword /> },
  { path: "/authentication/reset-password", element: <ResetPassword /> },
  // { path: "/authentication/passwordReset", element: <CheckEmailForOTP /> },
  // { path: "/authentication/otp-form", element: <OtpForm /> },
  { path: "/onboarding", element: <Onboarding /> },

  //for production
  { path: "/production/home", element: <ProductionLanding /> },
  { path: "/production/case-study/:id", element: <CaseStudy /> },
  { path: "/producers/:id", element: <Producer /> },

  { path: "*", element: <Signin /> },

  {
    path: "/app",
    element: <ProtectedRoute />,
    children: [
      { path: "home", element: <Home /> },
      { path: "account", element: <Account /> },
      { path: "my-learning", element: <MyLearning /> },
      { path: "courses", element: <MyCourses /> },
      { path: "courses/:cid", element: <SingleCourse /> },
      { path: "courses/certificate", element: <CourseCertificate /> },
      { path: "enrollment/:id", element: <CourseEnrollment /> },
      { path: "plans", element: <Plans /> },
    ],
  },
];

export { routes };
