import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

interface TabsProps {
  children: React.ReactElement<TabProps>[] | React.ReactElement<TabProps>;
}

interface TabProps {
  title: string;
  icon?: React.ReactNode;
  children: React.ReactNode;
  notEnabled?: boolean;
}

const Tabs: React.FC<TabsProps> = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [searchParams] = useSearchParams()
  const tab = searchParams.get("tab");

  useEffect(() => {
    if (tab) {
      setActiveTab(2);
    }
  }, [tab]);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  return (
    <div className="tabs">
      <div className="tab-list flex items-center gap-3 mb-5 relative">
        {React.Children.map(children, (child, index) => (
          <div
            key={index}
            className={`flex items-center gap-1 cursor-pointer text-sm md:text-[14px] w-full md:w-[180px] pb-3 ${
              index === activeTab ? "text-tsa-primary" : ""
            }`}
            onClick={() => handleTabClick(index)}
          >
            {child.props.icon && (
              <div className="tab-icon">{child.props.icon}</div>
            )}
            <div
              className={`tab-title text-14px supreme font-[500] ${
                index === activeTab ? "text-tsa-primary" : "text-gray-600"
              }`}
            >
              {child.props.title}
            </div>
            {index === activeTab && (
              <div className="active-tab-line bg-tsa-primary w-[70px] h-1 absolute bottom-0"></div>
            )}
          </div>
        ))}
        <div className="tabs-bottom-line bg-gray-500 h-[0.5px] absolute bottom-0 left-0 right-0"></div>
      </div>
      <div className="tab-content">
        {React.Children.map(children, (child, index) =>
          index === activeTab && !child.props.notEnabled ? child.props.children : ""
        )}
      </div>
    </div>
  );
};

const Tab: React.FC<TabProps> = ({ children,  }) => {
  return <>{children}</>;
};

export { Tabs, Tab };
