import React, { useEffect, useState } from "react";
import logo from "../../../assets/images/logo-mini.svg";
import productionLogo from "../../../assets/images/production/productionLogo.svg";
import hamburgerIcon from "../../../assets/icons/hamburger.svg";
import { Link, useNavigate } from "react-router-dom";
import { NAV_LINKS } from "../../../utils/constants";
import dropdownIcon from "../../../assets/icons/dropdown.svg";
import dropdownUpIcon from "../../../assets/icons/dropdown-up.svg";
import { useGetAllCourses } from "../../../hooks/react-query/useCourse";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/rootReducer";
import { setMode } from "../../../redux/slice/modeSlice";
import { Link as ScrollLink } from "react-scroll";

type Props = {
  setSidebarOpen: (value: boolean) => void;
  isSidebarOpen: boolean;
  toggleSidebar: any;
};

export default function Navbar({
  setSidebarOpen,
  isSidebarOpen,
  toggleSidebar,
}: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentMode = useSelector((state: RootState) => state.mode.mode);

  const education = currentMode === "For education";

  const [isClicked, setIsClicked] = useState(0);
  const { data: coursesData } = useGetAllCourses();

  useEffect(() => {
    const storedMode = localStorage.getItem("mode");

    if (!storedMode) {
      dispatch(setMode("For education"));
      localStorage.setItem("mode", "For education");
    } else {
      dispatch(setMode(storedMode));
    }
  }, [dispatch]);

  return (
    <nav className="w-full  lg:h-[70px] h-[50px] flex items-center bg-transparent relative lg:top-2 top-3 z-[999]">
      <div className="lg:w-[90%] left-1/2 -translate-x-1/2 top-4 w-[97%] mx-auto backdrop-blur-sm bg-white bg-opacity-20 rounded-[20px]  flex items-center justify-between px-[20px] py-[15px] fixed ">
        <div className="flex items-center gap-x-4">
          {education ? (
            <Link to="/">
              <img
                src={logo}
                alt="logo"
                className="lg:w-fit w-[70px] hover:cursor-pointer"
              />
            </Link>
          ) : (
            <Link to="/production/home">
              <img
                src={productionLogo}
                alt="logo"
                className="lg:w-fit w-[70px] hover:cursor-pointer"
              />
            </Link>
          )}

          <div className="mx-auto lg:px-10 flex items-center  text-sm  space-x-3 cursor-pointer">
            <p
              onClick={() => {
                dispatch(setMode("For education"));
                localStorage.setItem("mode", "For education");
                navigate("/home");
              }}
              className={`transition-color duration-500 ease-in-out w-[80px] lg:w-[120px] text-center  ${
                !education
                  ? "text-white"
                  : "bg-[#E8FFE8] py-2 rounded-2xl text-black"
              }`}
            >
              Education
            </p>
            <p
              onClick={() => {
                dispatch(setMode("For production"));
                localStorage.setItem("mode", "For production");
                navigate("/production/home");
              }}
              className={` duration-500 ease-in-out w-[80px] lg:w-[120px] text-center ${
                !education
                  ? "text-black bg-[#E8FFE8] py-2 rounded-2xl"
                  : "text-black"
              }`}
            >
              Production
            </p>
          </div>
        </div>
        {education && (
          <ul className="lg:flex hidden items-center gap-x-10">
            {NAV_LINKS.map((item) => (
              <li key={item.id}>
                {!item.isDropdown ? (
                  <Link to={item.to} className="font-[supreme]">
                    {item.link_name}
                  </Link>
                ) : (
                  <div className="relative">
                    <button
                      onClick={() => {
                        isClicked === item.id
                          ? setIsClicked(0)
                          : setIsClicked(item.id);
                      }}
                      className="flex items-center gap-x-2 font-[supreme]"
                    >
                      <>{item.link_name}</>
                      <img
                        src={
                          isClicked === item.id ? dropdownIcon : dropdownUpIcon
                        }
                        alt="dropdown"
                      />
                    </button>
                    {isClicked === item.id ? (
                      <div className="absolute rounded-md w-[400px] top-14 shadow-lg">
                        {item.id === 1 ? (
                          <ul className="bg-white p-2">
                            {coursesData?.data
                              .slice(0, 5)
                              .map((course: any) => {
                                return (
                                  <Link
                                    to={`/all-courses/${course?._id}`}
                                    className=" supreme block text-sm text-justify hover:bg-gray-100  cursor-pointer"
                                  >
                                    <p className="py-3">{course?.title}</p>
                                  </Link>
                                );
                              })}
                            <Link
                              className="supreme block text-sm  hover:bg-gray-100"
                              to="/all-courses"
                            >
                              <p className="py-3">View all courses</p>
                            </Link>
                          </ul>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </li>
            ))}
          </ul>
        )}
        {education ? (
          <div className="flex items-center gap-x-3">
            <Link
              className="text-tsa-primary p-[12px] font-[500] lg:block hidden rounded-[8px] font-[supreme] text-[14px] tracking-wide"
              to="/authentication/signin"
            >
              Log In
            </Link>
            <Link
              className="bg-tsa-primary p-[12px] lg:block hidden rounded-[8px] font-[supreme] text-white text-[14px] tracking-wide"
              to="/authentication/signup"
            >
              Sign up now
            </Link>
            <img
              onClick={toggleSidebar}
              src={hamburgerIcon}
              alt="collapse icon"
              className="lg:hidden block"
            />
          </div>
        ) : (
          <ScrollLink
            className="text-white bg-[#1C7272] p-[12px] font-[500] cursor-pointer lg:block hidden rounded-[8px] font-[supreme] text-[14px] tracking-wide"
            to="apply-section"
            smooth={true}
            offset={-100}
            duration={500}
          >
            Apply Now
          </ScrollLink>
        )}
      </div>
    </nav>
  );
}
