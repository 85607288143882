import React, { useEffect, useState } from "react";
import CourseCard from "../home/CourseCard";
import {
  FormControl,
  InputLabel,
  MenuItem,
  PaginationItem,
  Select,
  Stack,
  SelectChangeEvent,
} from "@mui/material";
import { Pagination } from "../../../../components/ui";
import { useGetAllCourses } from "../../../../hooks/react-query/useCourse";
import Loader from "../../../../components/ui/Loader";
import { useDispatch } from "react-redux";
import coursesSlice, { setCourses } from "../../../../redux/slice/coursesSlice";
import { useSearchParams } from "react-router-dom";

type Props = {};

export default function MyCourses({}: Props) {
  const [searchParams] = useSearchParams();
  const searchTerm = searchParams.get("search");

  const [age, setAge] = React.useState("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { isLoading, data: coursesData } = useGetAllCourses();

  const dispatch = useDispatch();
  const courses = coursesData?.data;

   const filteredCoursesArray = !searchTerm
     ? courses
     : courses.filter(
         (course:any) =>
           course.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
           course.main_contributor.name
             .toLowerCase()
             .includes(searchTerm.toLowerCase())
       );


  useEffect(() => {
    dispatch(setCourses(courses));
  }, [courses, dispatch]);

  if (isLoading) {
    return <Loader />;
  }

  //I still don't know what this does
  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };

  //pagination logic

  const itemsPerPage = 10;

  const totalItems = filteredCoursesArray?.length;

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredCoursesArray?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  return (
    <div className="my-[70px] mx-[20px]">
      <div className="my-10 md:flex items-center justify-between">
        <div className="flex items-center justify-start gap-2 md:w-[20%] mb-10 md:mb-0">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" style={{ fontSize: 12 }}>
              Category: All
            </InputLabel>
            <Select
              style={{ height: 40, fontSize: 12 }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={age}
              label="All"
              onChange={handleChange}
            ></Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" style={{ fontSize: 12 }}>
              Skills: All
            </InputLabel>
            <Select
              style={{ height: 40, fontSize: 12 }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={age}
              label="All"
              onChange={handleChange}
            ></Select>
          </FormControl>
        </div>

        <div className="hidden md:block">
          <Pagination
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={totalItems}
            paginate={paginate}
          />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-6 mb-7">
        {currentItems.map((item: any, index: number) => (
          <CourseCard key={index} course={item} />
        ))}
      </div>

      <div className="md:hidden">
        <Pagination
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          totalItems={totalItems}
          paginate={paginate}
        />
      </div>
    </div>
  );
}
