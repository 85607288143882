import checkCircle from "../../assets/icons/check-circle.svg";
import verifiedIcon from "../../assets/icons/verified.svg";
import check from "../../assets/icons/packageListCheck.svg";
import { Navigate, useNavigate } from "react-router-dom";

const cardContainer = `md:w-[365px] mx-auto bg-white rounded-[20px] border lg:py-[40px] py-[20px] lg:px-[30px] px-[18px] hover:border-tsa-primary`;

interface Props {
  packageInfo?: any;
  recommended?: string | undefined;
  activeTab?: number;
  packageId?: string;
}

export default function PackageCard({
  packageInfo,
  recommended,
  activeTab,
  packageId,
}: Props) {
  const navigate = useNavigate();

  const priceInNaira =
    activeTab === 0
      ? packageInfo?.price.monthly.ngn
      : activeTab === 1
      ? packageInfo?.price.quarterly.ngn
      : packageInfo?.price.biAnnually.ngn;

  // const priceInDollar =
  //   activeTab === 0
  //     ? packageInfo?.price.monthly.usd
  //     : activeTab === 1
  //     ? packageInfo?.price.quarterly.usd
  //     : packageInfo?.price.biAnnually.usd;

  return (
    <div className={`${cardContainer} shadow`}>
      <div className="flex justify-between items-center">
        <h1 className="font-[700] text-[18px] leading-[28px]">
          {packageInfo?.title}
        </h1>
        <div
          className="flex bg-[#39B5881A] py-1 px-2 rounded-full items-center gap-x-1 text-[#39B588] text-[12px] font-[400]"
          style={{ display: packageInfo?.recommended ? "" : "none" }}
        >
          <img src={verifiedIcon} alt="verified" />
          <span className="font-[supreme]">Recommended</span>
        </div>
      </div>
      <div className="mt-5">
        <h1 className="font-[supreme] font-[700] lg:text-[35px] text-[30px] leading-[40px] text-[#010516]">
          NGN{Math.floor(priceInNaira / 100).toLocaleString()}
        </h1>
        <p className="font-[supreme] font-[400] lg:text-[16px] text-[14px] leading-[24px] text-[#747478]">
          Monthly
        </p>
      </div>
      <div className="w-full mt-5">
        <button
          onClick={() => navigate("/authentication/signup")}
          className="w-full font-[supreme] text-center py-[10px] rounded-[8px] border-[1.4px] border-tsa-primary text-tsa-primary hover:bg-tsa-primary hover:text-white"
        >
          Get Started
        </button>
      </div>
      <ul className="mt-8 grid lg:gap-y-2 gap-y-1">
        {packageInfo.features.map((item: string, i: number) => (
          <li key={i} className="flex gap-x-4 items-start">
            <img src={check} alt="check" />
            <p className="font-[supreme] lg:text-[16px] text-[14px] font-[400] leading-[22px]">
              {item}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
}
