import React, { useState } from "react";
import { RiVisaLine } from "react-icons/ri";
import UpdatePayment from "./UpdatePayment";
import SubscripionPlan from "./SubscriptionPlan";
function Subscription() {
  const [changeSubscriptionPlan, setChangeSubscriptionPlan] = useState(true);
  const changePlan = () => {
    setChangeSubscriptionPlan(() => {
      return !changeSubscriptionPlan;
    });
  };
  return (
    <div>
      {changeSubscriptionPlan === true ? (
        <SubscripionPlan changePlans={changePlan} />
      ) : (
        <UpdatePayment changePlans={changePlan} />
      )}
    </div>
  );
}

export default Subscription;
