import React from "react";
import ratingIcon from "../../assets/icons/starIcon.svg";
import { Link, useNavigate } from "react-router-dom";

interface CourseCardProps {
  course?: any;
  showModal?: () => void;
  showProgress?: boolean;
}

const CourseCard: React.FC<CourseCardProps> = ({
  course,
  showModal,
  showProgress,
}) => {
  return (
    <div className="relative w-full  md:w-[350px] mx-auto h-[380px] rounded-[12px] font-[supreme] bg-[#F1F2F380]  overflow-hidden">
      <Link to={`/app/enrollment/${course._id}`}>
        <div className="w-full h-[50%] overflow-hidden">
          <img
            src={course.image}
            alt="course-image"
            className="object-contain w-full"
          />
        </div>
        <div className="p-[15px]">
          <h3 className="text-[15px] font-[500] leading-[21px] font-[supreme] leading-21px] text-[#010516] mb-1.5">
            {course?.title}
          </h3>
          <p className="text-[14px] font-[400] leading-[19px] font-[supreme] text-[#010516] mb-1.5">
            By {course?.main_contributor?.name}
          </p>
          {showProgress && (
            <div className="my-2">
              <div className="h-[5px] bg-[#DCDEE2] rounded-full">
                <div className="w-[40%] h-full bg-tsa-primary rounded-full"></div>
              </div>
              <div className="flex items-center justify-between">
                <span className="text-[10px] flex justify-end text-gray-700 supreme ">
                  2 of 12 lessons
                </span>
                <span className="text-[10px] flex justify-end text-gray-700 supreme">
                  70%
                </span>
              </div>
            </div>
          )}
          <div>
            <p className="font-[supreme] text-[12px] font-[400] leading-[19px] text-[#555C74] mb-5">
              {course?.tagline}
            </p>
            <div className="flex ">
              <div className="flex items-center gap-x-4  absolute bottom-3">
                <p className="text-[14px] font-[400] leading-[22px] font-[supreme] text-[#010516]">
                  {course?.lessons?.length || course?.lessons} lessons
                </p>
                {/* <p>{course.duration}</p> */}
                <p className="text-[14px] font-[400] leading-[22px] font-[supreme] text-[#010516]">
                  2hrs 5mins
                </p>
              </div>

              <div className=" flex items-center space-x-1">
                {course?.package.map((pkg: any, i:number) => (
                  <p key={i} className="supreme text-xs text-white bg-tsa-primary rounded-sm px-1">
                    {pkg.title}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default CourseCard;
