function Loader() {
  return (
      <div className="loader-container" style={{backgroundColor: "#f5fcfa"}}>
          <span className="loader"></span>
          <br/>
          <p style={{fontSize: 10}}>Please wait...</p>
      </div>
  );
}

export default Loader;
