import { useEffect, useState } from "react";
import productionBgImage from "../../../assets/images/production/productionBgImage.png";
import { Link as ScrollLink } from "react-scroll";
import { CustomModal } from "../../../components/ui";
import successful from "../../../assets/icons/successful.svg";
import { useNavigate, useSearchParams } from "react-router-dom";

const ProductionHero = () => {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const hasApplication = searchParams.has("application");

  useEffect(() => {
    if (hasApplication) {
      setIsSuccessModalOpen(true);
    }
  }, [hasApplication]);

  const handleClick = () => {
    setIsSuccessModalOpen(false);
    navigate("/production/home", { replace: true });
  };

  return (
    <section
      className="bg-cover bg-no-repeat bg-center px-4 md:px-8 lg:px-16 w-full h-[650px] lg:h-[750px] absolute top-0 pt-40"
      style={{
        backgroundImage: `url(${productionBgImage})`,
      }}
    >
      <div className="text-center flex flex-col space-y-5 items-center">
        <h2 className="text-white text-3xl lg:text-5xl font-[technorSemibold]">
          Level up your music with{" "}
        </h2>
        <h2 className="text-[#C3C212] text-3xl lg:text-5xl font-[technorSemibold]">
          top tier producers.
        </h2>
        <p className="text-white lg:w-[40%] lg:mx-auto">
          Work with our collective of seasoned producers, gain full ownership of
          your music, and take your sound to the next level
        </p>
        <ScrollLink
          className="bg-white text-[#1C7272] p-[12px] font-[500] rounded-[8px] font-[supreme] cursor-pointer text-[14px] tracking-wide "
          to="apply-section"
          smooth={true}
          offset={-100}
          duration={500}
        >
          Apply Now
        </ScrollLink>
      </div>

      <div className="w-[80%] mx-auto absolute bottom-40 lg:bottom-64 left-1/2 -translate-x-1/2">
        <div className="w-[90%] h-[200px] lg:w-[70%] lg:h-[340px] mx-auto absolute top-0 bg-[#FFFEE0] rounded-2xl left-1/2 -translate-x-1/2"></div>
        <div className="w-[95%] h-[200px] lg:w-[75%] lg:h-[340px] mx-auto absolute top-4 bg-[#68A1A1] rounded-2xl left-1/2 -translate-x-1/2"></div>

        <iframe
          className="w-full lg:w-[80%] h-[200px] lg:h-[340px] mx-auto  absolute top-8  rounded-2xl left-1/2 -translate-x-1/2 border-2 border-[#D2F5E7]"
          src="https://www.youtube.com/embed/qkl4IIPp7Bo?si=FT519Sl0rmSd60Ii"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>

        <div></div>
      </div>

      {isSuccessModalOpen && (
        <CustomModal
          onClose={() => handleClick()}
          modalStyle="lg:w-[50%] w-[90%] mt-[180px] bg-white mx-auto rounded-[12px] py-[30px] px-10 pt-20 pb-10"
        >
          <div className="text-center space-y-5 supreme">
            <img src={successful} alt="successful" className="mx-auto w-fit" />
            <h3 className="text-xl font-semibold supreme">
              Application successful
            </h3>
            <p>
              Your application was successful! Check your email for the payment
              receipt. We'll contact you soon!
            </p>
          </div>
        </CustomModal>
      )}
    </section>
  );
};

export default ProductionHero;
