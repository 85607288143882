import React from "react";
import { AuthLayout } from "../../../../components/layouts";
import { CustomButton, CustomTextbox } from "../../../../components/ui";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import PasswordFieldNoValidation from "../components/PasswordFieldNoValidation";
import { RESET_PASSWORD } from "./constant";
import { signup_style } from "./style";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { LayoutContainer } from "../components";
import { useParams } from "react-router-dom";
import { useForgotPassword, useResetPassword } from "../../../../hooks/react-query/useAuthUser";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";

type Props = {};
interface FormProps {
  token: string;
  password: string;
  confirmPassword: string;
}

const grid = "grid lg:gap-y-1";

export default function ResetPassword({}: Props) {
  const { isLoading: ResetPasswordIsLoading, mutate: ResetPasswordMutate } = useResetPassword();
  const { isLoading: ForgotPasswordIsLoading, mutate: forgotPasswordMutate } = useForgotPassword();



  const navigate = useNavigate();
 

  const [searchParams, _] = useSearchParams();
  const token = searchParams.get("token");
  const email = searchParams.get("email") as string;
  console.log({ token, email });

  const initialValues: FormProps = {
    token: token || "",
    password: "",
    confirmPassword: "",
  };

  const PasswordResetSchema = Yup.object().shape({
    token: Yup.string().required("Required"),
    password: Yup.string().required("Please enter a password"),
    confirmPassword: Yup.string()
      .required("Please re-type your password")
      .oneOf([Yup.ref("password")], "Passwords does not match"),
  });

  const onSubmit = (values: FormProps) => {
    const payload = { ...values, email };
    ResetPasswordMutate(payload, {
      onSuccess: (data) => {
        if (!data.success) {
          toast.error(data.error);
          return;
        }
        toast.success(data.success);
        navigate("/authentication/signin");
      },
    });
  };

  const sendEmail = (value: {email: string}) => {
    forgotPasswordMutate(value, {
      onSuccess: (data: any) => {
        if (!data.success) {
          toast.error(data.error);
          return;
        }
        toast.success("Reset password link has been sent!");
        navigate("/authentication/reset-password");
      },
      onError: (error: any) => {
        toast.error(error.error || error);
      },
    });
  };

  return (
    <AuthLayout>
      <LayoutContainer className="pb-[70px]">
        <h1 className={signup_style.h1Style}>{RESET_PASSWORD.HEADING_TEXT}</h1>
        <p className={`${signup_style.pStyle} lg:w-[70%] mx-auto w-[85%]`}>
          {RESET_PASSWORD.PARAGRAPH_TEXT}
        </p>

        <Formik
          onSubmit={onSubmit}
          validationSchema={PasswordResetSchema}
          initialValues={initialValues}
        >
          {({ errors, touched }) => (
            <Form className="w-[75%] mx-auto grid lg:gap-y-4 gap-y-2 mt-7">
              {/* reset code  */}
              <div className={grid}>
                <label htmlFor="token" className={signup_style.label}>
                  {RESET_PASSWORD.RESET_CODE_LABEL}
                </label>
                <CustomTextbox
                  id="token"
                  name="token"
                  useFormikForm={true}
                  placeholder={RESET_PASSWORD.RESET_CODE_PLACEHOLDER}
                />
                {errors.token && touched.token ? (
                  <small className={signup_style.errorStyle}>
                    {errors.token}
                  </small>
                ) : null}
              </div>
              {/* password  */}
              <div className={grid}>
                <CustomTextbox
                  id="password"
                  name="password"
                  type="password"
                  useFormikForm={true}
                  placeholder={RESET_PASSWORD.PASSWORD_PLACEHOLDER}
                  component={PasswordFieldNoValidation}
                />
                {/* {errors.password && touched.password ? (
                  <small className={signup_style.errorStyle}>
                    {errors.password}
                  </small>
                ) : null} */}
              </div>
              {/* confirm password  */}
              <div className={grid}>
                <label htmlFor="confirmPassword" className={signup_style.label}>
                  {RESET_PASSWORD.CONFIRM_PASSWORD_LABEL}
                </label>
                <CustomTextbox
                  id="confirmPassword"
                  name="confirmPassword"
                  useFormikForm={true}
                  placeholder={RESET_PASSWORD.CONFIRM_PASSWORD_PLACEHOLDER}
                />
                {errors.confirmPassword && touched.confirmPassword ? (
                  <small className={signup_style.errorStyle}>
                    {errors.confirmPassword}
                  </small>
                ) : null}
              </div>

              <CustomButton
                isLink={false}
                value={
                  ForgotPasswordIsLoading || ResetPasswordIsLoading ? (
                    <ClipLoader className="mx-auto" size={20} color="#fff" />
                  ) : (
                    RESET_PASSWORD.BUTTON_TEXT
                  )
                }
                className="text-white mt-3 lg:mt-0"
              />
              <button
                onClick={() => sendEmail({ email })}
                className="text-tsa-primary border-b border-b-tsa-primary w-fit"
              >
                Request new code
              </button>
            </Form>
          )}
        </Formik>
      </LayoutContainer>
    </AuthLayout>
  );
}
