import { createSlice } from "@reduxjs/toolkit";

interface ModeState {
  mode: string;
}

const initialState: ModeState = {
  mode: "For education",
};

const modeSlice = createSlice({
  name: "mode",
  initialState,
  reducers: {
    setMode: (state, action) => {
      state.mode = action.payload;
    },
  },
});

export const { setMode } = modeSlice.actions;
export default modeSlice.reducer;
