import React, { useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { artisteCaseStudy } from "../../../utils/constants";

const HowWeHelped = () => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % artisteCaseStudy.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + artisteCaseStudy.length) % artisteCaseStudy.length
    );
  };

  return (
    <div className="bg-white px-4 sm:px-6 lg:px-8 mt-20 lg:mt-28">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-2 font-[technorSemibold]">
          See how we have helped artistes
        </h2>
        <p className="text-3xl md:text-4xl text-teal-600 text-center mb-12 font-[technorSemibold]">
          produce meaningful music.
        </p>

        <div className="relative">
          <div className="overflow-x-clip">
            <div
              className="flex transition-transform duration-500 ease-in-out"
              style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
              {artisteCaseStudy.map((testimonial, index) => (
                <div key={index} className="w-full flex-shrink-0">
                  <div className="relative w-full max-w-3xl mx-auto">
                    <img
                      src={testimonial.image}
                      alt={testimonial.name}
                      className="w-full md:w-1/2 h-[200px] md:h-[500px] object-cover object-top rounded-lg "
                    />
                    <div className="absolute top-[19rem] md:top-1/2 md:right-5 w-[80%] left-1/2 -translate-x-1/2 md:-translate-x-1/4 md:w-full  transform -translate-y-1/2 bg-white p-6 rounded-lg shadow-lg max-w-md">
                      <blockquote className="text-lg mb-4">
                        "{testimonial.quote}"
                      </blockquote>
                      <p className="font-semibold">{testimonial.name}</p>
                      <p className="text-gray-600">
                        Genre: {testimonial.genre}
                      </p>
                      <p
                        onClick={() =>
                          navigate(`/production/case-study/${testimonial.name}`)
                        }
                        className="text-teal-600 hover:underline mt-4 inline-block cursor-pointer"
                      >
                        Read case study →
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <button
            onClick={prevSlide}
            className="absolute -bottom-36 h-fit md:top-1/2 left-4 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-lg z-10"
          >
            <FaChevronLeft className="text-gray-600 text-2xl" />
          </button>
          <button
            onClick={nextSlide}
            className="absolute -bottom-36 h-fit md:top-1/2 right-4 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-lg z-10"
          >
            <FaChevronRight className="text-gray-600 text-2xl" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default HowWeHelped;
