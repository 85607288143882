import { useNavigate, useParams } from "react-router-dom";
import { CustomModal, VideoPlayer } from "../../../../components/ui";
import { h3Style, pStyle } from "../../../waitList/style";
import saveIcon from "../../../../assets/icons/saveIcon.svg";
import shareIcon from "../../../../assets/icons/shareIcon.svg";
import levelIcon from "../../../../assets/icons/levelIcon.svg";
import reviewIcon from "../../../../assets/icons/reviewIcon.svg";
import audioIcon from "../../../../assets/icons/speaker.svg";
import durationIcon from "../../../../assets/icons/durationIcon.svg";
import { Tab, Tabs } from "../../../../components/ui/Tab";
import CourseDetails from "./CourseDetails";
import CourseContent from "./CourseContent";
import CourseReviews from "./CourseReviews";
import CourseInstructor from "./CourseInstructor";
import { useGetSingleCourse } from "../../../../hooks/react-query/useCourse";
import Loader from "../../../../components/ui/Loader";
import { useGetReview } from "../../../../hooks/react-query/useReview";
import { formatDuration } from "../../../../utils/formattedDate";
import { useGetInstructorPage } from "../../../../hooks/react-query/useInstructor";
import { useGetProfile } from "../../../../hooks/react-query/useAuthUser";
import { useState } from "react";
import { Button } from "@mui/material";

function CourseEnrollment() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  const { data: FetchedCourseData, isLoading } = useGetSingleCourse(
    id as string
  );
  const { data: userData } = useGetProfile();
  const { data: reviewData, isLoading: reviewLoading } = useGetReview(
    id as string
  );
  const { data: instructorData, isLoading: instructorLoading } =
    useGetInstructorPage(FetchedCourseData?.data?.main_contributor?._id, 
    );

  const courseData = FetchedCourseData || [];

  const userPackage = userData?.data?.packages;
  const coursePackage = courseData?.data?.package[0].title;
  const isCourseLocked = courseData?.data?.locked;
  const courseId = courseData.data?._id;
  const lessonId = courseData.data?.lessons[0]?._id;

  const handleClick = () => {
    navigate(`/app/courses/${courseId}?start=${lessonId}`);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (isLoading || reviewLoading || instructorLoading) {
    return <Loader />;
  }

  return (
      <div className="my-[70px] mx-[20px]">
        <section className="">
          <div className="md:flex items-center gap-5">
            <div className="md:w-[60%]">
              <VideoPlayer
                videoStyle="rounded-[12px] lg:h-[400px] h-[200px]"
                src={courseData?.data?.introductory_video}
              />
              <div className="flex items-center gap-x-7 py-4">
                <span className="flex items-center gap-x-1.5 supreme">
                  <img src={saveIcon} alt="share" /> Save
                </span>
                <span className="flex items-center gap-x-1.5 supreme">
                  <img src={shareIcon} alt="save" /> Share
                </span>
              </div>
            </div>
            <div className="md:w-[40%]">
              <h2 className={`font-[500] text-[30px] supreme`}>
                {courseData?.data?.title}
              </h2>
              <p className={`${pStyle} supreme py-1`}>
                By {courseData?.data?.main_contributor.name}{" "}
                {courseData?.data?.main_contributor.nickname}
              </p>
              <ul className="grid gap-y-3 py-5">
                <li className="flex items-center gap-x-2">
                  <span className="flex items-center gap-x-1 supreme">
                    <img src={levelIcon} alt="level" />
                    &nbsp; Level
                  </span>
                  <span className="supreme">{courseData?.data?.level}</span>
                </li>
                <li className="flex items-center gap-x-2">
                  <span className="flex items-center gap-x-1 supreme">
                    <img src={reviewIcon} alt="review" />
                    &nbsp; Review
                  </span>
                  <span className="supreme">90% positive review</span>
                </li>
                <li className="flex items-center gap-x-2">
                  <span className="flex items-center gap-x-1 supreme">
                    <img src={audioIcon} alt="audio" />
                    &nbsp; Audio
                  </span>
                  <span className="supreme">{courseData?.data?.language}</span>
                </li>
                <li className="flex items-center gap-x-2">
                  <span className="flex items-center gap-x-1 supreme">
                    <img src={durationIcon} alt="duration" />
                    &nbsp; Duration
                  </span>
                  <span className="supreme">
                    {courseData?.data?.lessons.length} lessons (
                    {formatDuration(courseData?.data?.duration)})
                  </span>
                </li>
              </ul>
              {isCourseLocked ? (
                <button
                  onClick={() => setIsModalOpen(true)}
                  className="w-full supreme flex justify-center mt-3 bg-tsa-primary lg:p-[18px] p-[13px] rounded-[8px] font-[supreme] text-white lg:text-[14px] text-[13px] tracking-wide"
                >
                  Enroll now
                </button>
              ) : (
                <button
                  onClick={handleClick}
                  className="w-full supreme flex justify-center mt-3 bg-tsa-primary lg:p-[18px] p-[13px] rounded-[8px] font-[supreme] text-white lg:text-[14px] text-[13px] tracking-wide"
                >
                  Continue Learning
                </button>
              )}
            </div>
          </div>
        </section>

        {isModalOpen && (
          <CustomModal
            modalStyle="lg:w-[40%] w-[90%] mt-[250px] bg-white mx-auto mt-[12rem] rounded-[12px] p-7 supreme"
            onClose={closeModal}
          >
            <p className="text-[##010516] font-medium text-lg mb-11">
              Upgrade to access this course
            </p>

            <p className="mb-11 font-light">
              This course is available to masterclass member only. Kindly
              upgrade to the masterclass plan to gain full access to this course
            </p>

            <button
              className="text-white bg-[#1C7272] rounded-lg px-4 py-4 text-sm font-medium supreme"
              onClick={() => navigate("/app/account?tab=subscription")}
            >
              Upgrade now
            </button>
          </CustomModal>
        )}

        <div className="mt-10 lg:w-[60%]">
          <Tabs>
            <Tab title="DESCRIPTION" notEnabled={false}>
              <CourseDetails data={courseData} />
            </Tab>
            <Tab title="COURSE CONTENT" notEnabled={false}>
              <div className="mt-7 pb-[40px]">
                <h3 className={`${h3Style} supreme text-[20px] my-2`}>
                  Course content
                </h3>
                <CourseContent data={courseData} />
              </div>
            </Tab>
            <Tab title="REVIEWS" notEnabled={true}>
              <CourseReviews reviewData={reviewData} />
            </Tab>
            <Tab title="INSTRUCTOR" notEnabled={true}>
              <CourseInstructor instructorData={instructorData.data} />
            </Tab>
          </Tabs>
        </div>
      </div>
  );
}

export default CourseEnrollment;
