import React from "react";
import { Rating } from "@mui/material";
import ReviewCard from "./ReviewCard";
import { h3Style } from "../../../waitList/style";

function CourseReviews({ reviewData }: any) {
  if (!reviewData || reviewData.data.length === 0) {
    return (
      <div className="pb-[50px]">
        <h3 className={`${h3Style} supreme text-[20px] my-2`}>
          Student Feedback
        </h3>
        <div>No reviews yet</div>
      </div>
    );
  }

  const totalReviews = reviewData.data.length;
  const ratingCounts = [0, 0, 0, 0, 0, 0]; // Index 0 is unused, indices 1-5 represent 1-5 star ratings
  let totalRating = 0;

  reviewData.data.forEach((review: any) => {
    const rating = Math.round(review.rating);
    ratingCounts[rating]++;
    totalRating += review.rating;
  });

  const averageRating = totalRating / totalReviews;

  const ratingPercentages = ratingCounts.map(
    (count) => (count / totalReviews) * 100
  );

  return (
    <div className="w-full"></div>
    // <div className='pb-[50px]'>
    //     <h3 className={`${h3Style} supreme text-[20px] my-2`}>Student Feedback</h3>
    //     <div className='flex items-center w-full'>
    //         <div className='w-[20%] flex items-center flex-col gap-1'>
    //             <h1 className='text-[50px] text-tsa-primary font-bold supreme'>
    //                 {averageRating.toFixed(1)}
    //             </h1>
    //             <Rating
    //                 name="half-rating-read"
    //                 defaultValue={averageRating}
    //                 precision={0.5}
    //                 readOnly
    //                 size="small"
    //             />
    //             <p className='text-[12px] text-tsa-primary supreme'>Course Rating</p>
    //         </div>
    //         <div className='w-[80%] flex items-center justify-end gap-2'>
    //             <div className='w-full grid gap-4'>
    //                 {[5, 4, 3, 2].map(star => (
    //                     <div key={star} className="h-[10px] bg-[#DCDEE2] w-full">
    //                         <div
    //                             className="h-full bg-tsa-primary"
    //                             style={{ width: `${ratingPercentages[star]}%` }}
    //                         ></div>
    //                     </div>
    //                 ))}
    //             </div>
    //             <div className='grid gap-2'>
    //                 {[5, 4, 3, 2].map(star => (
    //                     <div key={star} className='flex items-center justify-end gap-2'>
    //                         <Rating
    //                             name="half-rating-read"
    //                             defaultValue={star}
    //                             precision={0.5}
    //                             readOnly
    //                             size="small"
    //                         />
    //                         <span className='text-[12px] text-tsa-primary supreme'>
    //                             {ratingPercentages[star].toFixed(1)}%
    //                         </span>
    //                     </div>
    //                 ))}
    //             </div>
    //         </div>
    //     </div>
    //     <h3 className={`${h3Style} supreme text-[20px] mt-5`}>Reviews</h3>
    //     {reviewData.data.map((review: any) => (
    //         <ReviewCard key={review._id} data={review} />
    //     ))}
    // </div>
  );
}

export default CourseReviews;
